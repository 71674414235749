import {
    Avatar,
    Badge,
    Button,
    Flex,
    Td,
    Text,
    Tr,
    Menu,
    MenuButton,
    MenuList,
    List, ListItem, ListIcon,
    MenuItem,
    IconButton,
    useColorModeValue,
} from "@chakra-ui/react";
import { VscMenu } from 'react-icons/vsc'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { MdCancel } from 'react-icons/md'
import React from "react";

function TablesTableRow(props) {
    const data = props.data;
    let index = props.index;
    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.400");

    return (
        <Tr>
            <Td>
                <Flex direction="column">
                    <Text
                        fontSize="sm"
                        color={textColor}
                        fontWeight="bold"
                    >
                        {index}
                    </Text>
                </Flex>
            </Td>

            <Td width='100px'>
                <Flex direction="column">
                    <Text fontSize="sm" color={textColor} fontWeight="bold">
                        {data.configId}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <Flex direction="column">
                    <Text fontSize="sm" color={textColor} fontWeight="normal">
                        {data.name}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <Text fontSize="sm" color={textColor} fontWeight="normal">
                    {data.quizType.replace('_', ' ')}
                </Text>
            </Td>
            <Td>
                <Text fontSize="sm" color={textColor} fontWeight="normal" >
                    {data.quizFormat}
                </Text>
            </Td>
            <Td>
                <Text fontSize="sm" color={textColor} fontWeight="normal">
                    {data.learningType}
                </Text>
            </Td>
            <Td>
                <Text fontSize="sm" color={textColor} fontWeight="normal" >
                    {data.languageSupport.map(i => {
                        return (<Badge variant="solid" colorScheme='gray' mx={1}>{i}</Badge>);
                    })}
                </Text>
            </Td>
            <Td>
                <Text fontSize="sm" color={textColor} fontWeight="normal" >
                    {new Intl.DateTimeFormat('en-us').format(new Date(data.createdAt))}
                </Text>
            </Td>
            <Td>
                <Menu>
                    <MenuButton as={IconButton} icon={<VscMenu />}>

                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => props.onclick({ id: data.configId, action: "view" })}>View</MenuItem>
                        <MenuItem onClick={() => props.onclick({ id: data.configId, action: "delete" })}>Delete</MenuItem>
                    </MenuList>
                </Menu>
            </Td>
        </Tr>
    );
}

export default TablesTableRow;
