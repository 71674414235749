
import { useState, useEffect, useMemo } from "react";

// react-router components
import { BrowserRouter, Switch, useLocation } from "react-router-dom";


import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import ClientLayout from "layouts/Client.js";
import auth from "./helpers/auth";
import api from "./helpers/Fetch";
import Util from "./helpers/utils";

export default function App() {
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');
    console.log(token);
    let API = new api();
    let utils = new Util();
    if (token) {
        API.parseEmailTokens({ token: token }).then(res => {
            utils.showSuccess('Response', res.data.message);
        }).catch(err => {
            utils.showServerError(err);
        })
    }

    return (
        <>
            <BrowserRouter>
                <Switch>
                    {/* {getPageRoutes(pageRoutes)} */}
                    {auth.isLoggedIn() ? (auth.getLoginRole() === 'A' ? <AdminLayout /> : <ClientLayout />) : <AuthLayout />}
                </Switch>
            </BrowserRouter>
        </>
    );
}
