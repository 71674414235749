import React, { useState, useEffect } from "react";
// Chakra imports
import {
    Flex,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    Button,
    Grid,
    Box, UnorderedList, IconButton,
    SimpleGrid, VStack,
    useColorModeValue, FormControl,
    Link, Switch, useDisclosure, Input, InputGroup, InputLeftElement,
    Tabs, TabList, TabPanels, Tab, TabPanel, InputRightElement
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesTableRow from "components/Tables/BranchesTableRow";
import { PhoneIcon, AddIcon, WarningIcon, ArrowBackIcon, EditIcon, EmailIcon } from '@chakra-ui/icons'
import Api from "../../helpers/Fetch";
import Utils from "../../helpers/utils";
import Auth from '../../helpers/auth';
import { MdPassword } from "react-icons/md";
import { Editor } from '@tinymce/tinymce-react';
import { VscSave } from 'react-icons/vsc'
import { RiEyeCloseFill } from "react-icons/ri";
import { BsFillEyeFill } from 'react-icons/bs'
import { Formik } from 'formik';


function ViewClient(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const [data, setData] = useState(null);
    const [templates, setTemplates] = useState(null);
    const [LicenceIssueTemplate, setLicIssueTemplate] = useState(null);
    const [CertIssueTemplate, setCertIssueTemplate] = useState(null);
    const [editable, setEditable] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const editorRef = React.useRef(null);
    const editorCertRef = React.useRef(null);
    let api = new Api();
    let util = new Utils();
    useEffect(() => {
        // let data = {
        // }
        // api.getDefaults(data).then((res) => {
        //     console.log(res);
        //     setData(res.data.data)

        // }).catch((err) => {
        //     util.showServerError(err);

        // });
        api.getTemplates().then((res) => {
            console.log(res);
            setTemplates(res.data.data)
            res.data.data.map(temp => {
                if (temp.type === 'EMP_LIC_ISSUE') {
                    setLicIssueTemplate(temp);
                }
                if (temp.type === 'CERT_ISSUE') {
                    setCertIssueTemplate(temp);
                }
            })

        }).catch((err) => {
            util.showServerError(err);

        });
    }, []);
    const updateCredentials = (data) => {
        setLoading(true)
        api.updateDefaults(data).then((res) => {
            console.log(res);
            setLoading(false)
            util.showSuccess(res.data.message, '')
            setData(res.data.data)


        }).catch((err) => {
            setLoading(false)
            util.showServerError(err);

        });
    }
    const updateTemplate = (data) => {
        let content = editorRef.current.getContent();
        if (data === 'CERT') {
            if (CertIssueTemplate !== null) {
                setLoading(true)
                api.updateTemplate({ templateId: CertIssueTemplate.templateId, htmlText: editorCertRef.current.getContent() }).then((res) => {
                    console.log(res);
                    setLoading(false)
                    util.showSuccess(res.data.message, '');

                }).catch((err) => {
                    setLoading(false)
                    util.showServerError(err);

                });
            }
        }
        if (data === 'LICENCE') {
            if (LicenceIssueTemplate !== null) {
                setLoading(true)
                api.updateTemplate({ templateId: LicenceIssueTemplate.templateId, htmlText: content }).then((res) => {
                    console.log(res);
                    setLoading(false)
                    util.showSuccess(res.data.message, '');

                }).catch((err) => {
                    setLoading(false)
                    util.showServerError(err);

                });
            }
        }
    }





    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                <CardHeader p="6px 0px 22px 0px">
                    <Flex justify="space-between" align="center" minHeight="30px" w="100%">
                        <Text fontSize="lg" color={textColor} fontWeight="bold">
                            Settings
                        </Text>
                        <Button
                            fontSize="xs"
                            variant="solid"
                            leftIcon={<ArrowBackIcon />}
                            onClick={() => props.history.goBack()}
                        >
                            Back
                        </Button>
                    </Flex>

                </CardHeader>
                <CardBody>
                    <Box w="100%">
                        <Tabs variant='unstyled' orientation='vertical'>
                            <TabList w="180px" borderWidth={1} borderRadius="10px" p={2}>
                                {/* <Tab _selected={{ color: 'white', bg: 'green.500', borderRadius: '10px' }}>Mail</Tab> */}
                                <Tab _selected={{ color: 'white', bg: 'blue.400', borderRadius: '10px' }}>Templates</Tab>
                            </TabList>
                            <TabPanels borderRadius="6px" borderWidth={1} ml="10px" w="100%">
                                {/* <TabPanel>
                                    <Formik
                                        initialValues={{
                                            email: !!data && data.email || '',
                                            passkey: !!data && data.passkey || ''

                                        }}
                                        onSubmit={(values, actions) => {
                                            console.log(values);
                                            updateCredentials(values)
                                        }}

                                    >
                                        {({
                                            values,
                                            handleSubmit,
                                            handleChange
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <FormControl>
                                                    <Box justify='end' w="50%">
                                                        <IconButton icon={<EditIcon />} w="44px" onClick={() => setEditable(!editable)} />
                                                        <Flex direction='column' w="100%">
                                                            <VStack justifySelf='flex-start' textAlign='start'>
                                                                <Text>Email</Text>
                                                                <InputGroup>
                                                                    <InputLeftElement
                                                                        pointerEvents="none"
                                                                        children={<EmailIcon color="gray.300" />}
                                                                    />
                                                                    <Input type="email" placeholder="email" name="email" value={values.email} disabled={!editable} onChange={handleChange} />
                                                                </InputGroup>
                                                            </VStack>
                                                            <VStack justifySelf='flex-start' textAlign='start' mt={6}>
                                                                <Text>Password</Text>
                                                                <InputGroup>
                                                                    <InputLeftElement
                                                                        pointerEvents="none"
                                                                        children={<MdPassword color="gray.300" />}
                                                                    />
                                                                    <InputRightElement
                                                                        pointerEvents='visibleFill'
                                                                        children={showPassword ? <BsFillEyeFill color="gray.400" /> : <RiEyeCloseFill color='gray.400' />}
                                                                        onClick={() => setShowPassword(!showPassword)}

                                                                    />
                                                                    <Input
                                                                        type={showPassword ? "text" : "password"}
                                                                        name="passkey"
                                                                        value={values.passkey}
                                                                        disabled={!editable}
                                                                        onChange={handleChange}
                                                                    />
                                                                </InputGroup>
                                                            </VStack>
                                                            <Button mt={5} hidden={!editable} type="submit" isLoading={isLoading}>Update</Button>
                                                        </Flex>
                                                    </Box>
                                                </FormControl>
                                            </form>
                                        )}
                                    </Formik>
                                </TabPanel> */}
                                <TabPanel w="100%">
                                    <Tabs variant='line' colorScheme='green'>
                                        <TabList>
                                            <Tab>Emp. Licence Issue</Tab>
                                            <Tab>Certificate Issue</Tab>
                                        </TabList>
                                        <TabPanels>
                                            <TabPanel>
                                                <Flex direction="row" whiteSpace={1} justify='space-between' mb="10px">
                                                    <Text>Preview</Text>
                                                    <IconButton
                                                        fontSize="xs"
                                                        variant="solid"
                                                        icon={!isEdit ? <EditIcon /> : <VscSave />}
                                                        onClick={() => {
                                                            if (!isEdit) {
                                                                setEdit(true);
                                                            }
                                                            else {
                                                                updateTemplate('LICENCE')
                                                            }
                                                        }}
                                                    >
                                                    </IconButton>
                                                </Flex>
                                                <Flex direction="column">
                                                    {!isEdit ?
                                                        <Box borderWidth='1px' borderRadius='sm' overflow='scroll' mt={2} p={2}>
                                                            <Text w="100%" p={1}>
                                                                {!!LicenceIssueTemplate ? <div dangerouslySetInnerHTML={{ __html: LicenceIssueTemplate.htmlText }} /> : ''}
                                                            </Text>
                                                        </Box> :
                                                        <>
                                                            <Text><b>Note: </b>Use #clientId#, #Licence#  for add fields</Text>
                                                            <Editor
                                                                onInit={(evt, editor) => editorRef.current = editor}
                                                                initialValue={!!LicenceIssueTemplate ? LicenceIssueTemplate.htmlText : ''}

                                                                init={{
                                                                    height: 300,
                                                                    menubar: true,
                                                                    plugins: [
                                                                        'advlist autolink lists link image charmap print preview anchor',
                                                                        'searchreplace visualblocks code fullscreen',
                                                                        'insertdatetime media table paste code help wordcount'
                                                                    ],
                                                                    toolbar: 'undo redo | formatselect | ' +
                                                                        'bold italic backcolor | alignleft aligncenter ' +
                                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                        'removeformat | help',
                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                }}
                                                            /></>}
                                                </Flex>
                                            </TabPanel>
                                            <TabPanel>
                                                <Flex direction="row" whiteSpace={1} justify='space-between' mb="10px">
                                                    <Text>Preview</Text>
                                                    <IconButton
                                                        fontSize="xs"
                                                        variant="solid"
                                                        icon={!isEdit ? <EditIcon /> : <VscSave />}
                                                        onClick={() => {
                                                            if (!isEdit) {
                                                                setEdit(true);
                                                            }
                                                            else {
                                                                updateTemplate('CERT')
                                                            }
                                                        }}
                                                    >
                                                    </IconButton>
                                                </Flex>
                                                {!isEdit ?
                                                    <Box borderWidth='1px' borderRadius='sm' overflow='scroll' mt={2} p={2}>
                                                        <Text w="100%" p={1}>
                                                            {!!CertIssueTemplate ? <div dangerouslySetInnerHTML={{ __html: CertIssueTemplate.htmlText }} /> : ''}
                                                        </Text>
                                                    </Box> :
                                                    <>
                                                        <Text><b>Note: </b>Use #name# to add name</Text>
                                                        <Editor
                                                            onInit={(evt, editor) => editorCertRef.current = editor}
                                                            initialValue={!!CertIssueTemplate ? CertIssueTemplate.htmlText : ''}
                                                            init={{
                                                                height: 300,
                                                                menubar: false,
                                                                plugins: [
                                                                    'advlist autolink lists link image charmap print preview anchor',
                                                                    'searchreplace visualblocks code fullscreen',
                                                                    'insertdatetime media table paste code help wordcount'
                                                                ],
                                                                toolbar: 'undo redo | formatselect | ' +
                                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                    'removeformat | help',
                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                            }}
                                                        /></>}
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>

                </CardBody>

            </Card>
        </Flex>
    );
}

export default ViewClient;
