import React, { useState, useEffect } from "react";
// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Grid,
  GridItem,
  useToast,
  useColorModeValue,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, UnorderedList, ListItem
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesTableRow from "components/Tables/ClientsTableRow";
import { PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'
import Api from "../../helpers/Fetch";
import Util from '../../helpers/utils';


function Clients(props) {
  const textColor = useColorModeValue("gray.700", "white");
  const [data, setData] = useState(null);
  const [pageIndex, setPage] = useState(1);
  const [showDelete, setShowDelete] = useState(false);
  const [clientId, setClientId] = useState(false);
  const [isLoading, setLoading] = useState(false);
  let api = new Api();
  let utils = new Util();
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "red.800"
  );
  useEffect(() => {
    let data = {
      pageSize: 10,
      currentPage: pageIndex
    }
    getClients(data);
  }, []);
  const getClients = (data) => {
    api.getClients(data).then((res) => {
      console.log(res);
      setData(res.data.data)

    }).catch((err) => {
      console.log(err);
      utils.showServerError(err);

    });
  }
  const onclickHandler = (data) => {
    console.log("row clicked", data);
    if (!!data) {
      if (data.action === 'view') {
        props.history.push({
          pathname: '/admin/view-client',
          state: {
            userId: data.id
          }
        })
      }
      else if (data.action === 'delete') {
        setClientId(data.id);
        setShowDelete(true)
      }
      else if (data.action === 'change_status') {
        disableClient(data.id, data.isActive);
      }
      else if (data.action === 'add_branch') {
        props.history.push({
          pathname: "/admin/step-branch",
          state: {
            userId: data.id.userId,
            companyName: data.id.companyName
          }
        })
      }
    }
  }
  const deleteClient = (id) => {
    setLoading(true);
    api.deleteClient({ userId: id }).then((res) => {
      console.log(res);
      setLoading(false);
      let data = {
        pageSize: 10,
        currentPage: pageIndex
      }
      setShowDelete(false);
      getClients(data)

    }).catch((err) => {
      console.log(err);
      setLoading(false);
      utils.showServerError(err);
    });
  }
  const disableClient = (id, isActive) => {
    setLoading(true);
    if (!!data) {
      console.log(isActive)
      api.disableClient({ userId: id, setEnabled: !isActive }).then((res) => {
        console.log(res);
        setLoading(false);
        let data = {
          pageSize: 10,
          currentPage: pageIndex
        }
        getClients(data)
      }).catch((err) => {
        console.log(err);
        setLoading(false);
        utils.showServerError(err);
      });
    }
  }

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex justify="space-between" align="center" minHeight="60px" w="100%">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Clients
            </Text>
            <Flex>

              <Button
                bg={bgButton}
                color="white"
                fontSize="xs"
                variant="solid"
                leftIcon={<AddIcon />}
                onClick={() => props.history.push('/admin/add-client')}

              >
                ADD CLIENT
              </Button>
            </Flex>
          </Flex>

        </CardHeader>
        <CardBody>
          <Table size='sm' variant="striped" color={textColor} overflow='scroll'>
            <Thead>
              <Tr my=".3rem" pl="10px" color="gray.400">
                <Th color="gray.400" ps="70px">Client ID</Th>
                <Th color="gray.400">Email</Th>
                <Th color="gray.400">State/City</Th>
                <Th color="gray.400">Total Licences</Th>
                <Th color="gray.400">Branches</Th>
                <Th color="gray.400">Date</Th>
                <Th color="gray.400">Active</Th>

                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {!!data && data.map((row) => {
                return (
                  <TablesTableRow
                    data={row}
                    onclick={onclickHandler}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
      <Modal isOpen={showDelete} onClose={() => setShowDelete(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color='red.300'>Delete client - {clientId} ?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb='3'>Are you sure, you want to delete this client? By deleting this following things will be effected:</Text>
            <UnorderedList>
              <ListItem>All branches will be deleted</ListItem>
              <ListItem>All licences and related analytics will be deleted</ListItem>
              <ListItem>Client will have no longer access of panel.</ListItem>
              <ListItem>Certificates and Purchase orders <i>will not</i> be deleted.</ListItem>
            </UnorderedList>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => setShowDelete(false)}>
              Don't Delete
            </Button>
            <Button variant='solid' color='red.400' isLoading={isLoading} onClick={() => deleteClient(clientId)}>Yes, Delete</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Flex>
  );
}

export default Clients;
