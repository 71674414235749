// import
import Dashboard from "views/Dashboard/Dashboard.js";
import Clients from "views/Dashboard/Clients.js";
import Billing from "views/Dashboard/Billing.js";
import Orders from "views/Dashboard/Orders.js";
import Content from "views/Dashboard/Content.js";
import profile from "views/Dashboard/profile";
import QuizPack from "views/Dashboard/QuizPacks";
import MC from "views/Dashboard/ModuleConfigration";
import AL from "views/Dashboard/AdminLicences";
import MCF from "views/Dashboard/GeneralConfig";

import SignIn from "views/Pages/SignIn.js";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";
import { RiTimerFill } from "react-icons/ri";
import { BiBookContent } from 'react-icons/bi';
import { FcDataConfiguration } from 'react-icons/fc';
import { FaKey } from 'react-icons/fa';
import { EmailIcon, SettingsIcon } from "@chakra-ui/icons";
import { IoLogOutOutline } from "react-icons/io5";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/clients",
    name: "Clients",
    icon: <StatsIcon color="inherit" />,
    component: Clients,
    layout: "/admin",
  },
  {
    path: "/po",
    name: "Purchase Orders",
    icon: <CreditIcon color="inherit" />,
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/content",
    name: "Content",
    icon: <BiBookContent color="inherit" />,
    component: Content,
    layout: "/admin",
  },
  {
    path: "/quizpack",
    name: "Quiz",
    icon: <RiTimerFill color="inherit" />,
    component: QuizPack,
    layout: "/admin",
  },
  {
    path: "/configmodules",
    name: "Module Config",
    icon: <FcDataConfiguration color="inherit" />,
    component: MC,
    layout: "/admin",
  },
  {
    path: "/licences",
    name: "All Licences",
    icon: <FaKey color="inherit" />,
    component: AL,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Profile",
    icon: <PersonIcon color="inherit" />,
    component: profile,
    layout: "/admin",
  },
  {
    name: "Settings",
    category: "account",
    state: "pageCollapse",
    views: [
      {
        path: "/settings",
        name: "Configure",
        icon: <SettingsIcon color="inherit" />,
        component: MCF,
        layout: "/admin",
      }
    ],
  },
];
export default dashRoutes;
