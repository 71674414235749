import React, { useState, useEffect } from "react";
// Chakra imports
import {
    Flex,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    Select, IconButton,
    useColorModeValue, InputGroup, InputLeftElement, Input,
    Link,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TableRow from "components/Tables/OrdersTableRow";
import { AddIcon, EditIcon, ChevronRightIcon, ChevronLeftIcon, SearchIcon } from '@chakra-ui/icons'
import Api from "../../helpers/Fetch";
import Auth from '../../helpers/auth';
import Utils from '../../helpers/utils';
import debounce from 'lodash.debounce';


function Orders(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const [data, setData] = useState(null);
    const [pageIndex, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = React.useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false)
    const [orderId, setOrderId] = React.useState('')
    const [metadata, setMetadata] = useState(null);
    const [query, setQuery] = useState(null);
    const onClose = () => { setIsOpen(false); setIsDeleteOpen(false) }
    const cancelRef = React.useRef()

    let api = new Api();
    let utils = new Utils();
    let toast = useToast();
    const bgButton = useColorModeValue(
        "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
        "red.800"
    );
    let querydata = {
        pageSize: 10,
        currentPage: pageIndex
    }
    useEffect(() => {
        getData(querydata)

    }, []);
    const getData = (data) => {
        setQuery(data);
        api.getOrders(data).then((res) => {
            console.log(res);
            setData(res.data.data)
            setMetadata(res.data.meta);

        }).catch((err) => {
            console.log(err);
            utils.showServerError(err);

        });
    }
    const onclickHandler = (data) => {
        console.log("row clicked", data);
        if (!!data) {
            setOrderId(data.id);
            if (data.action === 'release') {
                setIsOpen(true);
            }
            else if (data.action === 'delete') {
                setIsDeleteOpen(true);
            }
            else if (data.action === 'view') {
                props.history.push({
                    pathname: '/admin/view-po',
                    state: {
                        orderId: data.id
                    }
                })
            }
            else if (data.action === 'download') {
                api.downloadPO({ orderId: data.id }).then((res) => {
                    console.log(res);
                    let base64 = (res.data.data)
                    let blob = b64toBlob(base64, 'application/pdf');
                    const url = URL.createObjectURL(blob);
                    window.open(url, "_blank");


                }).catch((err) => {
                    console.log(err);
                    utils.showServerError(err);

                });
            }
        }
    }
    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    const releaseLicence = () => {
        let data = {
            orderId: orderId
        }
        setIsLoading(true);
        api.releaseOrder(data).then((res) => {
            console.log(res);
            setIsOpen(false);
            utils.showSuccess(res.data.message, '');
            getData(querydata);

        }).catch((err) => {
            console.log(err);
            utils.showServerError(err);

        });
    }
    const deleteOrder = () => {
        let data = {
            orderId: orderId
        }
        setIsLoading(true);
        api.deleteOrder(data).then((res) => {
            console.log(res);
            setIsLoading(false);
            setIsDeleteOpen(false);
            utils.showSuccess(res.data.message, '');
            getData(querydata);

        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
            utils.showServerError(err);

        });
    }
    const handlePagination = (direction) => {
        if (direction === ">") {
            if (!!metadata) {
                let q = {
                    ...query,
                    currentPage: parseInt(metadata.currentPage) + 1,
                }
                getData(q);
            }
        }
        else {
            if (!!metadata) {
                let q = {
                    ...query,
                    currentPage: parseInt(metadata.currentPage) - 1,
                }
                getData(q);
            }
        }
    }
    const debouncedFetchData = debounce((query, cb) => {
        getData(query);
        cb();
    }, 500);

    const handleSearch = (event) => {
        debouncedFetchData({ search: event.target.value }, res => {
        });

    }

    return (<>
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                <CardHeader p="6px 0px 22px 0px">
                    <Flex justify="space-between" align="center" minHeight="60px" w="100%">
                        <Text fontSize="lg" color={textColor} fontWeight="bold">
                            All Orders
                        </Text>
                        <Button
                            bg={bgButton}
                            color="white"
                            fontSize="xs"
                            variant="solid"
                            leftIcon={<AddIcon />}
                            onClick={() => props.history.push('/admin/add-po')}

                        >
                            Add New
                        </Button>
                    </Flex>

                </CardHeader>
                <CardBody>
                    <Flex direction='column' w='100%'>
                        <InputGroup maxW='30%' mb={4} alignSelf='flex-end'>
                            <InputLeftElement
                                pointerEvents="none"
                                children={<SearchIcon color="gray.300" h="14px" w="14px" mb="2" />}
                            />
                            <Input type="text" size='md' placeholder="search Order ID, Client ID" onChange={handleSearch} />
                        </InputGroup>
                        <Table variant="striped" color={textColor} size="sm">
                            <Thead>
                                <Tr my=".3rem" pl="10px" color="gray.400">
                                    <Th color="gray.400">#</Th>
                                    <Th color="gray.400">Order ID</Th>
                                    <Th color="gray.400">Client</Th>
                                    <Th color="gray.400">Licence Qty.</Th>
                                    <Th color="gray.400">Order Amount</Th>
                                    <Th color="gray.400">Expiry Date</Th>
                                    <Th color="gray.400">Status</Th>
                                    <Th color="gray.400">Includes</Th>
                                    <Th color="gray.400">Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {!!data && data.map((row, index) => {
                                    return (
                                        <>
                                            <TableRow
                                                index={index + 1}
                                                data={row}
                                                onclick={onclickHandler}
                                            />
                                        </>

                                    );
                                })}
                            </Tbody>
                        </Table>
                        {!!data && data.length > 0 ?
                            <Flex justify='space-between'>
                                <Select mt='4' size='sm' maxW='100px' onChange={(e) => getData({ pageSize: e.target.value, currentPage: metadata.currentPage })}>
                                    {[10, 30, 50, 100].map(item => {
                                        return (<option value={item}>{item}</option>)
                                    })}
                                </Select>
                                <Flex me='8' mt='4'>
                                    {!!metadata ?
                                        <Text fontWeight='thin' me='6' py='2' size='sm'>Page {metadata.currentPage}, Showing {metadata.pageSize > metadata.totalCount ? metadata.totalCount : metadata.pageSize} of {metadata.totalCount}</Text>
                                        : null}
                                    <IconButton
                                        variant='outline'
                                        colorScheme='gray'
                                        fontSize='20px'
                                        mb={2}
                                        me={2}
                                        isLoading={isLoading}
                                        disabled={!!metadata ? parseInt(metadata.currentPage) === 1 : false}
                                        icon={<ChevronLeftIcon />}
                                        onClick={() => {
                                            handlePagination("<")
                                        }}
                                    />
                                    <IconButton
                                        variant='outline'
                                        colorScheme='gray'
                                        fontSize='20px'
                                        mb={2}
                                        isLoading={isLoading}
                                        disabled={!!metadata ? parseInt(metadata.currentPage) * parseInt(metadata.pageSize) >= metadata.totalCount : false}
                                        icon={<ChevronRightIcon />}
                                        onClick={() => handlePagination(">")}
                                    />
                                </Flex>
                            </Flex> : null}
                    </Flex>
                </CardBody>
            </Card>
        </Flex>
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Confirm Release?
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Are you sure you want to release licences?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme='green' onClick={onClose} ml={3} isLoading={isLoading} onClick={releaseLicence}>
                            Release
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        <AlertDialog
            isOpen={isDeleteOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Confirm Delete?
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Are you sure you want delete this order?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme='red' onClick={onClose} ml={3} isLoading={isLoading} onClick={deleteOrder}>
                            Delete
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    </>
    );
}

export default Orders;
