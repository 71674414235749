// import
import AddClient from "views/Pages/AddClient";
import BranchStep from "views/Pages/BranchStep";
import PoStep from "views/Pages/CreateOrderStep";
import PO from "views/Pages/CreateOrder";
import ViewPO from "views/Pages/ViewPO";
import Modules from "views/Pages/Modules";
import Chapters from "views/Pages/Chapters";
import Content from "views/Pages/ContentView";
import AddMCC from "views/Pages/AddMCC";
import Questions from "views/Pages/Questions";
import AddQuestion from "views/Pages/AddQuestion";
import CMC from "views/Pages/CreateModuleConfig";
import ViewClient from "views/Pages/ViewClient";
import Quiz from "views/Dashboard/Quiz";
import ViewConfig from 'views/Pages/ViewModuleConfig';


var internalRoutes = [
  {
    path: "/add-client",
    name: "Add Client",
    component: AddClient,
    layout: "/admin",
  },
  {
    path: "/view-client",
    name: "view Client",
    component: ViewClient,
    layout: "/admin",
  },
  {
    path: "/step-branch",
    name: "Add Branch",
    component: BranchStep,
    layout: "/admin",
  },
  {
    path: "/step-po",
    name: "Create Order",
    component: PO,
    layout: "/admin",
  },
  {
    path: "/add-po",
    name: "Create Order",
    component: PO,
    layout: "/admin",
  },
  {
    path: "/view-po",
    name: "View Order",
    component: ViewPO,
    layout: "/admin",
  },
  {
    path: "/modules",
    name: "Modules",
    component: Modules,
    layout: "/admin",
  },
  {
    path: "/chapters",
    name: "Chapters",
    component: Chapters,
    layout: "/admin",
  },
  {
    path: "/view/content",
    name: "Content",
    component: Content,
    layout: "/admin",
  },
  {
    path: "/create/mcc",
    name: "Add Content",
    component: AddMCC,
    layout: "/admin",
  },
  {
    path: "/questions",
    name: "Questions",
    component: Questions,
    layout: "/admin",
  },
  {
    path: "/add-question",
    name: "Add Question",
    component: AddQuestion,
    layout: "/admin",
  },
  {
    path: "/add-moduleConfig",
    name: "Add Configuration",
    component: CMC,
    layout: "/admin",
  },
  {
    path: "/quiz",
    name: "Add Configuration",
    component: Quiz,
    layout: "/admin",
  },
  {
    path: "/config/details",
    name: "Configuration",
    component: ViewConfig,
    layout: "/admin",
  },
];
export default internalRoutes;
