import React, { useState, useEffect } from "react";
// Chakra imports
import {
    Flex,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    Button,
    IconButton,
    Box,
    useToast, Spinner,
    useColorModeValue, Input, InputGroup, InputLeftElement,
    Link, Select,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody, Divider, Badge,
    ModalCloseButton, SimpleGrid,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesTableRow from "components/Tables/LicencesTableRow";
import Api from "../../helpers/Fetch";
import Auth from '../../helpers/auth';
import Util from '../../helpers/utils';
import { AddIcon, EditIcon, ChevronRightIcon, ChevronLeftIcon, SearchIcon } from '@chakra-ui/icons'
import {
    AsyncCreatableSelect,
    AsyncSelect,
    CreatableSelect,
    Select as Multiselect,
} from "chakra-react-select";
import debounce from 'lodash.debounce';


function Clients(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const [data, setData] = useState(null);
    const [clients, SetClients] = useState(null);
    const [pageIndex, setPage] = useState(1);
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false)
    const [isLoading, setLoading] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [metadata, setMetadata] = useState(null);
    const [query, setQuery] = useState(null);
    const [licItem, setLicItem] = useState(null);
    let api = new Api();
    let util = new Util();
    let toast = useToast();
    const bgButton = useColorModeValue(
        "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
        "red.800"
    );
    useEffect(() => {
        getLicences();
        api.getClients().then((res) => {
            console.log(res);
            let opt = [];
            res.data.data.map(item => {
                opt.push({
                    label: item.companyName,
                    value: item.userId
                })
            })
            SetClients(opt);

        }).catch((err) => {
            console.log(err);
            util.showServerError(err);

        });
    }, []);
    const getLicences = (d) => {
        let data = {
            pageSize: 10,
            currentPage: pageIndex,
            ...d
        }
        setQuery(data);
        api.getLicences(data).then((res) => {
            console.log(res);
            setData(res.data.data)
            setMetadata(res.data.meta);

        }).catch((err) => {
            console.log(err);
            util.showServerError(err);

        });
    }
    const onclickHandler = (data) => {
        console.log("row clicked", data);
        setLicItem(data.id);
        if (!!data) {
            if (data.action === 'view') {
                setOpen(true);
            }
            else if (data.action === 'state_change') {
                api.changeLicState({ licence: data.id.licence, isActive: !data.id.isActive }).then((res) => {
                    console.log(res);
                    util.showSuccess("Licence updated");
                    getLicences(query);

                }).catch((err) => {
                    console.log(err);
                    util.showServerError(err);

                });
            }
        }
    }
    const handlePagination = (direction) => {
        if (direction === ">") {
            if (!!metadata) {
                let q = {
                    ...query,
                    currentPage: parseInt(metadata.currentPage) + 1,
                }
                getLicences(q);
            }
        }
        else {
            if (!!metadata) {
                let q = {
                    ...query,
                    currentPage: parseInt(metadata.currentPage) - 1,
                }
                getLicences(q);
            }
        }
    }
    const debouncedFetchData = debounce((query, cb) => {
        getLicences(query);
        cb();
    }, 500);

    const handleSearch = (event) => {
        debouncedFetchData({ search: event.target.value }, res => {
        });

    }
    const filter = (filter) => {
        console.log(filter);
        getLicences({ filter: filter });
    }

    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                <CardHeader p="6px 0px 22px 0px">
                    <Flex justify="space-between" align="center" minHeight="60px" w="100%">
                        <Text fontSize="lg" color={textColor} fontWeight="bold">
                            Licences
                        </Text>

                        <Box w="40%">
                            <Multiselect
                                size="sm"
                                colorScheme="green"
                                closeMenuOnSelect={true}
                                placeholder='Select client'
                                name="client"
                                onChange={(e) => {
                                    setData(null);
                                    getLicences({ clientId: e.value })
                                }}
                                selectedOptionStyle="check"
                                options={!!clients ? clients : []}
                                width="50%"
                            >

                            </Multiselect>
                        </Box>

                    </Flex>

                </CardHeader>
                <CardBody>
                    <Flex direction='column' w='100%'>
                        <Flex justify='space-between'>
                            <Flex>
                                <Button size='xs' me='4' onClick={() => filter('unissued')}>Not issued</Button>
                                <Button size='xs' me='4' onClick={() => filter('issued')}>Issued</Button>
                                <Button size='xs' me='4' onClick={() => filter('used')}>Used</Button>
                                <Button size='xs' me='4' onClick={() => filter('unused')}>Unused</Button>
                                <Button size='xs' onClick={() => filter('certified')}>Certified</Button>
                            </Flex>
                            <InputGroup maxW='30%' mb={4} alignSelf='flex-end'>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<SearchIcon color="gray.300" h="14px" w="14px" mb="2" />}
                                />
                                <Input type="text" size='md' placeholder="search by licence, client ID" onChange={handleSearch} />
                            </InputGroup>
                        </Flex>
                        {
                            data == null ? <Spinner color='red.500' size='lg' /> :
                                <Table variant="simple" color={textColor} size="sm">
                                    <Thead>
                                        <Tr my=".3rem" pl="10px" color="gray.400">
                                            <Th color="gray.400">#</Th>
                                            <Th color="gray.400">Client ID</Th>
                                            <Th color="gray.400">Branch Id</Th>
                                            <Th color="gray.400">Licence</Th>
                                            <Th color="gray.400">Is Used</Th>
                                            <Th color="gray.400">Certificate</Th>
                                            <Th color="gray.400">Issued To</Th>
                                            <Th color="gray.400">Expiry</Th>
                                            <Th></Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {!!data && data.map((row, i) => {
                                            return (
                                                <TablesTableRow
                                                    data={row}
                                                    index={i + 1}
                                                    onclick={onclickHandler}
                                                />
                                            );
                                        })}
                                    </Tbody>
                                </Table>
                        }
                        {!!data && data.length > 0 ?
                            <Flex justify='space-between'>
                                <Select mt='4' size='sm' maxW='100px' onChange={(e) => getLicences({ pageSize: e.target.value, currentPage: 1 })}>
                                    {[10, 30, 50, 100].map(item => {
                                        return (<option value={item}>{item}</option>)
                                    })}
                                </Select>
                                <Flex me='8' mt='4'>
                                    {!!metadata ?
                                        <Text fontWeight='thin' me='6' py='2' size='sm'>Page {metadata.currentPage}, Showing {metadata.pageSize > metadata.totalCount ? metadata.totalCount : metadata.pageSize} of {metadata.totalCount}</Text>
                                        : null}
                                    <IconButton
                                        variant='outline'
                                        colorScheme='gray'
                                        fontSize='20px'
                                        mb={2}
                                        me={2}
                                        isLoading={isLoading}
                                        disabled={!!metadata ? parseInt(metadata.currentPage) === 1 : false}
                                        icon={<ChevronLeftIcon />}
                                        onClick={() => {
                                            handlePagination("<")
                                        }}
                                    />
                                    <IconButton
                                        variant='outline'
                                        colorScheme='gray'
                                        fontSize='20px'
                                        mb={2}
                                        isLoading={isLoading}
                                        disabled={!!metadata ? parseInt(metadata.currentPage) * parseInt(metadata.pageSize) >= metadata.totalCount : false}
                                        icon={<ChevronRightIcon />}
                                        onClick={() => handlePagination(">")}
                                    />
                                </Flex>
                            </Flex> : null}
                    </Flex>
                </CardBody>
            </Card>
            {!!licItem ?
                <Modal isOpen={isOpen} onClose={() => setOpen(false)} size='3xl'>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Details</ModalHeader>
                        <ModalCloseButton onClick={() => setOpen(false)} />
                        <ModalBody>
                            <SimpleGrid columns={{ sm: 1, md: 3 }} columnGap={10} spacing='6'>
                                <Flex direction="column">
                                    <Text color='gray.400'>Licence</Text>
                                    <Text>{licItem.licence}</Text>
                                </Flex>
                                <Flex direction="column">
                                    <Text color='gray.400'>Expiry Date</Text>
                                    <Text>{licItem.purchaseOrder.licenceExpiryDate}</Text>
                                </Flex>
                                <Flex direction="column">
                                    <Text color='gray.400'>Order Id</Text>
                                    <Link ><Text color='blue.500'>{licItem.purchaseOrderId}</Text></Link>
                                </Flex>
                                <Flex direction="column">
                                    <Text color='gray.400'>Branch Name</Text>
                                    <Text>{licItem.purchaseOrder.branchId}</Text>
                                </Flex>
                                <Flex direction="column">
                                    <Text color='gray.400'>Last Login</Text>
                                    <Text>{licItem.lastLogin || 'NA'}</Text>
                                </Flex>
                                <Flex direction="column">
                                    <Text color='gray.400'>Login Count</Text>
                                    <Text>{licItem.usageCount || '0'}</Text>
                                </Flex>
                                <Flex direction="column">
                                    <Text color='gray.400'>Sent To</Text>
                                    <Text>{licItem.sentTo || 'n/a'}</Text>
                                </Flex>
                                <Flex direction="column">
                                    <Text color='gray.400'>Is Custom Template Enabled</Text>
                                    <Text>{licItem.purchaseOrder.customTemplating === true ? 'Yes' : 'No'}</Text>
                                </Flex>

                                <Flex direction="column">
                                    <Text color='gray.400'>Supported Languages</Text>
                                    <Link><Text color='green.500'>{!!licItem.configModule ? licItem.configModule.languageSupport.map(el => { return (<Badge me='2'>{el}</Badge>); }) : ''}</Text></Link>
                                </Flex>
                                <Flex direction="column">
                                    <Text color='gray.400'>Configration Module</Text>
                                    <Link onClick={() => {
                                        props.history.push({
                                            pathname: 'config/details',
                                            state: {
                                                moduleId: !!licItem ? licItem.configModule.configId : ''
                                            }
                                        })
                                    }}><Text color='green.500'>{!!licItem.configModule ? licItem.configModule.configId : ''}</Text></Link>
                                </Flex>
                            </SimpleGrid>
                            <Divider my='4' />
                            <SimpleGrid columns={{ sm: 1, md: 2 }} columnGap={10} spacing='6'>
                                <Flex direction="column">
                                    <Text color='gray.400'>What's included</Text>
                                    <Text>{licItem.purchaseOrder.contentIncluded ? "Content, " : ''} {licItem.purchaseOrder.quizIncluded ? "Quiz" : ''}</Text>
                                </Flex>
                                <Flex direction="column">
                                    <Text color='gray.400'>Certificate</Text>
                                    <Text>{!!licItem.certificateUrl ? <a href={licItem.certificateUrl} target="_blank" rel="noreferrer noopener">
                                        <Button size='xs' colorScheme='green'> Download </Button></a> : 'Not available yet!'}</Text>
                                </Flex></SimpleGrid>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='blue' size='sm' onClick={() => setOpen(false)}>
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                : null}
        </Flex>
    );
}

export default Clients;
