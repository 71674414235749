// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Badge,
  Text,
  SimpleGrid,
  VStack,
  Input,
  IconButton,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  LinkBox,
  LinkOverlay,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
// Assets
import email from "assets/img/email.png";
import BackgroundCard2 from "assets/img/solidcolor.png";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React, { useEffect, useState } from "react";
import Api from "../../helpers/Fetch";
import Util from "../../helpers/utils";
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Editor } from "@tinymce/tinymce-react";
import { RiEyeLine } from "react-icons/ri";
import { VscSave } from "react-icons/vsc";

function Content(props) {
  let api = new Api();
  let util = new Util();
  const [data, setData] = useState(null);
  const [dataReminder, setReminderData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [getId, setId] = useState(null);
  const [item, setItem] = useState(null);
  const nameRef = React.useRef();
  const editorRef = React.useRef(null);

  const open = () => setOpen(!isOpen);
  const close = () => setOpen(false);

  const [isDialogOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();
  const [isEdit, setEdit] = useState(false);
  const editorCertRef = React.useRef(null);
  useEffect(() => {
    getTemplates();
  }, []);
  const getTemplates = () => {
    api
      .getClientTemplate()
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        util.showServerError(err);
      });
    api
      .getReminderTemplate()
      .then((res) => {
        if (!!res.data.data) {
          setReminderData(res.data.data);
        }
      })
      .catch((err) => {
        util.showServerError(err);
      });
  };
  const saveTemplate = () => {
    setLoading(true);
    let content = editorRef.current.getContent();
    if (!content.includes("#clientId#") || !content.includes("#licence#")) {
      setLoading(false);
      util.showError(
        "Missing mandatory",
        "please include mandatory feild in your template"
      );
      return;
    }
    let name = nameRef.current.value;
    let data = {
      content,
      name,
    };
    api
      .addClientTemplate(data)
      .then((res) => {
        close();
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        util.showServerError(err);
        setLoading(false);
      });
  };
  const deleteTemplate = (id) => {
    setLoading(true);
    api
      .removeTemplate({ id: getId })
      .then((res) => {
        setIsOpen(false);
        getTemplates();
        setLoading(false);
      })
      .catch((err) => {
        util.showServerError(err);
        setLoading(false);
      });
  };
  const updateDefaults = (id) => {
    api
      .updateDefaultTemplate({ id: id })
      .then((res) => {
        close();
        getTemplates();
        setLoading(false);
      })
      .catch((err) => {
        util.showServerError(err);
        setLoading(false);
      });
  };
  const updateTemplate = (type) => {
    let content = editorCertRef.current.getContent();
    if (
      !content.includes("#licence#") ||
      !content.includes("#clientId#") ||
      !content.includes("#date#")
    ) {
      util.showError(
        "Missing fields",
        "Please include mandatory fields in template"
      );
      return;
    }
    api
      .updateReminderTemplate({ html: content })
      .then((res) => {
        setEdit(false);
        util.showSuccess("Reminder template updated");
        getTemplates();
        setLoading(false);
      })
      .catch((err) => {
        util.showServerError(err);
        setLoading(false);
      });
  };
  const deleteReminderTemplate = (type) => {
    api
      .deleteReminderTemplate()
      .then((res) => {
        setEdit(false);
        util.showSuccess("Reminder template Deleted");
        getTemplates();
        setLoading(false);
      })
      .catch((err) => {
        util.showServerError(err);
        setLoading(false);
      });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Tabs>
        <TabList>
          <Tab>License Invitation</Tab>
          <Tab>Reminder</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Grid templateRows="1fr" w="100%">
              <Box w="100%">
                <SimpleGrid
                  w="100%"
                  columns={{ sm: 1, md: 4 }}
                  spacingX="20px"
                  spacingY="10px"
                >
                  {!!data &&
                    data.templates.map((item) => {
                      return (
                        <VStack>
                          <Card>
                            <CardBody w="100%">
                              <Flex
                                direction="column"
                                color="gray.200"
                                justify="center"
                                w="100%"
                              >
                                <Flex justify="space-between">
                                  <Text
                                    fontSize="sm"
                                    fontWeight="bold"
                                    color="gray.600"
                                  >
                                    {item.name}
                                  </Text>
                                  {item.isSelected ? (
                                    <Badge colorScheme="green" variant="solid">
                                      {" "}
                                      Default{" "}
                                    </Badge>
                                  ) : (
                                    <Button
                                      variant="solid"
                                      colorScheme="blackAlpha"
                                      size="xs"
                                      onClick={() => updateDefaults(item._id)}
                                    >
                                      Set Default
                                    </Button>
                                  )}
                                </Flex>
                                <Box
                                  w="220px"
                                  mt="4"
                                  borderWidth="0px"
                                  borderRadius="10px"
                                  overflow="hidden"
                                >
                                  <Image
                                    src={email}
                                    alt="Dan Abramov"
                                    opacity="0.5"
                                    _hover={{ opacity: "1.0" }}
                                    objectFit="fill"
                                    fallbackSrc="https://via.placeholder.com/150"
                                  />
                                </Box>
                                <Flex justify="space-between" mt="12">
                                  <IconButton
                                    icon={<RiEyeLine />}
                                    variant="ghost"
                                    colorScheme="red"
                                    onClick={() => {
                                      setItem(item);
                                      setPreviewOpen(true);
                                    }}
                                  />
                                  <IconButton
                                    icon={<DeleteIcon />}
                                    id={item._id}
                                    variant="ghost"
                                    colorScheme="red"
                                    w="20px"
                                    onClick={(e) => {
                                      setId(item._id);
                                      setIsOpen(true);
                                    }}
                                  />
                                </Flex>
                              </Flex>
                            </CardBody>
                          </Card>
                        </VStack>
                      );
                    })}
                  <LinkBox p="2" rounded="md" h={140} mb="10">
                    <LinkOverlay onClick={open}>
                      <Card
                        backgroundImage={BackgroundCard2}
                        cor
                        backgroundRepeat="no-repeat"
                        background="cover"
                        bgPosition="10%"
                        p="8px"
                        h={{ sm: "220px", xl: "100%" }}
                        gridArea={{ md: "1 / 1 / 2 / 3", xl: "1 / 1 / 2 / 3" }}
                      >
                        <CardBody h="100%" w="100%">
                          <Flex
                            direction="column"
                            color="white"
                            h="100%"
                            p="0px 10px 20px 10px"
                            w="100%"
                          >
                            <Flex justify="center" align="center">
                              <Text fontSize="lg" fontWeight="bold"></Text>
                              <Icon
                                as={AddIcon}
                                w="30px"
                                h="auto"
                                mt={10}
                                color="gray.400"
                              />
                            </Flex>
                          </Flex>
                        </CardBody>
                      </Card>
                    </LinkOverlay>
                  </LinkBox>
                </SimpleGrid>
              </Box>
            </Grid>
          </TabPanel>
          <TabPanel>
            <Flex
              direction="row"
              whiteSpace={1}
              justify="space-between"
              mb="10px"
            >
              <Text>Preview</Text>
              <IconButton
                fontSize="xs"
                variant="solid"
                icon={!isEdit ? <EditIcon /> : <VscSave />}
                onClick={() => {
                  if (!isEdit) {
                    setEdit(true);
                  } else {
                    updateTemplate("REMINDER");
                  }
                }}
              ></IconButton>
            </Flex>
            <Flex direction="column">
              {!isEdit ? (
                <Box
                  borderWidth="1px"
                  borderRadius="sm"
                  overflow="scroll"
                  mt={2}
                  p={2}
                >
                  {!!dataReminder ? (
                    <Button
                      variant="link"
                      colorScheme="red"
                      pos="absolute"
                      right={16}
                      onClick={deleteReminderTemplate}
                    >
                      Remove
                    </Button>
                  ) : null}
                  <Text w="100%" p={1}>
                    {!!dataReminder ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: dataReminder.htmlText,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Text>
                </Box>
              ) : (
                <>
                  <Text>
                    <b>Mandatory Fields :</b>Use #clientId#, #licence#, #date#
                    to insert data.
                  </Text>
                  <Editor
                    apiKey="zj5ar504k7ncmrvz4ug4tkj0iu6ue9fvjtu2bhx9if2dhjzu"
                    onInit={(evt, editor) => (editorCertRef.current = editor)}
                    cloudChannel="dev"
                    initialValue={!!dataReminder ? dataReminder.htmlText : ""}
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </>
              )}
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex justify="space-between">
              <Text>Create new template</Text>
              <Button
                colorScheme="blue"
                variant="solid"
                onClick={saveTemplate}
                me="10"
              >
                Save
              </Button>
            </Flex>
          </ModalHeader>
          <ModalCloseButton onClick={close} mt="2" />
          <ModalBody>
            <Flex>
              <VStack alignItems="flex-start" justifyContent="center" w="100%">
                <Input
                  type="text"
                  ref={nameRef}
                  placeholder="Enter template name"
                  mb="6"
                />
                <Text>
                  <b>Mandatory: </b>Use #clientId#, #licence# for add fields
                </Text>
                <Editor
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue={""}
                  apiKey="zj5ar504k7ncmrvz4ug4tkj0iu6ue9fvjtu2bhx9if2dhjzu"
                  init={{
                    height: 500,
                    menubar: false,
                    width: "100%",
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
              </VStack>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={close}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {!!item ? (
        <Modal
          isOpen={isPreviewOpen}
          onClose={() => setPreviewOpen(false)}
          size="3xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Preview</ModalHeader>
            <ModalCloseButton onClick={() => setPreviewOpen(false)} />
            <ModalBody>
              <Box
                borderWidth="1px"
                borderRadius="sm"
                overflow="scroll"
                mt={2}
                p={2}
              >
                <Text w="100%" p={1}>
                  {!!item ? (
                    <div dangerouslySetInnerHTML={{ __html: item.htmlText }} />
                  ) : (
                    ""
                  )}
                </Text>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                size="sm"
                onClick={() => setPreviewOpen(false)}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}

      <AlertDialog
        isOpen={isDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={isLoading}
                ml={3}
                onClick={deleteTemplate}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}

export default Content;
