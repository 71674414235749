import React, { useState, useEffect } from "react";
// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Grid,
  GridItem,
  useToast,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesTableRow from "components/Tables/QuestionTableRow";
import { PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'
import Api from "../../helpers/Fetch";
import utils from "../../helpers/utils";
import Auth from '../../helpers/auth';
import { Separator } from "components/Separator/Separator";
import Utils from "../../helpers/utils";


function Clients(props) {
  const textColor = useColorModeValue("gray.700", "white");
  const [data, setData] = useState(null);
  const [moduleId, setModuleId] = useState(null);
  const [query, setQuery] = useState(null);
  let api = new Api();
  let util = new Utils();
  let toast = useToast();
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "red.800"
  );
  useEffect(() => {
    let data = {
      currentPage: 1
    }
    if (!!props && !!props.location && !!props.location.state) {
      data.moduleId = props.location.state.moduleId;
      setModuleId(data.moduleId)
    }
    setModuleId(localStorage.getItem('moduleId'));
    data.moduleId = localStorage.getItem('moduleId');
    console.log('props', props);
    getQuestions(data);

  }, []);
  const getQuestions = (data) => {
    setQuery(data);
    api.getQuizQuestions(data).then((res) => {
      console.log(res);
      setData(res.data.data)

    }).catch((err) => {
      console.log(err);
      util.showServerError(err);

    });
  }
  const onclickHandler = (data) => {
    console.log("row clicked", data);
    if (!!data) {
      if (data.action === 'delete') {
        api.deleteQuizQuestion({ questionId: data.id }).then((res) => {
          console.log(res);
          getQuestions(query);
        }).catch((err) => {
          console.log(err);
          util.showServerError(err);

        });
      }
      else if (data.action === 'disable') {
        api.changeQuestionStatus({ questionId: data.id.questionId, status: !data.id.isActive }).then((res) => {
          console.log(res);
          getQuestions(query);
        }).catch((err) => {
          console.log(err);
          util.showServerError(err);

        });
      }
    }
  }

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex justify="space-between" align="center" minHeight="60px" w="100%">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Questions
            </Text>
            <Button
              bg={bgButton}
              color="white"
              fontSize="xs"
              variant="solid"
              leftIcon={<AddIcon />}
              onClick={() => props.history.push({
                pathname: '/admin/add-question',
                state: {
                  moduleId: moduleId
                }
              })}

            >
              Add Question
            </Button>
          </Flex>

        </CardHeader>
        <Separator />
        <CardBody>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".3rem" pl="10px" color="gray.400">
                <Th color="gray.400">Question ID</Th>
                <Th color="gray.400" minW={{ sm: '200px', md: "400px" }}>Question</Th>
                <Th color="gray.400">Language</Th>
                <Th color="gray.400">Chapter</Th>
                <Th color="gray.400">Mark Fixed</Th>
                <Th color="gray.400">Status</Th>

                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {!!data && data.map((row) => {
                return (
                  <TablesTableRow
                    data={row}
                    onclick={onclickHandler}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Clients;
