import {
    Avatar,
    Badge,
    Button,
    Flex,
    Td,
    Text,
    Tr,
    Menu,
    MenuButton,
    MenuList,
    List, ListItem, ListIcon,
    MenuItem,
    IconButton,
    useColorModeValue,
} from "@chakra-ui/react";
import { VscMenu } from 'react-icons/vsc'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { MdCancel } from 'react-icons/md'
import React from "react";

function TablesTableRow(props) {
    const data = props.data;
    let index = props.index;
    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.400");

    return (
        <Tr>
            <Td>
                <Flex direction="column">
                    <Text
                        fontSize="sm"
                        color={textColor}
                        fontWeight="bold"
                        minWidth="80%"
                    >
                        {index}
                    </Text>
                </Flex>
            </Td>

            <Td>
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                        {data.orderId}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="normal" noOfLines={1}>
                        {data.client.companyName}
                    </Text>
                    <Text fontSize="sm" color="gray.400" fontWeight="normal" fontSize={12}>
                        {data.branch.branchName}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem" color="blue.500">
                    <a href="#" onClick={() => props.onclick({ id: data.orderId, action: "view_licences" })}>{data.licenceQuantity}</a>
                </Text>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
                    {data.licenceQuantity * data.pricePerLicence}
                </Text>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
                    {data.licenceExpiryDate}
                </Text>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
                    {data.status === 'Delivered' ? <Badge variant="solid" colorScheme='yellow'>{data.status}</Badge> : data.status === 'Declined' ? <Badge colorScheme='red'>{data.status}</Badge> : <Badge colorScheme='green'>{data.status}</Badge>}
                </Text>
            </Td>
            <Td>
                <Text fontSize="sm" color={textColor} fontWeight="normal" pb=".5rem">
                    <List spacing={2}>
                        <ListItem>
                            <ListIcon as={data.contentIncluded == true ? BsFillCheckCircleFill : MdCancel} color={data.contentIncluded == true ? 'green.500' : 'red.500'} />
                            Content
                        </ListItem>
                        <ListItem>
                            <ListIcon as={data.quizIncluded == true ? BsFillCheckCircleFill : MdCancel} color={data.quizIncluded == true ? 'green.500' : 'red.500'} />
                            Quiz
                        </ListItem>
                        <ListItem>
                            <ListIcon as={data.customEmailing == true ? BsFillCheckCircleFill : MdCancel} color={data.customEmailing == true ? 'green.500' : 'red.500'} />
                            Custom Emailing
                        </ListItem>
                        <ListItem>
                            <ListIcon as={data.customTemplating == true ? BsFillCheckCircleFill : MdCancel} color={data.customTemplating == true ? 'green.500' : 'red.500'} />
                            Custom Templates
                        </ListItem>
                    </List>
                </Text>
            </Td>
            <Td>
                <Menu>
                    <MenuButton as={IconButton} icon={<VscMenu />}>

                    </MenuButton>
                    <MenuList>
                        {data.licencesReleased === false && data.status === 'accepted' ?
                            <MenuItem onClick={() => props.onclick({ id: data.orderId, action: "release" })}>Release Now</MenuItem>
                            : null}
                        <MenuItem onClick={() => props.onclick({ id: data.orderId, action: "view" })}>View</MenuItem>
                        {data.licencesReleased === false ?
                            <MenuItem onClick={() => props.onclick({ id: data.orderId, action: "delete" })}>Delete</MenuItem>
                            : null}
                    </MenuList>
                </Menu>
            </Td>
        </Tr >
    );
}

export default TablesTableRow;
