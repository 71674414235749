// import
import Dashboard from "views/ClientDashboard/Dashboard.js";
import Branches from "views/ClientDashboard/Branches.js";
import licences from "views/ClientDashboard/licences.js";
import Templates from "views/ClientDashboard/Templates";
import Support from "views/ClientDashboard/Support";
import Profile from "views/ClientDashboard/profile";
import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
} from "components/Icons/Icons";
import { BiBookContent } from 'react-icons/bi';
import { GiThreeKeys } from "react-icons/gi";
import { IoGitBranchOutline } from "react-icons/io5";
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { PersonIcon } from "components/Icons/Icons";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: Dashboard,
    layout: "/client",
  },
  {
    path: "/branches",
    name: "Branches",
    icon: <IoGitBranchOutline color="inherit" />,
    component: Branches,
    layout: "/client",
  },
  {
    path: "/licences",
    name: "Licences",
    icon: <GiThreeKeys color="inherit" />,
    component: licences,
    layout: "/client",
  },
  {
    path: "/templates",
    name: "Templates",
    icon: <BiBookContent color="inherit" />,
    component: Templates,
    layout: "/client",
  },
  {
    path: "/support",
    name: "Help & Support",
    icon: <IoIosHelpCircleOutline color="inherit" />,
    component: Support,
    layout: "/client",
  },
  {
    path: "/profile",
    name: "Profile",
    icon: <PersonIcon color="inherit" />,
    component: Profile,
    layout: "/client",
  }
];
export default dashRoutes;
