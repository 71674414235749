import React, { useState, useEffect } from "react";
// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  Button,
  Grid,
  GridItem,
  Spacer,
  useToast,
  Input,
  IconButton,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  Checkbox,
  Link,
  Select,
  SimpleGrid,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesTableRow from "components/Tables/ClientLicencesTableRow";
import {
  PhoneIcon,
  AddIcon,
  WarningIcon,
  ArrowBackIcon,
  ArrowDownIcon,
  EditIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import Api from "../../helpers/Fetch";
import Util from "../../helpers/utils";
import { Separator } from "components/Separator/Separator";
import { BiChevronDown } from "react-icons/bi";
import { IoSendOutline } from "react-icons/io5";
import readXlsxFile from "read-excel-file";
import validator from "validator";
import debounce from "lodash.debounce";
import moment from "moment";

function Clients(props) {
  const textColor = useColorModeValue("gray.700", "white");
  const [branches, setBranches] = useState(null);
  const [licences, setLicences] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [query, setQuery] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [selected, setSelected] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [template, setTemplate] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [licItem, setLicItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [seletedBranch, setSelectedBranch] = useState(null);

  const [isIndeterminate, setIndeterminate] = useState(false);
  let api = new Api();
  let util = new Util();
  let toast = useToast();
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "red.800"
  );
  useEffect(() => {
    let data = {
      pageSize: localStorage.getItem("pageSize") || 10,
      currentPage: currentPage,
    };
    setPageSize(data.pageSize);
    if (!!props && !!props.location && !!props.location.state) {
      data.branchId = props.location.state.branchId;
    }
    api
      .getBranches(data)
      .then((res) => {
        console.log(res);
        setBranches(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        util.showServerError(err);
      });
    getLicences(data);
    getTemplates();
  }, []);

  const getTemplates = () => {
    api
      .getClientTemplate()
      .then((res) => {
        if (!!res.data.data.templates && res.data.data.templates.length > 0) {
          res.data.data.templates.forEach((element) => {
            if (element.isSelected === true) {
              setTemplate(element);
            }
          });
        }
      })
      .catch((err) => {
        util.showServerError(err);
      });
  };
  const getLicences = (data) => {
    if (!!data.pageSize) {
      localStorage.setItem("pageSize", data.pageSize);
    }
    setQuery(data);
    api
      .getLicences(data)
      .then((res) => {
        console.log(res);
        let result = res.data.data;
        result.map((item) => {
          item.selected = false;
          item.email = "";
        });
        setLicences(result);
        setMetadata(res.data.meta);
      })
      .catch((err) => {
        console.log(err);
        util.showServerError(err);
      });
  };
  const onclickHandler = (data) => {
    console.log("row clicked", data);
    if (!!data) {
      if (data.action === "view") {
        console.log("lic", data.data);
        setOpen(true);
        setLicItem(data.data);
      }
      if (data.action === "check_change") {
        let tempList = licences;
        tempList.map((user) => {
          if (user.licence === data.id.licence) {
            user.selected = data.event.target.checked;
          }
          return user;
        });

        //To Control Master Checkbox State
        const totalItems = licences.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;
        setIndeterminate(totalCheckedItems !== totalItems);
        setLicences([...tempList]);
        getSelectedRows();
      }
      if (data.action === "email_input") {
        let tempList = licences;
        tempList.map((user) => {
          if (user.licence === data.id.licence) {
            user.email = data.event.target.value;
            if (validator.isEmail(data.event.target.value)) {
              user.selected = true;
            } else {
              user.selected = false;
            }
          }
          return user;
        });
        const totalItems = licences.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;
        setIndeterminate(totalCheckedItems !== totalItems);
        setLicences([...tempList]);
        getSelectedRows();
      }
      if (data.action === "name_input") {
        let tempList = licences;
        tempList.map((user) => {
          if (user.licence === data.id.licence) {
            user.name = data.event.target.value;
            if (!validator.isEmpty(data.event.target.value)) {
              user.selected = true;
            } else {
              user.selected = false;
            }
          }
          return user;
        });
        const totalItems = licences.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;
        setIndeterminate(totalCheckedItems !== totalItems);
        setLicences([...tempList]);
        getSelectedRows();
      }
      if (data.action === "emp_code_input") {
        let tempList = licences;
        tempList.map((user) => {
          if (user.licence === data.id.licence) {
            user.empCode = data.event.target.value;
            if (!validator.isEmpty(data.event.target.value)) {
              user.selected = true;
            } else {
              user.selected = false;
            }
          }
          return user;
        });
        const totalItems = licences.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;
        setIndeterminate(totalCheckedItems !== totalItems);
        setLicences([...tempList]);
        getSelectedRows();
      }
      if (data.action === "reminder") {
        api
          .sendReminder({ licence: data.id })
          .then((res) => {
            console.log(res);
            util.showSuccess(res.data.message);
          })
          .catch((err) => {
            console.log(err);
            util.showServerError(err);
          });
      }
    }
  };
  const getSelectedRows = () => {
    let selected = licences.filter((e) => e.selected);
    console.log("selected", selected);
    setSelected(selected);
  };

  const onMasterCheck = (e) => {
    let tempList = licences;
    tempList.map((item) => (item.selected = e.target.checked));
    console.log(tempList);
    setLicences(null);
    setLicences([...tempList]);
    getSelectedRows();
  };
  const changeBranch = (e) => {
    let val = e.target.value;
    let data = {
      pageSize: 10,
      currentPage: 1,
      branchId: val,
    };
    setSelectedBranch(val);
    getLicences(data);
  };
  const uploadFile = (e) => {
    console.log(e);
    readXlsxFile(e.target.files[0]).then((rows) => {
      console.log(rows);
      let emails = [];
      let names = [];
      let empCode = [];
      rows.map((item, i) => {
        if (i > 0) {
          if (!validator.isEmpty(item[0]) && validator.isEmail(item[0])) {
            emails.push(item[0]);
            names.push(item[1]);
            empCode.push(item[2]);
          }
        }
      });
      if (emails.length > 0) {
        let tempList = licences;
        tempList.map((item, i) => {
          if (i >= emails.length) {
            return;
          }
          item.selected = true;
          item.email = i <= emails.length - 1 ? emails[i] : "";
          item.name = i <= names.length - 1 ? names[i] : "";
          item.empCode = i <= empCode.length - 1 ? empCode[i] : "";
        });
        const totalItems = licences.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;
        setIndeterminate(totalCheckedItems !== totalItems);
        setLicences(null);
        setLicences([...tempList]);
        getSelectedRows();
      }
    });
  };
  const sendInvites = async () => {
    console.log("licences", selected);
    debugger;
    setLoading(true);
    if (!selected || selected.length == 0) {
      setLoading(false);
      util.showInfo("Selection Empty", "Please fill email ids to send invites");
      return;
    }
    if (
      selected.filter((e) => {
        return validator.isEmpty(e.sentTo) && !validator.isEmail(e.email);
      }).length > 0
    ) {
      setLoading(false);
      util.showInfo(
        "Validation error",
        "one or more email fields have incorrect input filled"
      );
      return;
    }
    for (const item of selected) {
      if (validator.isEmpty(item.sentTo)) {
        let data = {
          inviteStatus: item.inviteStatus,
          email: item.email,
          name: item.name,
          empCode: item.empCode,
          licence: item.licence,
          purchaseOrderId: item.purchaseOrderId,
        };
        updateItem(item, "sending..");
        try {
          let res = await api.sendInvite(data);
          console.log(res);
          updateItem(item, "sent!");
        } catch (err) {
          console.log(err);
          updateItem(item, "failed");
        }
      }
      setSelected(null);
      setLoading(false);
    }
  };
  const updateItem = (item, state) => {
    let tempList = licences;
    tempList.map((row) => {
      if (row.licence === item.licence) {
        row.sentTo = item.email;
        row.inviteStatus = "invited";
        row.pendingStatus = state;
        row.name = item.name;
        row.empCode = item.emp_code || item.empCode;
      }
      return row;
    });
    setLicences([...tempList]);
  };
  const debouncedFetchData = debounce((query, cb) => {
    getLicences(query);
    cb();
  }, 500);

  const handleSearch = (event) => {
    debouncedFetchData(
      { search: event.target.value, branchId: seletedBranch },
      (res) => {}
    );
  };

  const filter = (filter) => {
    console.log(filter);
    getLicences({
      filter: filter,
      pageSize: localStorage.getItem("pageSize") || 10,
    });
  };
  const handlePagination = (direction) => {
    if (direction === ">") {
      if (!!metadata) {
        let q = {
          ...query,
          currentPage: parseInt(metadata.currentPage) + 1,
        };
        getLicences(q);
      }
    } else {
      if (!!metadata) {
        let q = {
          ...query,
          currentPage: parseInt(metadata.currentPage) - 1,
        };
        getLicences(q);
      }
    }
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 2px 0px">
          <Flex direction="column" w="100%">
            <Flex
              justify="space-between"
              align="center"
              minHeight="60px"
              w="100%"
            >
              <Select
                rightIcon={<BiChevronDown />}
                colorScheme="red"
                variant="outline"
                maxW="300px"
                placeholder="Select branch"
                onChange={changeBranch}
              >
                {!!branches &&
                  branches.map((item) => {
                    return (
                      <option value={item.branchId}>{item.branchName}</option>
                    );
                  })}
              </Select>

              <Button
                bg={bgButton}
                color="white"
                fontSize="xs"
                variant="solid"
                leftIcon={<ArrowBackIcon />}
                onClick={() => props.history.goBack()}
              >
                Back
              </Button>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex justify="space-between" w="100%">
            <Flex ml="0.5" mt="6" direction="column">
              <Text fontWeight="semibold">Template </Text>
              <Text fontWeight="normal" color="gray.400" py="2">
                {!!template ? template.name : "System Default"}{" "}
                <EditIcon
                  onClick={() => props.history.push("/client/templates")}
                />
              </Text>
            </Flex>
            <Flex ml="0.5" mt="6" direction="column">
              <Text fontWeight="semibold">Import Emails </Text>
              <Input type="file" py="1" onChange={(e) => uploadFile(e)} />
              <Button variant="link" pos="absolute" right={8} py={1}>
                Sample file
              </Button>
            </Flex>
          </Flex>
        </CardBody>
      </Card>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} mt="3">
        <CardHeader p="6px 0px 2px 0px">
          <Flex direction="column" w="100%"></Flex>
        </CardHeader>
        <CardBody>
          <Flex direction="column">
            <Flex justify="space-between" align="center" w="100%">
              <InputGroup maxW="20%" mb={2}>
                <InputLeftElement
                  pointerEvents="none"
                  children={
                    <SearchIcon color="gray.300" h="14px" w="14px" mb="2" />
                  }
                />
                <Input
                  type="text"
                  size="sm"
                  placeholder="search email, licence"
                  onChange={handleSearch}
                />
              </InputGroup>
              <Text color="gray.400" ml="4" pb="4">
                Total Selected:{" "}
                <strong>{!!selected ? selected.length : "0"}</strong>
              </Text>
              <IconButton
                variant="link"
                colorScheme="red"
                fontSize="20px"
                mb={2}
                isLoading={isLoading}
                disabled={selected == 0}
                icon={<IoSendOutline />}
                onClick={sendInvites}
              />
            </Flex>
            <Divider mb="3" />
            <Flex>
              <Button size="xs" me="4" onClick={() => filter("unissued")}>
                Not issued
              </Button>
              <Button size="xs" me="4" onClick={() => filter("issued")}>
                Issued
              </Button>
              <Button size="xs" me="4" onClick={() => filter("used")}>
                Used
              </Button>
              <Button size="xs" me="4" onClick={() => filter("unused")}>
                Unused
              </Button>
              <Button size="xs" onClick={() => filter("certified")}>
                Certified
              </Button>
            </Flex>
            <Divider mb="3" mt="3" />

            <Table
              variant="simple"
              color={textColor}
              size="sm"
              overflowX="scroll"
            >
              <Thead>
                <Tr mb="10px" pl="10px" color="gray.500">
                  <Th color="gray.400">#</Th>
                  <Th color="gray.400">
                    <Checkbox
                      onChange={onMasterCheck}
                      isIndeterminate={isIndeterminate}
                    />
                  </Th>
                  <Th color="gray.400">Licence</Th>
                  <Th color="gray.400">Send to</Th>
                  <Th color="gray.400">Emp. Name</Th>
                  <Th color="gray.400">Emp Code</Th>
                  <Th color="gray.400">Usage</Th>
                  <Th color="gray.400">Content Read(%)</Th>
                  <Th color="gray.400">Quiz Attempt</Th>
                  <Th color="gray.400">Certificate</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!!licences &&
                  licences.map((row, i) => {
                    return (
                      <TablesTableRow
                        data={row}
                        index={i + 1}
                        onclick={onclickHandler}
                      />
                    );
                  })}
              </Tbody>
            </Table>
            {!!licences && licences.length > 0 ? (
              <Flex justify="space-between">
                <Select
                  mt="4"
                  size="sm"
                  maxW="100px"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(e.target.value);
                    getLicences({
                      pageSize: e.target.value,
                      currentPage: currentPage,
                    });
                  }}
                >
                  {[10, 30, 50, 100, 200].map((item) => {
                    return <option value={item}>{item}</option>;
                  })}
                </Select>
                <Flex me="8" mt="4">
                  {!!metadata ? (
                    <Text fontWeight="thin" me="6" py="2" size="sm">
                      Page {metadata.currentPage}, Showing {metadata.pageSize}{" "}
                      of {metadata.totalCount}
                    </Text>
                  ) : null}
                  <IconButton
                    variant="outline"
                    colorScheme="gray"
                    fontSize="20px"
                    mb={2}
                    me={2}
                    isLoading={isLoading}
                    disabled={
                      !!metadata ? parseInt(metadata.currentPage) === 1 : false
                    }
                    icon={<ChevronLeftIcon />}
                    onClick={() => {
                      handlePagination("<");
                    }}
                  />
                  <IconButton
                    variant="outline"
                    colorScheme="gray"
                    fontSize="20px"
                    mb={2}
                    isLoading={isLoading}
                    disabled={
                      !!metadata
                        ? parseInt(metadata.currentPage) *
                            parseInt(metadata.pageSize) >=
                          metadata.totalCount
                        : false
                    }
                    icon={<ChevronRightIcon />}
                    onClick={() => handlePagination(">")}
                  />
                </Flex>
              </Flex>
            ) : null}
          </Flex>
        </CardBody>
      </Card>
      {!!licItem ? (
        <Modal isOpen={isOpen} onClose={() => setOpen(false)} size="3xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Details</ModalHeader>
            <ModalCloseButton onClick={() => setOpen(false)} />
            <ModalBody>
              <SimpleGrid columns={{ sm: 1, md: 3 }} columnGap={10} spacing="6">
                <Flex direction="column">
                  <Text color="gray.400">Licence</Text>
                  <Text>{licItem.licence}</Text>
                </Flex>
                <Flex direction="column">
                  <Text color="gray.400">Expiry Date</Text>
                  <Text>{licItem.purchaseOrder.licenceExpiryDate}</Text>
                </Flex>
                <Flex direction="column">
                  <Text color="gray.400">Order Id</Text>
                  <Text>{licItem.purchaseOrderId}</Text>
                </Flex>
                <Flex direction="column">
                  <Text color="gray.400">Branch Name</Text>
                  <Text>{licItem.purchaseOrder.branchId}</Text>
                </Flex>
                <Flex direction="column">
                  <Text color="gray.400">Last Login</Text>
                  <Text>{licItem.lastLogin || "NA"}</Text>
                </Flex>
                <Flex direction="column">
                  <Text color="gray.400">Login Count</Text>
                  <Text>{licItem.usageCount || "0"}</Text>
                </Flex>
                <Flex direction="column">
                  <Text color="gray.400">Sent To</Text>
                  <Text>{licItem.sentTo || "n/a"}</Text>
                </Flex>
                <Flex direction="column">
                  <Text color="gray.400">Emp Name</Text>
                  <Text>{licItem.empName || "n/a"}</Text>
                </Flex>
                <Flex direction="column">
                  <Text color="gray.400">Emp Code</Text>
                  <Text>{licItem.empCode || "n/a"}</Text>
                </Flex>
                <Flex direction="column">
                  <Text color="gray.400">Is Custom Template Enabled</Text>
                  <Text>
                    {licItem.purchaseOrder.customTemplating === true
                      ? "Yes"
                      : "No"}
                  </Text>
                </Flex>
              </SimpleGrid>
              <Divider my="4" />
              <SimpleGrid columns={{ sm: 1, md: 2 }} columnGap={10} spacing="6">
                <Flex direction="column">
                  <Text color="gray.400">What's included</Text>
                  <Text>
                    {licItem.purchaseOrder.contentIncluded ? "Content, " : ""}{" "}
                    {licItem.purchaseOrder.quizIncluded ? "Quiz" : ""}
                  </Text>
                </Flex>
                <Flex direction="column">
                  <Text color="gray.400">Certificate</Text>
                  <Text>
                    {!!licItem.certificateUrl ? (
                      <a
                        href={licItem.certificateUrl}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <Button size="xs" colorScheme="green">
                          {" "}
                          Download{" "}
                        </Button>
                      </a>
                    ) : (
                      "Not available yet!"
                    )}
                  </Text>
                </Flex>
                <Flex direction="column">
                  <Text color="gray.400">Certificate Issue Date</Text>
                  <Text>
                    {!!licItem.certificateIssueDate
                      ? licItem.certificateIssueDate
                      : "-"}
                  </Text>
                </Flex>
                <Flex direction="column">
                  <Text color="gray.400">Certificate Expiry</Text>
                  <Text>
                    {!!licItem.certificateIssueDate
                      ? moment(licItem.certificateIssueDate)
                          .add(1, "y")
                          .format("dd/MM/YYYY")
                      : "-"}
                  </Text>
                </Flex>
              </SimpleGrid>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                size="sm"
                onClick={() => setOpen(false)}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </Flex>
  );
}

export default Clients;
