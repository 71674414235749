import React, { useState, useEffect } from "react";
// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Grid,
  GridItem,
  useToast,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import TablesTableRow from "components/Tables/ClientBranchesTableRow";
import { tablesProjectData, tablesTableData } from "variables/general";
import { PhoneIcon, AddIcon, WarningIcon, ArrowBackIcon } from '@chakra-ui/icons'
import Api from "../../helpers/Fetch";
import Auth from '../../helpers/auth';
import Util from "../../helpers/utils";
import { Separator } from "components/Separator/Separator";


function Clients(props) {
  const textColor = useColorModeValue("gray.700", "white");
  const [data, setData] = useState(null);
  const [pageIndex, setPage] = useState(1);
  let api = new Api();
  let util = new Util();
  let toast = useToast();
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "red.800"
  );
  useEffect(() => {
    let data = {
      pageSize: 10,
      currentPage: pageIndex
    }
    api.getBranches(data).then((res) => {
      console.log(res);
      setData(res.data.data)

    }).catch((err) => {
      console.log(err);
      util.showServerError(err);
    });
  }, []);
  const onclickHandler = (data) => {
    console.log("row clicked", data);
    if (!!data) {
      if (data.action === 'open_licence') {
        props.history.push({
          pathname: '/client/licences',
          state: {
            branchId: data.data.branchId
          }
        })
      }
    }
  }

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex justify="space-between" align="center" minHeight="60px" w="100%">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Branches
            </Text>
            <Button
              bg={bgButton}
              color="white"
              fontSize="xs"
              variant="solid"
              leftIcon={<ArrowBackIcon />}
              onClick={() => props.history.push('/admin/add-client')}

            >
              Back
            </Button>
          </Flex>

        </CardHeader>
        <CardBody>
          <Table variant="simple" color={textColor} size='sm' overflowX='scroll'>
            <Thead>
              <Tr my=".3rem" pl="10px" color="gray.500">
                <Th color="gray.400">Branch</Th>
                <Th color="gray.400">Email</Th>
                <Th color="gray.400">City</Th>
                <Th color="gray.400">Licences</Th>
                <Th color="gray.400">Used</Th>
                <Th color="gray.400">Unused</Th>
                <Th color="gray.400">Certificates</Th>
              </Tr>
            </Thead>
            <Tbody>

              {!!data && data.map((row) => {
                return (
                  <TablesTableRow
                    data={row}
                    onclick={onclickHandler}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Clients;
