import {
    Avatar,
    Badge,
    Button,
    Flex,
    Td,
    Text,
    Tr,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuIcon,
    IconButton,
    useColorModeValue,
} from "@chakra-ui/react";
import { VscMenu } from 'react-icons/vsc'
import React from "react";
import { BsEye } from "react-icons/bs";

function TablesTableRow(props) {
    const data = props.data;
    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.400");

    return (
        <Tr>
            <Td>
                <Flex direction="column" w='100px'>
                    <Text fontSize="sm" fontWeight="normal">
                        {data.branchName}
                    </Text>
                    <Text fontSize="sm" color='gray.400' fontWeight="normal">
                        {data.branchId}
                    </Text>
                </Flex>
            </Td>

            <Td>
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="normal">
                        {data.branchEmail}
                    </Text>

                </Flex>
            </Td>
            <Td>
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="normal">
                        {data.state}/{data.city}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <Button variant='link' fontSize="md" color={textColor}
                    fontWeight="bold" pb=".5rem" color="blue.500"
                    onClick={() => props.onclick({ action: 'open_licence', data: data })}>
                    {data.licences}
                </Button>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
                    {data.used}
                </Text>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
                    {data.unused}
                </Text>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
                    {data.certificates}
                </Text>
            </Td>
        </Tr>
    );
}

export default TablesTableRow;
