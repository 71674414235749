// Chakra imports
import React, { useEffect, useState } from "react";
import {
  Flex,
  SimpleGrid,
  Spacer,
  Stat,
  Icon,
  StatHelpText,
  StatLabel,
  StatNumber,
  Box,
  useColorMode,
  Link,
  useColorModeValue,
  Tooltip,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import IconBox from "components/Icons/IconBox";
// Custom icons
import { BiMailSend, BiCertification } from "react-icons/bi";
// react icons
import { GoGitBranch } from "react-icons/go";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { RiKeyLine } from "react-icons/ri";
import api from "../../helpers/Fetch";
import utils from "../../helpers/utils";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as tp,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import { DownloadIcon } from "@chakra-ui/icons";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  tp,
  Legend
);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "",
    },
  },
};
export default function Dashboard() {
  const value = "$100.000";
  // Chakra Color Mode
  const { colorMode, toggleColorMode } = useColorMode();
  const iconTeal = useColorModeValue("red.300", "red.300");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const [data, setData] = useState(null);
  const [c1, setC1] = useState(null);
  const [c2, setC2] = useState(null);
  const [c3, setC3] = useState(null);
  const [c4, setC4] = useState(null);
  const [c5, setC5] = useState(null);

  let API = new api();
  let Util = new utils();
  useEffect(() => {
    API.clientDashboard()
      .then((res) => {
        console.log(res.data);
        setData(res.data.data);
        setC1(
          setFirst(
            res.data.data.issues_notIssued,
            ["Issued", "Not Issued"],
            "Issued Ratio"
          )
        );
        setC2(
          setFirst(res.data.data.issued_used, ["Issued", "Used"], "Used Ratio")
        );
        setC3(
          setFirst(
            res.data.data.issued_certified,
            ["Issued", "Certified"],
            "Certified Ratio"
          )
        );
        setC4(
          setBar(
            res.data.data.failed_attempt_ranges,
            ["Quiz Failed Attempts"],
            "Certified Ratio"
          )
        );
        setC5(
          setBar2(
            res.data.data.content_completion,
            ["Content Completion Percentage"],
            "Certified Ratio"
          )
        );
      })
      .then((err) => {
        console.log(err);
        Util.showServerError(err);
      });
  }, []);
  const setFirst = (d, labels, label) => {
    let data = d.split("/").map(Number);
    const cdata = {
      labels: labels,
      datasets: [
        {
          label: label,
          data: data,
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
          ],
          borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
          borderWidth: 1,
        },
      ],
    };
    return cdata;
  };
  const setBar = (d, labels, label) => {
    const cdata = {
      labels,
      datasets: [
        {
          label: "1 to 3",
          data: [d.first],
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: "4 to 7",
          data: [d.second],
          backgroundColor: "rgba(54, 162, 235, 0.2)",
        },
        {
          label: "7 to 10",
          data: [d.third],
          backgroundColor: "rgba(60, 132, 60, 0.2)",
        },
      ],
    };
    return cdata;
  };
  const setBar2 = (d, labels, label) => {
    const cdata = {
      labels,
      datasets: [
        {
          label: ">25%",
          data: [d._25perc],
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: ">50%",
          data: [d._50perc],
          backgroundColor: "rgba(154, 152, 235, 0.2)",
        },
        {
          label: ">75%",
          data: [d._75perc],
          backgroundColor: "rgba(56, 199, 122, 0.5)",
        },
        {
          label: "100%",
          data: [d._100perc],
          backgroundColor: "rgba(60, 132, 60, 0.2)",
        },
      ],
    };
    return cdata;
  };
  const callGenerateReport = () => {
    API.clientDownloadReport()
      .then((response) => {
        let data = response.data.data;
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = {
          Sheets: { data: ws },
          SheetNames: ["data"],
        };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const file = new Blob([excelBuffer], { type: fileType });
        saveAs(file, "report_" + moment().format("MMM_DD_YY"));
      })
      .catch((err) => {
        Util.showServerError(err);
      });
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px">
        <Card minH="83px">
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat me="auto">
                <StatLabel
                  fontSize="sm"
                  color="gray.400"
                  fontWeight="bold"
                  pb=".1rem"
                >
                  Total Branches
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor}>
                    {!!data ? data.branches : 0}
                  </StatNumber>
                  <StatHelpText
                    alignSelf="flex-end"
                    justifySelf="flex-end"
                    m="0px"
                    color="green.400"
                    fontWeight="bold"
                    ps="3px"
                    fontSize="md"
                  ></StatHelpText>
                </Flex>
              </Stat>
              <IconBox as="box" h={"45px"} w={"45px"} bg={iconTeal}>
                <Icon
                  as={GoGitBranch}
                  h={"24px"}
                  w={"24px"}
                  color={iconBoxInside}
                />
              </IconBox>
            </Flex>
          </CardBody>
        </Card>
        <Card minH="83px">
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat me="auto">
                <StatLabel
                  fontSize="sm"
                  color="gray.400"
                  fontWeight="bold"
                  pb=".1rem"
                >
                  Total Licences
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor}>
                    {!!data ? data.licCount : 0}
                  </StatNumber>
                  <StatHelpText
                    alignSelf="flex-end"
                    justifySelf="flex-end"
                    m="0px"
                    color="green.400"
                    fontWeight="bold"
                    ps="3px"
                    fontSize="md"
                  ></StatHelpText>
                </Flex>
              </Stat>
              <IconBox as="box" h={"45px"} w={"45px"} bg={iconTeal}>
                <Icon
                  as={RiKeyLine}
                  h={"24px"}
                  w={"24px"}
                  color={iconBoxInside}
                />
              </IconBox>
            </Flex>
          </CardBody>
        </Card>
        <Card minH="83px">
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat>
                <StatLabel
                  fontSize="sm"
                  color="gray.400"
                  fontWeight="bold"
                  pb=".1rem"
                >
                  Issued
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor}>
                    {!!data ? data.issued : 0}
                  </StatNumber>
                </Flex>
              </Stat>
              <Spacer />
              <IconBox as="box" h={"45px"} w={"45px"} bg={iconTeal}>
                <Icon
                  as={BiMailSend}
                  h={"24px"}
                  w={"24px"}
                  color={iconBoxInside}
                />
              </IconBox>
            </Flex>
          </CardBody>
        </Card>
        <Card minH="83px">
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat me="auto">
                <StatLabel
                  fontSize="sm"
                  color="gray.400"
                  fontWeight="bold"
                  pb=".1rem"
                >
                  Employee Trained
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                    {!!data ? data.certified : 0}
                  </StatNumber>
                  <StatHelpText
                    alignSelf="flex-end"
                    justifySelf="flex-end"
                    m="0px"
                    color="green.400"
                    fontWeight="bold"
                    ps="3px"
                    fontSize="md"
                  >
                    +0%
                  </StatHelpText>
                </Flex>
              </Stat>
              <IconBox as="box" h={"45px"} w={"45px"} bg={iconTeal}>
                <Icon
                  as={AiFillSafetyCertificate}
                  h={"24px"}
                  w={"24px"}
                  color={iconBoxInside}
                />
              </IconBox>
            </Flex>
          </CardBody>
        </Card>
      </SimpleGrid>
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="10">
        <Box
          w="100%"
          bg="white"
          borderRadius="10px"
          boxShadow="sm"
          p={6}
          mt="20px"
          justifyItems="center"
        >
          <Box maxW="300px" maxH="300px" alignSelf="center" ms="20%">
            {!!c1 ? (
              <Pie
                data={c1}
                width={10}
                height={10}
                options={{ maintainAspectRatio: true }}
              />
            ) : null}
          </Box>
        </Box>
        <Box bg="white" borderRadius="10px" boxShadow="sm" p={6} mt="20px">
          <Box maxW="300px" maxH="300px" alignSelf="center" ms="20%">
            {!!c2 ? (
              <Pie
                data={c2}
                width={10}
                height={10}
                options={{ maintainAspectRatio: true }}
              />
            ) : null}
          </Box>
        </Box>
        <Box bg="white" borderRadius="10px" boxShadow="sm" p={6} mt="20px">
          <Tooltip label="Download All">
            <Link onClick={() => callGenerateReport()}>
              <Icon as={DownloadIcon} w={"30px"} color="green" />
            </Link>
          </Tooltip>
          <Box maxW="300px" maxH="300px" alignSelf="center" ms="20%">
            {!!c3 ? (
              <Pie
                data={c3}
                width={10}
                height={10}
                options={{ maintainAspectRatio: true }}
              />
            ) : null}
          </Box>
        </Box>
        <Box bg="white" borderRadius="10px" boxShadow="sm" p={6} mt="20px">
          <Box maxW="300px" maxH="300px" alignSelf="center" ms="20%">
            {!!c4 ? (
              <Bar data={c4} width={10} height={10} options={options} />
            ) : null}
          </Box>
        </Box>
      </SimpleGrid>
      <Box bg="white" borderRadius="10px" boxShadow="sm" p={6} mt="20px">
        <Box maxW="60%" maxH="300px" alignSelf="center" ms="20%">
          {!!c5 ? (
            <Bar data={c5} width={10} height={4} options={options} />
          ) : null}
        </Box>
      </Box>
    </Flex>
  );
}
