import {
    Avatar,
    Badge,
    Button,
    Flex,
    Td,
    Text,
    Tr,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuIcon,
    IconButton,
    useColorModeValue,
} from "@chakra-ui/react";
import { VscMenu } from 'react-icons/vsc'
import React from "react";

function TablesTableRow(props) {
    const data = props.data;
    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.400");

    return (
        <Tr>
            <Td>
                <Text
                    fontSize="sm"
                    color={textColor}
                    fontWeight="bold"
                >
                    {props.index}
                </Text>
            </Td>
            <Td>
                <Flex direction="column">
                    <Text
                        fontSize="sm"
                        color={textColor}
                    >
                        {!!data.purchaseOrder && data.purchaseOrder.userId}
                    </Text>
                </Flex>
            </Td>

            <Td minWidth={{ sm: "200px", md: "150px" }}>
                <Flex direction="column">
                    <Text fontSize="sm" color={textColor} fontWeight="normal">
                        {!!data.purchaseOrder && data.purchaseOrder.branchId}
                    </Text>
                </Flex>
            </Td>
            <Td minWidth={{ sm: "200px", md: "180px" }} pl="0px">
                <Flex direction="column">
                    <Text fontSize="sm" color={textColor} fontWeight="bold">
                        {data.licence}
                    </Text>
                    <Text fontSize="sm" color={data.isActive ? "green.400" : 'red.300'} fontWeight="normal">
                        {data.isActive ? 'active' : 'disabled'}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem" color="blue.500">
                    {data.usageCount == 0 ? 'No' : `${data.usageCount} times`}
                </Text>
            </Td>
            <Td>
                <Text fontSize="sm">
                    {data.certificateUrl === null ? 'NA' : <a href={data.certificateUrl}>Download</a>}
                </Text>
            </Td>
            <Td>
                <Text fontSize="sm" color={textColor} fontWeight="normal" pb=".5rem">
                    NA
                </Text>
            </Td>
            <Td>
                <Text fontSize="sm">
                    {!!data.purchaseOrder && data.purchaseOrder.licenceExpiryDate}
                </Text>
            </Td>
            <Td>
                <Menu>
                    <MenuButton as={IconButton} icon={<VscMenu />}>

                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => props.onclick({ id: data, action: "view" })}>View</MenuItem>
                        <MenuItem onClick={() => props.onclick({ id: data, action: "state_change" })}>Disable</MenuItem>
                    </MenuList>
                </Menu>
            </Td>
        </Tr>
    );
}

export default TablesTableRow;
