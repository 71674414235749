// Chakra imports
import {
    Box,
    Button, Wrap, WrapItem,
    Flex,
    Grid,
    Icon,
    Spacer, VStack,
    Text, ButtonGroup, SimpleGrid,
    Badge,
    LinkBox, LinkOverlay,


} from "@chakra-ui/react";
// Assets
import BackgroundCard1 from "assets/img/BackgroundCard1.png";
import BackgroundCard2 from "assets/img/solidcolor.png";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React, { useEffect, useState } from "react";
import { GiBookPile } from "react-icons/gi";
import { BsFilesAlt } from "react-icons/bs";
import { VscPieChart, VscSymbolEnum } from "react-icons/vsc";
import Api from '../../helpers/Fetch';
import Util from '../../helpers/utils';
import Auth from '../../helpers/auth';
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";

function Content(props) {

    let api = new Api();
    let util = new Util();
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [getId, setId] = useState(null);
    const [dropdownData, setDropdownData] = useState(null);
    const addsectionRef = React.useRef();
    const open = () => setOpen(!isOpen)
    const close = () => setOpen(false)

    const [isDialogOpen, setIsOpen] = React.useState(false)
    const onClose = () => setIsOpen(false)
    const cancelRef = React.useRef()

    useEffect(() => {
        getChapters();
        api.getDropdownData().then(res => {
            setDropdownData(res.data.data);
        }).catch(err => {
            util.showServerError(err);
        })
    }, []);
    const getChapters = () => {
        setData(null);

        api.getQuizChapters({ lang: Auth.getDefaultLang() }).then(res => {
            setData(res.data.data);
        }).catch(err => {
            util.showServerError(err);
        })
    }
    const openQuestions = (id) => {
        localStorage.setItem('moduleId', id);
        if (!!props && !!props.history) {
            props.history.push({
                pathname: '/admin/questions',
                state: {
                    moduleId: id
                }
            })
        }
    }

    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Grid w="100%">
                <Box w="100%">
                    <SimpleGrid
                        w="100%"
                        columns={{ sm: 1, md: 4 }}
                        columnGap={1}
                    >
                        {!!data && data.map(item => {

                            return (<VStack>
                                <LinkBox p='5' borderRadius='6px' >
                                    <LinkOverlay href='#' onClick={() => openQuestions(item.moduleId)}>
                                        <Card
                                            bgPosition="10%"
                                            p="16px"
                                            borderRadius='6px'
                                            h={{ sm: "220px", xl: "100%" }}
                                            gridArea={{ md: "1 / 1 / 2 / 3", xl: "1 / 1 / 2 / 3" }}
                                        >
                                            <CardBody h="100%" w="210px">
                                                <Flex
                                                    direction="column"
                                                    h="100%"
                                                    p="0px 10px 10px 10px"
                                                    w="100%"
                                                >
                                                    <Flex w="100%"
                                                    >
                                                        <Text fontSize="sm" fontWeight="bold">
                                                            {item.name}
                                                        </Text>
                                                    </Flex>
                                                    <Spacer />
                                                    <Flex direction="row" w='100%' mt='10' justify='space-between' textAlign='center'>
                                                        <Badge colorScheme='green'>
                                                            {item.questions} Questions
                                                        </Badge>
                                                        <Badge colorScheme='blue'>
                                                            {item.fixed} fixed
                                                        </Badge>

                                                    </Flex>
                                                </Flex>
                                            </CardBody>
                                        </Card>
                                    </LinkOverlay>
                                </LinkBox>
                                {/* <IconButton icon={<DeleteIcon />} value={item.sectionId} colorScheme='red' w="20px" pos="relative" bottom="129px" left="120px" mt='14px' onClick={(e) => { setId(e.target.value); setIsOpen(true) }
                                } /> */}
                            </VStack>);
                        })}


                    </SimpleGrid>
                </Box>
            </Grid >

        </Flex >
    );
}

export default Content;
