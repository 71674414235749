import React, { useState, useEffect, useRef } from "react";
// Chakra imports
import {
  useDisclosure,
  Flex,
  Badge,
  Text,
  Box,
  Button,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useColorModeValue,
  IconButton,
  Icon,
  Divider,
  Select,
  HStack,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import IconBox from "components/Icons/IconBox";
import { Separator } from "components/Separator/Separator";
import TablesTableRow from "components/Tables/ChaptersTableRow";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import Api from "../../helpers/Fetch";
import Auth from "../../helpers/auth";
import { BiArrowBack } from "react-icons/bi";
import { Editor } from "@tinymce/tinymce-react";
import Util from "../../helpers/utils";
import { BsEye } from "react-icons/bs";
import ReactHtmlParser from "react-html-parser";

function ContentView(props) {
  const textColor = useColorModeValue("gray.700", "white");
  const [data, setData] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownData, setDropdownData] = useState(null);
  const [selectedLang, setLanguage] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [isViewOpen, setViewOpen] = useState(false);
  const [modelData, setModelData] = useState(null);
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  let api = new Api();
  let util = new Util();

  let toast = useToast();
  const editorRef = useRef(null);

  useEffect(() => {
    let data = {};
    if (!!props && !!props.location && !!props.location.state) {
      data.topicId = props.location.state.topicId;
    }
    api
      .getContent(data)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
        setLanguage(res.data.data.lang);
      })
      .catch((err) => {
        util.showServerError(err);
      });
    api
      .getDropdownData()
      .then((res) => {
        console.log(res);
        setDropdownData(res.data.data);
      })
      .catch((err) => {
        util.showServerError(err);
      });
  }, []);

  const updateContent = () => {
    if (isEdit && editorRef.current) {
      setIsLoading(true);
      console.log(editorRef.current.getContent());
      let content = editorRef.current.getContent();
      if (!!data && !!data.contentId) {
        let update = {
          content: content,
          contentId: data.contentId,
          isVarient: false,
          lang: selectedLang,
        };
        api
          .updateContent(update)
          .then((res) => {
            console.log(res);
            setIsLoading(false);
            util.showSuccess("Status", res.data.message);
            setEdit(false);
            setData(res.data.data);
          })
          .catch((err) => {
            setIsLoading(false);
            util.showServerError(err);
          });
      } else {
        let data = {
          content: content,
          lang: selectedLang,
        };
        if (!!props && !!props.location && !!props.location.state) {
          data.topicId = props.location.state.topicId;
        }
        api
          .addContent(data)
          .then((res) => {
            console.log(res);
            setIsLoading(false);
            util.showSuccess("Status", res.data.message);
            setEdit(false);
            data.htmlText = content;
            setData(data);
            setOpen(false);
          })
          .catch((err) => {
            setIsLoading(false);
            util.showServerError(err);
          });
      }
    }

    setEdit(!isEdit);
  };
  const variantUpdate = () => {
    setIsLoading(true);
    console.log(editorRef.current.getContent());
    let content = editorRef.current.getContent();
    if (!!data && !!data.contentId) {
      let update = {
        content: content,
        contentId: data.contentId,
        isVarient: true,
        lang: modelData.lang.toLowerCase(),
      };
      api
        .updateContent(update)
        .then((res) => {
          console.log(res);
          setIsLoading(false);
          util.showSuccess("Status", res.data.message);
          setEdit(false);
          setData(res.data.data);
          setOpen(false);
        })
        .catch((err) => {
          setIsLoading(false);
          util.showServerError(err);
        });
    }
  };
  const EditModel = () => {
    return (
      <Modal
        isOpen={isOpen}
        size="5xl"
        autoFocus={true}
        scrollBehavior="outside"
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit ({modelData.lang.toUpperCase()})</ModalHeader>
          <ModalBody pb={6}>
            <Box borderRadius="10px" borderWidth="1px" m="10px" p="10px">
              <Flex direction="column">
                <Text mt={2} mb={3}>
                  Language: <b>{modelData.lang.toUpperCase()}</b>
                </Text>
                <Editor
                  apiKey="zj5ar504k7ncmrvz4ug4tkj0iu6ue9fvjtu2bhx9if2dhjzu"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue={modelData.content}
                  init={{
                    height: 300,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
              </Flex>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button colorScheme="red" ms={3} onClick={variantUpdate}>
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
  const htmlDecode = (input) => {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };

  const ViewModel = () => {
    return (
      <Modal
        isOpen={isViewOpen}
        size="5xl"
        autoFocus={true}
        scrollBehavior="outside"
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit ({modelData.lang.toUpperCase()})</ModalHeader>
          <ModalBody pb={6}>
            <Box borderRadius="10px" borderWidth="1px" m="10px" p="10px">
              <Flex direction="column">
                <Text mt={2} mb={3}>
                  Language: <b>{modelData.lang.toUpperCase()}</b>
                </Text>
                <Separator m={2} />
                <Text w="100%" h="300px" p={1} overflowY="scroll">
                  <div>
                    {ReactHtmlParser(
                      modelData.content.includes("player.vimeo.com")
                        ? modelData.content.replace(
                            /(width=")\d+("\W+height=")\d+/,
                            "$1850$2700"
                          )
                        : modelData.content
                    )}
                  </div>
                </Text>
              </Flex>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button onClick={() => setViewOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "scroll", md: "scroll" }}>
        <CardHeader p="1px 0px 22px 0px">
          <Flex direction="column" w="100%" justify="center">
            <Text pb={4}>
              <IconButton
                colorScheme="red"
                variant="solid"
                size="lg"
                me="3"
                onClick={() => props.history.goBack()}
                icon={<BiArrowBack />}
              />
              {props.location.state.chapter}
            </Text>

            <Divider />
            <Flex
              justify="space-between"
              align="center"
              minHeight="30px"
              w="100%"
              mt={3}
              ms={3}
            >
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                View or Update content
              </Text>
            </Flex>
            <Divider w="200px" ms={3} />
          </Flex>
        </CardHeader>
        <CardBody>
          <Tabs variant="line" colorScheme="orange" w="100%">
            <TabList>
              <Tab>Main</Tab>
              {/* <Tab>Language Varients <Badge ms={1} variant='solid' colorScheme='red'>
                {!!data && !!data.varients ? data.varients.length : '0'}
              </Badge>
              </Tab> */}
            </TabList>
            <TabPanels>
              <TabPanel>
                <Flex direction="row" whiteSpace={1} justify="space-between">
                  <Select
                    placeholder="Select Language"
                    size="sm"
                    maxW="200px"
                    value={selectedLang}
                    onChange={(e) => {
                      setLanguage(e.target.value);
                      setEdit(true);
                    }}
                    borderRadius="10px"
                  >
                    {!!dropdownData &&
                      dropdownData.languages.map((item) => {
                        return (
                          <option value={item.toLowerCase()}>{item}</option>
                        );
                      })}
                  </Select>
                  <Button
                    fontSize="xs"
                    variant="outline"
                    isLoading={isLoading}
                    onClick={updateContent}
                  >
                    {isEdit ? "Save" : data != null ? "Edit" : "Add Content"}
                  </Button>
                </Flex>
                {isEdit == false ? (
                  <>
                    <Box
                      borderWidth="1px"
                      borderRadius="lg"
                      overflow="scroll"
                      mt={2}
                      p={2}
                    >
                      <Text w="100%" h="100vh" p={1}>
                        {!!data ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data.htmlText.includes("player.vimeo.com")
                                ? data.htmlText.replace(
                                    /(width=")\d+("\W+height=")\d+/,
                                    "$1850$2700"
                                  )
                                : data.htmlText,
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </Text>
                    </Box>
                  </>
                ) : (
                  <Flex direction="column" w="100%" mt={3}>
                    <Editor
                      apiKey="zj5ar504k7ncmrvz4ug4tkj0iu6ue9fvjtu2bhx9if2dhjzu"
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue={!!data ? data.htmlText : ""}
                      init={{
                        height: 500,
                        menubar: true,
                        plugins: [
                          "media advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                  </Flex>
                )}
              </TabPanel>
              <TabPanel>
                <HStack direction={"column"} spacing="24px" overflowX="scroll">
                  {!!data &&
                    !!data.varients &&
                    data.varients.map((item) => {
                      return (
                        <>
                          <Card
                            p="16px"
                            display="flex"
                            align="center"
                            justify="center"
                            maxW="160px"
                            h="220px"
                            borderRadius="10px"
                            borderWidth="1px"
                          >
                            <CardBody>
                              <Flex direction="column" align="center">
                                <Avatar name={item.lang} src="" />
                                <Flex
                                  direction="column"
                                  mt="14px"
                                  ms="14px"
                                  me="14px"
                                  justify="center"
                                  textAlign="center"
                                  align="center"
                                  w="100%"
                                >
                                  <Text
                                    fontSize="md"
                                    color={textColor}
                                    fontWeight="bold"
                                  >
                                    {item.lang.toUpperCase()}
                                  </Text>
                                  <Text
                                    mb="24px"
                                    fontSize="xs"
                                    color="gray.400"
                                    noOfLines={2}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item.content,
                                      }}
                                    ></div>
                                  </Text>
                                </Flex>
                                <Flex
                                  direction="row"
                                  spacing="20px"
                                  pos="absolute"
                                  bottom="20px"
                                  align="center"
                                  alignItems="center"
                                >
                                  <IconButton
                                    me={3}
                                    aria-label="edit"
                                    icon={<EditIcon />}
                                    onClick={() => {
                                      setModelData(item);
                                      setOpen(true);
                                    }}
                                  />
                                  <IconButton
                                    aria-label="view"
                                    icon={<BsEye />}
                                    onClick={() => {
                                      setModelData(item);
                                      setViewOpen(true);
                                    }}
                                  />
                                </Flex>
                              </Flex>
                            </CardBody>
                          </Card>
                          <></>
                        </>
                      );
                    })}
                </HStack>
                {isOpen ? <EditModel /> : null}
                {isViewOpen ? <ViewModel /> : null}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default ContentView;
