import React, { useState, useEffect } from "react";
// Chakra imports
import {
    Flex,
    Text,
    Image, InputGroup, InputLeftElement, Input,
    IconButton, Box, Textarea, Grid, GridItem, Spacer, Select,
    Button, FormControl,
    useToast, Link,
    useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { AddIcon, ArrowBackIcon, MinusIcon, PhoneIcon } from '@chakra-ui/icons'
import { HiOutlineOfficeBuilding, HiOutlineMail, HiDotsHorizontal } from 'react-icons/hi'
import Api from "../../helpers/Fetch";
import Auth from '../../helpers/auth';
import { PersonIcon } from "components/Icons/Icons";
import { Formik, FieldArray } from 'formik';
import Utils from "../../helpers/utils";


function AddClient(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const [isLoading, setLoading] = useState(false);
    const [dropdownData, setDropDownData] = useState(null);
    const [countries, setCountries] = useState(null);
    const [states, setStates] = useState(null);
    const [cities, setCities] = useState(null);

    let api = new Api();
    let util = new Utils();
    const bgButton = useColorModeValue(
        "gray.500",
        "gray.800"
    );
    const inputRef = React.useRef();
    const [formValues, setFormValues] = useState([{ name: "", contact: "" }]);
    // let addFormFields = () => {
    //     setFormValues([...formValues, { name: "", email: "" }])
    // }

    // let removeFormFields = (i) => {
    //     let newFormValues = [...formValues];
    //     newFormValues.splice(i, 1);
    //     setFormValues(newFormValues)
    // }
    useEffect(() => {
        api.getDropdownData().then(res => {
            console.log(res);
            setDropDownData(res.data.data);
        }).catch(err => {
            console.log(err)
            if (!!err && !!err.response) {
                util.showError(err.response.data.message, '');
            }
        })
        api.getCountries().then(res => {
            console.log(res);
            setCountries(res.data);
        }).catch(err => {
            console.log(err)
            if (!!err && !!err.response) {
                util.showError(err.response.data.message, '');
            }
        })
    }, []);
    const doRegisteration = (data) => {
        setLoading(true)
        api.addClients(data).then((res) => {
            setLoading(false)
            util.showSuccess(res.data.message, '');
            props.history.push({
                pathname: "/admin/step-branch",
                state: res.data.data
            })
        }).catch((err) => {
            setLoading(false)
            if (!!err && !!err.response)
                util.showError(err.response.data.message, '');

        });
    }
    const getStates = (country) => {
        api.getStates(country).then(res => {
            console.log(res);
            setStates(res.data);
        }).catch(err => {
            console.log(err)
            if (!!err && !!err.response) {
                util.showError(err.response.data.message, '');
            }
        })
    }
    const getCities = (country, state) => {
        api.getCities(country, state).then(res => {
            console.log(res);
            setCities(res.data);
        }).catch(err => {
            console.log(err)
            if (!!err && !!err.response) {
                util.showError(err.response.data.message, '');
            }
        })
    }


    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                <CardHeader p="6px 0px 22px 0px">
                    <Flex justify="space-between" align="center" minHeight="60px" w="100%">
                        <Text fontSize="lg" color={textColor} fontWeight="bold">
                            Register Client
                        </Text>
                        <Button
                            bg={bgButton}
                            color="white"
                            fontSize="xs"
                            variant="solid"
                            leftIcon={<ArrowBackIcon />}
                            onClick={() => props.history.goBack()}
                        >
                            Back
                        </Button>
                    </Flex>

                </CardHeader>
                <CardBody>
                    <Flex direction="column" align="center" w="100%" mt="35px" zIndex={99}>
                        <Formik
                            initialValues={{
                                companyLogo: 'https://icons-for-free.com/iconfiles/png/512/capture+device+photo+play+icon-1320166363978203980.png',
                                companyName: '',
                                companyDisplayName: '',
                                email: '',
                                secondaryEmail: '',
                                country: '',
                                state: "",
                                city: "",
                                pincode: "",
                                address: "",
                                contacts: [
                                    {
                                        "name": "",
                                        "designation": "",
                                        "contact": ""
                                    }
                                ]

                            }}
                            onSubmit={(values, actions) => {
                                console.log(values);
                                doRegisteration(values);
                            }}

                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue
                                /* and other goodies */
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <FormControl>
                                        <Flex direction="column" align="center" w="100%" mt="35px" zIndex={99}>
                                            <Box borderWidth='1px' borderRadius='lg' w="100%">
                                                <Flex direction="column" align="center" w="100%" mt="-35px" zIndex={99}>
                                                    <Link>
                                                        <Image
                                                            borderRadius="full"
                                                            boxSize="70px"
                                                            name="logo"
                                                            src={(typeof values.companyLogo === 'string') ? values.companyLogo : URL.createObjectURL(values.companyLogo)}
                                                            alt="Logo"
                                                            onClick={() => inputRef.current.click()}
                                                        />
                                                    </Link>
                                                    <Input
                                                        ref={inputRef}
                                                        accept='image/*'
                                                        hidden={true} type="file" onChange={(event) => {
                                                            setFieldValue("companyLogo", event.currentTarget.files[0]);
                                                        }} />
                                                    <Text size={12} color="gray.600" p={2}>
                                                        Company Logo
                                                    </Text>
                                                </Flex>
                                                <Box p='6'>
                                                    <Text color="gray.300" mb={4}>Basic Details</Text>
                                                    <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                                                        <InputGroup>
                                                            <InputLeftElement
                                                                pointerEvents="none"
                                                                children={<HiOutlineOfficeBuilding color="gray.300" />}
                                                            />
                                                            <Input type="text" placeholder="Company Name" name="companyName" onChange={handleChange} />
                                                        </InputGroup>
                                                        <InputGroup>
                                                            <InputLeftElement
                                                                pointerEvents="none"
                                                                children={<HiOutlineOfficeBuilding color="gray.300" />}
                                                            />
                                                            <Input type="text" placeholder="Display Name" name="companyDisplayName" onChange={handleChange} />
                                                        </InputGroup>
                                                        <GridItem colSpan={2}>
                                                            <InputGroup>
                                                                <InputLeftElement
                                                                    pointerEvents="none"
                                                                    children={<HiOutlineMail color="gray.300" />}
                                                                />
                                                                <Input type="email" placeholder="Email" name="email" onChange={handleChange} />
                                                            </InputGroup>
                                                        </GridItem>
                                                        <GridItem colSpan={2}>
                                                            <InputGroup>
                                                                <InputLeftElement
                                                                    pointerEvents="none"
                                                                    children={<HiOutlineMail color="gray.300" />}
                                                                />
                                                                <Input type="email" placeholder="Secondary Email" name="secondaryEmail" onChange={handleChange} />
                                                            </InputGroup>
                                                        </GridItem>
                                                        <Text color="gray.300">Add Contacts</Text>
                                                        <FieldArray name="contacts">
                                                            {({ insert, remove, push }) => (
                                                                values.contacts.map((contact, index) => (

                                                                    <GridItem colSpan={2}>
                                                                        <Flex>
                                                                            <Select variant="filled" placeholder='Designation' name={`contacts.${index}.designation`} onChange={handleChange}>
                                                                                {!!dropdownData && !!dropdownData.designations && dropdownData.designations.map(item => {
                                                                                    return (
                                                                                        <option value={item}>{item}</option>
                                                                                    );
                                                                                })}
                                                                            </Select>
                                                                            <Spacer />
                                                                            <InputGroup me={10} ms={10}>
                                                                                <InputLeftElement
                                                                                    pointerEvents="none"
                                                                                    children={<PersonIcon color="gray.300" />}
                                                                                />
                                                                                <Input type="text" placeholder="Person Name" value={contact.name || ""} name={`contacts.${index}.name`} onChange={handleChange} />
                                                                            </InputGroup>
                                                                            <Spacer />
                                                                            <InputGroup>
                                                                                <InputLeftElement
                                                                                    pointerEvents="none"
                                                                                    children={<PhoneIcon color="gray.300" />}
                                                                                />
                                                                                <Input type="number" placeholder="Contact" value={contact.contact || ""} name={`contacts.${index}.contact`} onChange={handleChange} />
                                                                            </InputGroup>
                                                                            <Spacer />
                                                                            {index === 0 ?

                                                                                <IconButton aria-label='add contact' icon={<AddIcon />} ms={6} onClick={() => push({ name: '', designation: '', contact: '' })} />
                                                                                : <IconButton aria-label='remove contact' icon={<MinusIcon />} ms={6} onClick={() => remove(index)} />}

                                                                        </Flex>
                                                                    </GridItem>
                                                                )
                                                                ))}
                                                        </FieldArray>
                                                        <Text color="gray.300" mb={0}>Address details</Text>

                                                        <GridItem colSpan={2}>
                                                            <Flex>
                                                                <Select variant="filled" placeholder='Select Country' name="country" onChange={(e) => {
                                                                    setFieldValue('country', e.target.value);
                                                                    getStates(e.target.value);
                                                                }}>
                                                                    {!!countries && countries.map(item => {
                                                                        return (
                                                                            <option value={item.iso2}>{item.name}</option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                <Spacer />
                                                                <Select variant="filled" placeholder='Select State' me={12} ms={12} name="state" onChange={(e) => {
                                                                    setFieldValue('state', e.target.value);
                                                                    getCities(values.country, e.target.value);
                                                                }}>
                                                                    {!!states && states.map(item => {
                                                                        return (
                                                                            <option value={item.iso2}>{item.name}</option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                <Spacer />
                                                                <Select variant="filled" placeholder='Select City' name="city" onChange={handleChange}>
                                                                    {!!cities && cities.map(item => {
                                                                        return (
                                                                            <option value={item.iso2}>{item.name}</option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Flex>
                                                        </GridItem>

                                                        <Textarea

                                                            placeholder='Enter complete address'
                                                            size='sm'
                                                            name="address"
                                                            onChange={handleChange}
                                                        />
                                                        <GridItem colSpan={1}>
                                                            <Flex>
                                                                <InputGroup>
                                                                    <InputLeftElement
                                                                        pointerEvents="none"
                                                                        children={<HiDotsHorizontal color="gray.300" />}
                                                                    />
                                                                    <Input type="number" placeholder="Pin code" name="pincode" onChange={handleChange} />
                                                                </InputGroup>
                                                                <Spacer />
                                                            </Flex>
                                                        </GridItem>
                                                        <Button
                                                            fontSize="14px"
                                                            bg="gray.400"
                                                            w="100%"
                                                            h="45"
                                                            mb="20px"
                                                            color="white"
                                                            isLoading={false}
                                                            mt="20px"
                                                            _hover={{
                                                                bg: 'red.200',
                                                            }}
                                                            _active={{
                                                                bg: 'red.400',
                                                            }}
                                                            onClick={() => props.history.goBack()}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            fontSize="14px"
                                                            type="submit"
                                                            bg="red.300"
                                                            w="100%"
                                                            h="45"
                                                            mb="20px"
                                                            color="white"
                                                            isLoading={isLoading}
                                                            mt="20px"
                                                            _hover={{
                                                                bg: 'red.200',
                                                            }}
                                                            _active={{
                                                                bg: 'red.400',
                                                            }}                                                        >
                                                            Create
                                                        </Button>
                                                    </Grid>


                                                </Box>
                                            </Box>

                                        </Flex>
                                    </FormControl>
                                </form>
                            )}
                        </Formik>
                    </Flex>

                </CardBody>
            </Card>
        </Flex >
    );
}

export default AddClient;
