import React, { useState, useEffect } from "react";
// Chakra imports
import {
    Flex,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Text, Box,
    Th,
    Thead,
    Tr, RadioGroup, Radio, Stack, SimpleGrid,
    Button, Input,
    Grid, SlideFade, AlertIcon, AlertTitle, Alert, AlertDescription,
    GridItem, Avatar,
    useToast, Divider,
    useColorModeValue, Center, FormControl,
    Link, Select, Textarea,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Api from "../../helpers/Fetch";
import Util from "../../helpers/utils";
import { Separator } from "components/Separator/Separator";
import { Formik, FieldArray } from 'formik'
import { get } from "lodash";


function Clients(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const [data, setData] = useState(null);
    const [emailData, setEmailData] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setLoading] = useState(false);

    let api = new Api();
    let util = new Util();
    useEffect(() => {
        api.getProfile().then(res => {
            setData(res.data.data);
        }).catch(err => {
            setLoading(false)
            util.showServerError(err);
        })

    }, []);
    const submitEmail = (data) => {
        setLoading(true);
        api.updateEmailConfig(data).then(res => {
            setLoading(false)
            util.showSuccess(res.data.message);
        }).catch(err => {
            setLoading(false)
            util.showServerError(err);

        })
    }
    const changePassword = (data) => {
        setLoading(true);
        api.changePassword(data).then(res => {
            setLoading(false)
            util.showSuccess(res.data.message);
        }).catch(err => {
            setLoading(false)
            util.showServerError(err);

        })
    }
    const testMail = (data) => {
        setLoading(true);
        api.testMail(data).then(res => {
            setLoading(false)
            util.showSuccess(res.data.message);
        }).catch(err => {
            setLoading(false)
            util.showServerError(err);

        })
    }
    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                <CardHeader p="6px 0px 2px 0px">
                    <Flex justify="center" align="center" minHeight="60px" w="100%">
                        <Text fontSize="lg" color={textColor} fontWeight="bold">
                            My Account
                        </Text>

                    </Flex>

                </CardHeader>
                <Divider mb='4' />
                <CardBody>
                    <Box w='100%'>
                        <Tabs variant='soft-rounded'>
                            <TabList>
                                <Tab>Profile</Tab>
                                <Tab>Password</Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    {!!data ?
                                        <Flex direction='column'>
                                            <Avatar alignSelf='center' mb='10' size='lg' name='Company Logo' src={data.company_logo.url || ''} />
                                            <SimpleGrid columns={{ sm: 1, md: 4 }} spacing='4'>
                                                <Flex direction='column'>
                                                    <Text color='gray.400'>ID</Text>
                                                    <Text color='gray.600'>{data.userId}</Text>
                                                </Flex>
                                                <Flex direction='column'>
                                                    <Text color='gray.400'>Company Name</Text>
                                                    <Text color='gray.600'>{data.companyName}</Text>
                                                </Flex>
                                                <Flex direction='column'>
                                                    <Text color='gray.400'>Display Name</Text>
                                                    <Text color='gray.600'>{data.companyDisplayName}</Text>
                                                </Flex>
                                                <Flex direction='column'>
                                                    <Text color='gray.400'>Email</Text>
                                                    <Text color='gray.600'>{data.email}</Text>
                                                </Flex>
                                                <Flex direction='column'>
                                                    <Text color='gray.400'>Seconday Email</Text>
                                                    <Text color='gray.600'>{data.secondaryEmail}</Text>
                                                </Flex>
                                                <Flex direction='column'>
                                                    <Text color='gray.400'>Address</Text>
                                                    <Text color='gray.600'>{data.address}</Text>
                                                </Flex>
                                            </SimpleGrid>
                                        </Flex>
                                        : null}
                                </TabPanel>
                                <TabPanel>
                                    <Formik
                                        initialValues={{
                                            "oldPass": "",
                                            "newPass": "",
                                            "confirmPass": ""
                                        }}
                                        onSubmit={(values, actions) => {
                                            console.log(values);
                                            changePassword(values);
                                        }}

                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            handleReset,
                                            isSubmitting,
                                            setFieldValue
                                            /* and other goodies */
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <FormControl>
                                                    <Flex direction='column'>
                                                        <Box maxW='40%' boxShadow='sm' p={4}>
                                                            <Flex direction='column'>
                                                                <Input type='text' placeholder="Old password" mb='5' name="oldPass" onChange={handleChange} />
                                                                <Divider w='90px' alignSelf='center' mb='5' />
                                                                <Input type='password' placeholder="Enter new password" mb='2' name="newPass" onChange={handleChange} />
                                                                <Input type='password' placeholder="confirm password" mb='3' name="confirmPass" onChange={handleChange} />
                                                                <Button type='submit' isLoading={isLoading}>Change password</Button>
                                                            </Flex>
                                                        </Box>
                                                    </Flex>
                                                </FormControl>
                                            </form>)}
                                    </Formik>

                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </CardBody>
            </Card>
        </Flex>
    );
}

export default Clients;
