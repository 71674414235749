import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuIcon,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { VscMenu } from "react-icons/vsc";
import React from "react";

function TablesTableRow(props) {
  const data = props.data;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <Tr>
      <Td minWidth={{ sm: "200px" }} pl="0px">
        <Flex align="center" py=".3rem" minWidth="60%" flexWrap="nowrap">
          <Avatar
            src={!!data.company_logo ? data.company_logo.url : undefined}
            w="50px"
            borderRadius="12px"
            me="18px"
            ms="10px"
          />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="80%"
            >
              {data.userId}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {data.companyName}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="normal">
            {data.email}
          </Text>
          {/* <Text fontSize="sm" color="gray.400" fontWeight="normal">
                        {subdomain}
                    </Text> */}
        </Flex>
      </Td>
      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="normal">
            {data.state}/{data.city}
          </Text>
          {/* <Text fontSize="sm" color="gray.400" fontWeight="normal">
                        {subdomain}
                    </Text> */}
        </Flex>
      </Td>
      <Td>
        <Text
          fontSize="md"
          color={textColor}
          fontWeight="bold"
          pb=".5rem"
          color="blue.500"
        >
          {data.totalLicences}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {data.branches}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {new Intl.DateTimeFormat("en-IN").format(new Date(data.createdAt))}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {data.isActive ? "Yes" : "No"}
        </Text>
      </Td>
      <Td>
        <Menu>
          <MenuButton as={IconButton} icon={<VscMenu />}></MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => props.onclick({ id: data.userId, action: "view" })}
            >
              View
            </MenuItem>
            <MenuItem
              onClick={() =>
                props.onclick({ id: data.userId, action: "delete" })
              }
            >
              Delete
            </MenuItem>
            <MenuItem
              onClick={() => props.onclick({ id: data, action: "add_branch" })}
            >
              Add Branch
            </MenuItem>
            <MenuItem
              onClick={() =>
                props.onclick({
                  id: data.userId,
                  isActive: data.isActive,
                  action: "change_status",
                })
              }
            >
              {data.isActive ? "Disable" : "Enable"}
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default TablesTableRow;
