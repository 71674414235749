import {
  Flex,
  Td,
  Text,
  Tr,
  Progress,
  Button,
  IconButton,
  InputGroup,
  InputLeftElement,
  Input,
  useColorModeValue,
  Checkbox,
} from "@chakra-ui/react";
import { VscMenu } from "react-icons/vsc";
import React from "react";
import { EmailIcon } from "@chakra-ui/icons";
import { RiEyeLine } from "react-icons/ri";

function TablesTableRow(props) {
  const data = props.data;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <Tr>
      <Td>
        <Flex direction="column">
          <Text
            fontSize="sm"
            color="gray.600"
            fontWeight="normal"
            noOfLines={2}
          >
            {props.index}
          </Text>
        </Flex>
      </Td>

      <Td>
        <Flex direction="column">
          <Checkbox
            isChecked={data.selected}
            onChange={(e) =>
              props.onclick({ id: data, action: "check_change", event: e })
            }
          />
        </Flex>
      </Td>
      <Td>
        <Flex direction="column" w="150px">
          <Text fontSize="sm" color={textColor} fontWeight="normal">
            {data.licence}
          </Text>
        </Flex>
      </Td>
      <Td>
        {data.inviteStatus === "none" ? (
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<EmailIcon color="gray.300" h="14px" w="14px" mb="2" />}
            />
            <Input
              type="email"
              size="sm"
              placeholder="email address"
              name="name"
              value={data.email}
              onChange={(e) =>
                props.onclick({ id: data, action: "email_input", event: e })
              }
            />
          </InputGroup>
        ) : (
          <Flex direction="column">
            <Text>{data.sentTo}</Text>
            {data.inviteStatus === "invited" && data.certificateUrl === null ? (
              <Button
                variant="ghost"
                mt="2"
                colorScheme="red"
                onClick={() =>
                  props.onclick({ id: data.licence, action: "reminder" })
                }
                size="xs"
              >
                Send Reminder
              </Button>
            ) : null}
            <Text
              mt={2}
              color={
                data.pendingStatus === "sent!"
                  ? "green"
                  : data.pendingStatus === "failed"
                  ? "red"
                  : "gray.600"
              }
            >
              {data.pendingStatus || ""}
            </Text>
          </Flex>
        )}
      </Td>
      <Td>
        {data.inviteStatus === "none" ? (
          <InputGroup>
            <Input
              type="text"
              size="sm"
              placeholder="Name"
              name="name"
              value={data.name}
              onChange={(e) =>
                props.onclick({ id: data, action: "name_input", event: e })
              }
            />
          </InputGroup>
        ) : (
          <Flex direction="column">
            <Text>{data.empName}</Text>
          </Flex>
        )}
      </Td>
      <Td>
        {data.inviteStatus === "none" ? (
          <InputGroup>
            <Input
              type="text"
              size="sm"
              placeholder="Code"
              name="emp_code"
              value={data.empCode}
              onChange={(e) =>
                props.onclick({ id: data, action: "emp_code_input", event: e })
              }
            />
          </InputGroup>
        ) : (
          <Flex direction="column">
            <Text>{data.empCode}</Text>
          </Flex>
        )}
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {data.usageCount}
        </Text>
      </Td>
      <Td>
        <Progress
          colorScheme="red"
          size="sm"
          value={data.contentReadPercentage}
        />
      </Td>
      <Td>
        <Flex direction="column">
          <Text color="green.300">Pass: {data.quizAttempts.pass}</Text>
          <Text color="red.300">Fail: {data.quizAttempts.fails}</Text>
        </Flex>
      </Td>
      <Td>
        <Text fontSize="sm" color={textColor} fontWeight="normal" pb=".5rem">
          {data.certificateUrl == null ? (
            "NA"
          ) : (
            <a href={data.certificateUrl}>Download</a>
          )}
        </Text>
      </Td>

      <Td>
        <IconButton
          icon={<RiEyeLine />}
          variant="ghost"
          colorScheme="red"
          onClick={() => props.onclick({ action: "view", data: data })}
        />
      </Td>
    </Tr>
  );
}

export default TablesTableRow;
