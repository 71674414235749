import React, { useState, useEffect } from "react";
// Chakra imports
import {
    Flex,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Text, Box,
    Th,
    Thead,
    Tr, RadioGroup, Radio, Stack, SimpleGrid,
    Button, Input,
    Grid, SlideFade, AlertIcon, AlertTitle, Alert, AlertDescription,
    GridItem, Avatar,
    useToast, Divider, Image,
    useColorModeValue, Center, FormControl,
    Link, Select, Textarea,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Api from "../../helpers/Fetch";
import Util from "../../helpers/utils";
import { Separator } from "components/Separator/Separator";
import { Formik, FieldArray } from 'formik'
import { get } from "lodash";


function Clients(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const [data, setData] = useState(null);
    const [emailData, setEmailData] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const inputRef = React.useRef();
    let api = new Api();
    let util = new Util();
    useEffect(() => {
        api.getProfile().then(res => {
            setData(res.data.data);
        }).catch(err => {
            setLoading(false)
            util.showServerError(err);
        })
        api.getEmailConfig().then(res => {
            setEmailData(res.data.data);
        }).catch(err => {
            setLoading(false)
            // util.showServerError(err);
        })
    }, []);
    const submitEmail = (data) => {
        setLoading(true);
        api.updateEmailConfig(data).then(res => {
            setLoading(false)
            util.showSuccess(res.data.message);
        }).catch(err => {
            setLoading(false)
            util.showServerError(err);

        })
    }
    const changePassword = (data) => {
        setLoading(true);
        api.changePassword(data).then(res => {
            setLoading(false)
            util.showSuccess(res.data.message);
        }).catch(err => {
            setLoading(false)
            util.showServerError(err);

        })
    }
    const testMail = (data) => {
        setLoading(true);
        api.testMail(data).then(res => {
            setLoading(false)
            util.showSuccess(res.data.message);
        }).catch(err => {
            setLoading(false)
            util.showServerError(err);

        })
    }
    const updateLogo = (file) => {
        if (!!data) {
            api.updateClientLogo(file, data.userId).then((res) => {
                console.log(res);
                util.showSuccess(res.data.message);
            }).catch((err) => {
                util.showServerError(err);

            });
        }
    }
    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                <CardHeader p="6px 0px 2px 0px">
                    <Flex justify="center" align="center" minHeight="60px" w="100%">
                        <Text fontSize="lg" color={textColor} fontWeight="bold">
                            My Account
                        </Text>

                    </Flex>

                </CardHeader>
                <Divider mb='4' />
                <CardBody>
                    <Box w='100%'>
                        <Tabs variant='soft-rounded'>
                            <TabList>
                                <Tab>Profile</Tab>
                                <Tab>Password</Tab>
                                <Tab>Email</Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    {!!data ?
                                        <Flex direction='column'>
                                            <Box alignSelf='center' textAlign='center'>
                                                <Link>
                                                    <Image
                                                        borderRadius="full"
                                                        boxSize="70px"
                                                        name="logo"
                                                        src={!!data.company_logo.url ? data.company_logo.url : "https://icons-for-free.com/iconfiles/png/512/capture+device+photo+play+icon-1320166363978203980.png"}
                                                        alt="Logo"
                                                        onClick={() => inputRef.current.click()}
                                                    />
                                                </Link>
                                                <Input
                                                    ref={inputRef}
                                                    accept='image/*'
                                                    hidden={true} type="file" onChange={(event) => {
                                                        updateLogo(event.currentTarget.files[0])
                                                    }} />
                                                <Text color='gray.400' ml='-4' mb='4'>Click to update</Text>
                                            </Box>
                                            {/* <Avatar alignSelf='center' mb='10' size='lg' name='Company Logo' src={data.company_logo.url || ''} /> */}
                                            <SimpleGrid columns={{ sm: 1, md: 4 }} spacing='4'>
                                                <Flex direction='column'>
                                                    <Text color='gray.400'>ID</Text>
                                                    <Text color='gray.600'>{data.userId}</Text>
                                                </Flex>
                                                <Flex direction='column'>
                                                    <Text color='gray.400'>Company Name</Text>
                                                    <Text color='gray.600'>{data.companyName}</Text>
                                                </Flex>
                                                <Flex direction='column'>
                                                    <Text color='gray.400'>Display Name</Text>
                                                    <Text color='gray.600'>{data.companyDisplayName}</Text>
                                                </Flex>
                                                <Flex direction='column'>
                                                    <Text color='gray.400'>Email</Text>
                                                    <Text color='gray.600'>{data.email}</Text>
                                                </Flex>
                                                <Flex direction='column'>
                                                    <Text color='gray.400'>Seconday Email</Text>
                                                    <Text color='gray.600'>{data.secondaryEmail}</Text>
                                                </Flex>
                                                <Flex direction='column'>
                                                    <Text color='gray.400'>Address</Text>
                                                    <Text color='gray.600'>{data.address}</Text>
                                                </Flex>
                                            </SimpleGrid>
                                        </Flex>
                                        : null}
                                </TabPanel>
                                <TabPanel>
                                    <Formik
                                        initialValues={{
                                            "oldPass": "",
                                            "newPass": "",
                                            "confirmPass": ""
                                        }}
                                        onSubmit={(values, actions) => {
                                            console.log(values);
                                            changePassword(values);
                                        }}

                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            handleReset,
                                            isSubmitting,
                                            setFieldValue
                                            /* and other goodies */
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <FormControl>
                                                    <Flex direction='column'>
                                                        <Box maxW='40%' boxShadow='sm' p={4}>
                                                            <Flex direction='column'>
                                                                <Input type='text' placeholder="Old password" mb='5' name="oldPass" onChange={handleChange} />
                                                                <Divider w='90px' alignSelf='center' mb='5' />
                                                                <Input type='password' placeholder="Enter new password" mb='2' name="newPass" onChange={handleChange} />
                                                                <Input type='password' placeholder="confirm password" mb='3' name="confirmPass" onChange={handleChange} />
                                                                <Button type='submit' isLoading={isLoading}>Change password</Button>
                                                            </Flex>
                                                        </Box>
                                                    </Flex>
                                                </FormControl>
                                            </form>)}
                                    </Formik>

                                </TabPanel>
                                <TabPanel>
                                    <Formik
                                        initialValues={{
                                            "host": "",
                                            "port": 0,
                                            "protocol": "",
                                            "username": "",
                                            "password": ""
                                        }}
                                        onSubmit={(values, actions) => {
                                            console.log(values);
                                            submitEmail(values);
                                        }}

                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            handleReset,
                                            isSubmitting,
                                            setFieldValue
                                            /* and other goodies */
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <FormControl>
                                                    <Flex direction='column'>
                                                        <Box maxW='40%' boxShadow='sm' p={4}>
                                                            <Flex direction='column'>
                                                                <Text m='2' color='gray.600'>Setup your SMTP details</Text>
                                                                <Divider mb='3' />
                                                                <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={2}>
                                                                    <Input type='text' placeholder="Host" mb='5' name="host" value={values.host === '' ? !!emailData ? emailData.host : values.host : values.host} onChange={handleChange} required />
                                                                    <Input type='number' placeholder="Port" mb='5' name="port" onChange={handleChange} value={values.port === '' ? !!emailData ? emailData.port : values.port : values.port} required />
                                                                    <RadioGroup name="protocol"
                                                                        value={values.protocol === '' ? !!emailData ? emailData.protocol : values.protocol : values.protocol}
                                                                        onChange={(e) => {
                                                                            console.log(e);
                                                                            setFieldValue("protocol", e)
                                                                        }} required>
                                                                        Security Protocol
                                                                        <Stack direction='row'>
                                                                            <Radio value='ssl'>SSL</Radio>
                                                                            <Radio value='tls'>TLS</Radio>
                                                                        </Stack>
                                                                    </RadioGroup>
                                                                </SimpleGrid>
                                                                <Input type='text' placeholder="Username" mb='5' mt='5' name="username" onChange={handleChange} required value={values.username === '' ? !!emailData ? emailData.username : values.username : values.username} />
                                                                <Input type='password' placeholder="Password" mb='5' name="password" onChange={handleChange} required />
                                                                <Flex w='100%' justify='space-between'>
                                                                    <Button isLoading={isLoading} variant='solid' colorScheme='gray' onClick={() => testMail(values)}>Test</Button>
                                                                    <Button type='submit' isLoading={isLoading} colorScheme='red'>Save</Button>
                                                                </Flex>
                                                            </Flex>
                                                        </Box>
                                                    </Flex>
                                                </FormControl>
                                            </form>)}
                                    </Formik>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </CardBody>
            </Card>
        </Flex>
    );
}

export default Clients;
