import React, { useState, useEffect } from "react";
// Chakra imports
import {
  Flex, Tabs, TabList, TabPanels, Tab, TabPanel,
  Text, Box,
  Button,
  useColorModeValue, Stack, Checkbox,
  Link, Select, FormControl
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesTableRow from "components/Tables/ChaptersTableRow";
import { AddIcon, ArrowBackIcon, MinusIcon, PhoneIcon } from '@chakra-ui/icons'
import Api from "../../helpers/Fetch";
import Auth from '../../helpers/auth';
import Util from '../../helpers/utils';
import { MdTitle } from 'react-icons/md'
import { Formik, FieldArray } from 'formik'


import { Separator } from "components/Separator/Separator";
import { Editor } from '@tinymce/tinymce-react';
import {
  AsyncCreatableSelect,
  AsyncSelect,
  CreatableSelect,
  Select as Multiselect,
} from "chakra-react-select";


function AddMCC(props) {
  const textColor = useColorModeValue("gray.700", "white");
  const [data, setData] = useState(null);
  const [modules, setModules] = useState(null);
  const [topics, setTopics] = useState(null);
  const [isNewChapter, setIsNewChapter] = useState(undefined);
  const [isNewTopic, setIsNewTopic] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  let api = new Api();
  let util = new Util();
  const addbtnRef = React.useRef();
  const resetbtnRef = React.useRef();
  const editorRef = React.useRef(null);
  useEffect(() => {
    api.getDropdownData().then(res => {
      setData(res.data.data);
    }).catch(err => {
      util.showServerError(err);
    });
    getModules();
  }, [])
  const getModules = () => {
    let data = {
      lang: Auth.getDefaultLang().toLowerCase()
    }
    api.getModules(data).then(res => {
      let kv = [];
      res.data.data.map(item => {
        kv.push({
          label: item.title,
          value: item.moduleId
        })
      })
      setModules(kv);
    }).catch(err => {
      util.showServerError(err);
    });
  }
  const getTopics = (id) => {
    let data = {
      lang: Auth.getDefaultLang(),
      moduleId: id
    }
    api.getTopics(data).then(res => {
      let kv = [];
      res.data.data.map(item => {
        kv.push({
          label: item.title,
          value: item.topicId
        })
      })
      setTopics(kv);
    }).catch(err => {
      util.showServerError(err);
    });
  }
  const addData = (values) => {
    if (values.moduleId === '' || values.topicId === '' || editorRef.current.getContent() === '') {
      util.showError('Please fill all the feilds')
      return
    }
    setLoading(true)
    if (isNewChapter) { // if chapter not exist and creating new
      let module = {
        name: values.moduleId,
        lang: values.lang,
        isIccContent: values.isIccContent,
        isContainVideos: values.isContainVideos
      }
      api.addModule(module).then((res) => {
        console.log(res);
        let moduleId = res.data.data.moduleId;
        let topic = {
          moduleId,
          name: values.topicId,
          lang: values.lang
        }
        api.addChapters(topic).then((topic) => {
          console.log(topic);
          let topicId = topic.data.data.topicId;
          let content = {
            topicId,
            content: editorRef.current.getContent(),
            lang: values.lang
          }
          api.addContent(content).then((rescontent) => {
            console.log(rescontent);
            util.showSuccess(rescontent.data.message, '')
            setLoading(false)
            setTimeout(() => { window.location.reload(false) }, 1500);
          }).catch((err) => {
            util.showServerError(err);
            setLoading(false)
          });
        }).catch((err) => {
          util.showServerError(err);
          setLoading(false)
        });

      }).catch((err) => {
        util.showServerError(err);
        setLoading(false)
      });
    }
    else {
      let moduleId = values.moduleId;
      if (isNewTopic) {
        let topic = {
          moduleId,
          name: values.topicId,
          lang: values.lang
        }
        api.addChapters(topic).then((topic) => {
          console.log(topic);
          let topicId = topic.data.data.topicId;
          let content = {
            topicId,
            content: editorRef.current.getContent(),
            lang: values.lang
          }
          api.addContent(content).then((rescontent) => {
            console.log(rescontent);
            util.showSuccess(rescontent.data.message, '')
            setLoading(false)
            setTimeout(() => { window.location.reload(false) }, 1500);
          }).catch((err) => {
            util.showServerError(err);
            setLoading(false)
          });
        }).catch((err) => {
          util.showServerError(err);
          setLoading(false)
        });
      }
      else {
        let topicId = values.topicId;
        let content = {
          topicId,
          content: editorRef.current.getContent(),
          lang: values.lang
        }
        api.addContent(content).then((rescontent) => {
          console.log(rescontent);
          util.showSuccess(rescontent.data.message, '')
          setLoading(false)
          setTimeout(() => { window.location.reload(false) }, 1500);
        }).catch((err) => {
          util.showServerError(err);
          setLoading(false)
        });
      }
    }
  }

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "scroll", md: 'scroll' }}>
        <CardHeader p="1px 0px 22px 0px">
          <Flex justify="space-between" align="center" minHeight="30px" w="100%">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Add
            </Text>
            <Button
              fontSize="xs"
              variant="solid"
              leftIcon={<ArrowBackIcon />}
              onClick={() => props.history.goBack()}

            >
              Back
            </Button>
          </Flex>

        </CardHeader>
        <CardBody>
          <Tabs w="100%" variant='solid-rounded' size="sm">
            <TabList ms={3}>
              <Tab>Content</Tab>
            </TabList>

            <TabPanels>

              <TabPanel>
                <Box w="100%" borderRadius="10px" borderWidth="1px" p="30px">
                  <Formik
                    initialValues={{
                      "moduleId": '',
                      "topicId": "",
                      "name": "",
                      isIccContent: false,
                      isContainVideos: false,
                      "lang": Auth.getDefaultLang().toLowerCase(),
                    }}
                    onSubmit={(values, actions) => {
                      console.log(values);

                      addData(values);

                    }}

                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      handleReset,
                      isSubmitting,
                      setFieldValue
                      /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <FormControl>
                          <Flex direction='column'>

                            <Text mt="14px" color='gray.400'>Language</Text>
                            <Select placeholder="Select language" name="lang" value={values.lang} disabled={values.lang !== ''} onChange={(e) => {
                              setFieldValue('lang', e.target.value);
                              getModules();
                            }}>
                              {
                                !!data && !!data.languages && data.languages.map(item => {
                                  return (
                                    <option value={item.toLowerCase()}>{item}</option>
                                  )
                                })
                              }
                            </Select>
                            <Text mt="14px" color='gray.400'>Select/Add Chapter</Text>

                            <Box w="100%">
                              <CreatableSelect
                                size="md"
                                colorScheme="red"
                                closeMenuOnSelect={true}
                                placeholder='Select Module'
                                name="moduleId"
                                onChange={(e) => {
                                  console.log(e);
                                  setIsNewChapter(e.__isNew__);
                                  setFieldValue('moduleId', e.value);
                                  getTopics(e.value);
                                }}
                                selectedOptionStyle="color"
                                options={!!modules ? modules : []}
                              >

                              </CreatableSelect>
                            </Box>
                            <Stack spacing={5} direction='row' mt={6}>
                              <Checkbox colorScheme='orange' name="isIccContent" onChange={handleChange}>Is Contain ICC Content</Checkbox>
                              <Checkbox colorScheme='orange' name="isContainVideos" onChange={handleChange}>
                                Contain Videos
                              </Checkbox>
                            </Stack>
                            <Text mt="14px" color='gray.400'>Select/Add Topic</Text>
                            <Box w="100%" mb='10'
                            >
                              <CreatableSelect
                                size="md"
                                colorScheme="red"
                                closeMenuOnSelect={true}
                                placeholder='Select/Enter Topic'
                                name="topicId"
                                onChange={(e) => {
                                  setIsNewTopic(e.__isNew__);
                                  setFieldValue('topicId', e.value);
                                  if (!isNewChapter) {
                                    getTopics(e.value);
                                  }
                                }}
                                selectedOptionStyle="color"
                                options={!!topics ? topics : []}
                                width={300}
                              >

                              </CreatableSelect>
                            </Box>
                            <Text mt="14px" color='gray.400'>Write Content:</Text>
                            {values.moduleId !== '' && values.topicId != '' ?
                              <Editor
                                apiKey="zj5ar504k7ncmrvz4ug4tkj0iu6ue9fvjtu2bhx9if2dhjzu"
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={!!data ? data.htmlText : ''}
                                init={{
                                  height: 300,
                                  menubar: true,
                                  plugins: [
                                    'advlist media autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                  ],
                                  toolbar: 'undo redo | formatselect | ' +
                                    'bold italic backcolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help',
                                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                              />
                              : null}


                            <Button
                              mt="24px"
                              type="submit"
                              isLoading={isLoading}
                              colorScheme='red'
                            >
                              Save
                            </Button>
                            <Button
                              ref={resetbtnRef}
                              mt="24px"
                              hidden={true}
                              colorScheme='red'
                              onClick={handleReset}
                            >
                              Reset
                            </Button>

                          </Flex>
                        </FormControl>
                      </form>
                    )}
                  </Formik>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default AddMCC;

// //{/* <TabPanel>
// <Box w="100%" borderRadius="10px" borderWidth="1px" p="30px">
//   <Formik
//     initialValues={{
//       "name": "",
//       "lang": "",
//       "isIccContent": false,
//       "isContainVideos": false
//     }}
//     onSubmit={(values, actions) => {
//       console.log(values);
//       addModule(values);
//     }}

//   >
//     {({
//       values,
//       errors,
//       touched,
//       handleChange,
//       handleBlur,
//       handleSubmit,
//       handleReset,
//       isSubmitting,
//       setFieldValue
/* and other goodies */
  //   }) => (
  //     <form onSubmit={handleSubmit}>
  //       <FormControl>
  //         <Flex direction='column'>
  //           <Text mt="14px" color='gray.400'>Select Language</Text>
  //           <Select placeholder="Select language" name="lang" onChange={handleChange}>
  //             {
  //               !!data && !!data.languages && data.languages.map(item => {
  //                 return (
  //                   <option value={item.toLowerCase()}>{item}</option>
  //                 )
  //               })
  //             }
  //           </Select>
  //           <Text mt="14px" color='gray.400'>Title</Text>
  //           <InputGroup>
  //             <InputLeftElement
  //               pointerEvents="none"
  //               children={<MdTitle color="gray.300" />}
  //             />
  //             <Input type="text" placeholder="title" name="name" onChange={handleChange} />
  //           </InputGroup>
  //           <Stack spacing={5} direction='row' mt={6}>
  //             <Checkbox colorScheme='orange' name="isIccContent" onChange={handleChange}>Is Contain ICC Content</Checkbox>
  //             <Checkbox colorScheme='orange' name="isContainVideos" onChange={handleChange}>
  //               Contain Videos
  //             </Checkbox>
  //           </Stack>

  //           <Button
  //             mt="24px"
  //             type="submit"
  //             isLoading={isLoading}
  //             colorScheme='red'
  //           >
  //             Save
  //           </Button>
  //           <Button
  //             ref={resetbtnRef}
  //             mt="24px"
  //             hidden={true}
  //             colorScheme='red'
  //             onClick={handleReset}
  //           >
  //             Reset
  //           </Button>

  //         </Flex>
  //       </FormControl>
  //     </form>
  //   )}
  // </Formik>
// </Box>
// </TabPanel>
// <TabPanel>
// <Box w="100%" borderRadius="10px" borderWidth="1px" p="30px">
//   <Formik
//     initialValues={{
//       "name": "",
//       "lang": "",
//       "moduleId": ""
//     }}
//     onSubmit={(values, actions) => {
//       console.log(values);
//       addChapter(values);
//     }}

//   >
//     {({
//       values,
//       errors,
//       touched,
//       handleChange,
//       handleBlur,
//       handleSubmit,
//       handleReset,
//       isSubmitting,
//       setFieldValue
//       /* and other goodies */
//     }) => (
//       <form onSubmit={handleSubmit}>
//         <FormControl>
//           <Flex direction='column'>

//             <Text mt="14px" color='gray.400'>Select Base Language</Text>
//             <Select placeholder="Select language" name="lang" onChange={(e) => {
//               setFieldValue('lang', e.target.value);
//               Auth.setDefaultLanguage(e.target.value);
//               getModules();
//             }}>
//               {
//                 !!data && !!data.languages && data.languages.map(item => {
//                   return (
//                     <option value={item.toLowerCase()}>{item}</option>
//                   )
//                 })
//               }
//             </Select>
//             <Text mt="14px" color='gray.400'>Select Module</Text>
//             <Select placeholder="Select module" name="moduleId" onChange={handleChange}>
//               {
//                 !!modules && modules.map(item => {
//                   return (
//                     <option value={item.moduleId}>{item.title}</option>
//                   )
//                 })
//               }
//             </Select>
//             <Text mt="14px" color='gray.400'>Title</Text>
//             <InputGroup>
//               <InputLeftElement
//                 pointerEvents="none"
//                 children={<MdTitle color="gray.300" />}
//               />
//               <Input type="text" placeholder="title" name="name" onChange={handleChange} />
//             </InputGroup>
//             <Button
//               mt="24px"
//               type="submit"
//               isLoading={isLoading}
//               colorScheme='red'
//             >
//               Save
//             </Button>
//             <Button
//               ref={resetbtnRef}
//               mt="24px"
//               hidden={true}
//               colorScheme='red'
//               onClick={handleReset}
//             >
//               Reset
//             </Button>

//           </Flex>
//         </FormControl>
//       </form>
//     )}
//   </Formik>
// </Box>
// </TabPanel> */}
