import * as axios from "axios";

export default class Api {
  constructor() {
    this.api_token = null;
    this.client = null;
    // this.api_url = "http://localhost:3000/dev/api/v1";
    this.api_url = "https://api.centre4posh.com/api/v1";
  }

  init = (isMultipart, isBlob = false) => {
    this.api_token = localStorage.getItem("ACCESS_TOKEN");

    let headers = {
      Accept: "application/json",
      "Content-type": isMultipart ? "multipart/form-data" : "application/json",
    };

    if (this.api_token) {
      headers.Authorization = `Bearer ${this.api_token}`;
    }

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers: headers,
    });

    return this.client;
  };
  csc = () => {
    let headers = {
      Accept: "application/json",
      "X-CSCAPI-KEY":
        "bHlOekdxd2lGbkdXWlJEUFl0VldDa1dkSVdqb3N3dm5zOXFaenV1bw==",
    };

    this.client = axios.create({
      baseURL: "https://api.countrystatecity.in/v1",
      timeout: 31000,
      headers: headers,
      responseType: "json",
    });

    return this.client;
  };
  checkAuthToken = (props) => {
    return this.init()
      .get("/auth/checkToken")
      .then((res) => {
        console.log("check auth", res);
      })
      .catch((err) => {
        console.log("check auth", err);
        localStorage.clear();
        if (!!props && !!props.history) {
          props.history.push("/");
        } else {
          window.location.reload(false);
        }
      });
  };

  login = (params) => {
    return this.init().post("/auth/login", params);
  };

  getClients = (data) => {
    return this.init().get("/get/clients", data);
  };

  getClient = (data) => {
    return this.init().get("/get/client", { params: data });
  };

  updateClient = (data) => {
    return this.init().post("/update/client", data);
  };

  addClients = (data) => {
    let formdata = new FormData();
    Object.keys(data).map((e) => {
      if (e !== "contacts" && e !== "companyLogo") {
        formdata.append(e, data[e]);
      }
    });
    formdata.append("contacts", JSON.stringify(data.contacts));
    formdata.append("companyLogo", data.companyLogo);
    return this.init(true).post("/auth/register", formdata);
  };
  updateClientLogo = (file, userId, companyName) => {
    let formdata = new FormData();
    formdata.append("companyLogo", file);
    formdata.append("userId", userId);
    formdata.append("companyName", companyName);
    return this.init(true).post("/update/clientLogo", formdata);
  };
  updateClientBand = (file, userId, companyName) => {
    let formdata = new FormData();
    formdata.append("companyBandLogo", file);
    formdata.append("userId", userId);
    formdata.append("companyName", companyName);
    return this.init(true).post("/update/clientOtherLogo", formdata);
  };

  deleteClient = (data) => {
    return this.init().get("/delete/client", { params: data });
  };
  disableClient = (data) => {
    return this.init().get("/set/clientStatus", { params: data });
  };

  addBranch = (data) => {
    return this.init().post("/add/branch", data);
  };
  getContentModules = (data) => {
    return this.init().get("/get/contentPacks", { params: data });
  };

  addContentModule = (data) => {
    return this.init().post("/add/contentPack", data);
  };
  deleteContentModule = (data) => {
    return this.init().get("/delete/contentPack", { params: data });
  };

  addModule = (data) => {
    return this.init().post("/add/module", data);
  };
  addChapters = (data) => {
    return this.init().post("/add/topic", data);
  };
  addContent = (data) => {
    return this.init().post("/add/content", data);
  };

  createPO = (data) => {
    return this.init().post("/create/purchase-order", data);
  };
  updatePO = (data) => {
    return this.init().post("/update/purchase-order", data);
  };
  downloadPO = (data) => {
    return this.init().get("/download/purchase-order", { params: data });
  };

  getOrders = (data) => {
    return this.init().get("/get/purchase-orders", { params: data });
  };

  getOrder = (data) => {
    return this.init().get("/get/purchase-order", { params: data });
  };

  releaseOrder = (data) => {
    return this.init().post("/release/purchase-order", data);
  };

  deleteOrder = (data) => {
    return this.init().delete("/delete/purchase-order", { params: data });
  };

  getDropdownData = () => {
    return this.init().get("/get/dropdowns");
  };

  getModules = (data) => {
    return this.init().get("/get/modules", { params: data });
  };

  updateModule = (data) => {
    return this.init().post("/update/module", data);
  };
  deleteModule = (data) => {
    return this.init().get("/delete/module", { params: data });
  };
  disableModule = (data) => {
    return this.init().get("/change/moduleVisibility", { params: data });
  };

  getTopics = (data) => {
    return this.init().get("/get/topics", { params: data });
  };

  updateTopic = (data) => {
    return this.init().post("/update/topic", data);
  };
  deleteTopic = (data) => {
    return this.init().get("/delete/topic", { params: data });
  };

  getContent = (data) => {
    return this.init().get("/get/content", { params: data });
  };

  updateContent = (data) => {
    return this.init().post("/update/content", data);
  };

  getSections = () => {
    return this.init().get("/get/sections");
  };

  addSection = (data) => {
    return this.init().post("/add/section", data);
  };

  deleteSection = (data) => {
    return this.init().get("/delete/section", { params: data });
  };

  getQuizLangPacks = () => {
    return this.init().get("get/quizPacks");
  };
  getQuizChapters = (body) => {
    return this.init().get("get/quizChapters", { params: body });
  };

  getQuizQuestions = (data) => {
    return this.init().get("/get/questions", { params: data });
  };

  addQuestion = (data) => {
    return this.init().post("/add/quiz", data);
  };

  getConfigModules = (data) => {
    return this.init().get("/get/config", { params: data });
  };
  getConfigDetails = (data) => {
    return this.init().get("/get/configDetails", { params: data });
  };

  addConfigModule = (data) => {
    return this.init().post("/add/config", data);
  };

  getLicences = (data) => {
    return this.init().get("/get/licences", { params: data });
  };

  getDefaults = (data) => {
    return this.init().get("/get/defaults");
  };

  updateDefaults = (data) => {
    return this.init().post("/update/defaults", data);
  };

  createTemplate = (data) => {
    return this.init().post("/add/template", data);
  };

  updateTemplate = (data) => {
    return this.init().post("/update/template", data);
  };

  getTemplates = (data) => {
    return this.init().get("/get/template");
  };

  getBranches = (data) => {
    return this.init().get("/get/branches", { params: data });
  };

  sendInvite = async (data) => {
    return await this.init().post("/send/invites", data);
  };

  addClientTemplate = async (data) => {
    return this.init().post("/client/add/template", data);
  };

  getClientTemplate = async (data) => {
    return this.init().get("/client/get/template", data);
  };

  updateDefaultTemplate = async (data) => {
    return this.init().get("/client/update/defaultTemplate", { params: data });
  };

  removeTemplate = async (data) => {
    return this.init().get("/client/delete/template", { params: data });
  };

  submitQuery = async (data) => {
    return this.init().post("/post/query", data);
  };

  deleteConfig = async (data) => {
    return this.init().get("/delete/config", { params: data });
  };

  clientDashboard = async () => {
    return this.init().get("/client/dashboard");
  };
  clientDownloadReport = async () => {
    return this.init(false, true).get("/download/report");
  };

  sendReminder = async (data) => {
    return this.init().post("/client/sendReminder", data);
  };

  getProfile = () => {
    return this.init().get("/client/profile");
  };

  changePassword = (data) => {
    return this.init().post("/changePassword", data);
  };

  updateEmailConfig = (data) => {
    return this.init().post("/update/emailConfig", data);
  };
  getEmailConfig = () => {
    return this.init().get("/get/emailConfig");
  };

  testMail = (data) => {
    return this.init().post("/test/emailConfig", data);
  };

  parseEmailTokens = (data) => {
    return this.init().get("/parse/emailtoken", { params: data });
  };

  changeLicState = (data) => {
    return this.init().post("/change/licenceState", data);
  };

  adminDashboard = (data) => {
    return this.init().get("/get/dashboard", { params: data });
  };

  deleteQuizQuestion = (data) => {
    return this.init().get("/delete/question", { params: data });
  };

  changeQuestionStatus = (data) => {
    return this.init().get("/change/questionStatus", { params: data });
  };

  getReminderTemplate = () => {
    return this.init().get("/get/reminderTemplate", {});
  };
  updateReminderTemplate = (data) => {
    return this.init().post("/update/reminderTemplate", data);
  };
  deleteReminderTemplate = () => {
    return this.init().get("/delete/reminderTemplate");
  };

  getCountries = () => {
    return this.csc().get("/countries");
  };

  getStates = (country) => {
    return this.csc().get("/countries/" + country + "/states");
  };

  getCities = (country, state) => {
    return this.csc().get(
      "/countries/" + country + "/states/" + state + "/cities"
    );
  };
}
