import {
    Avatar,
    Badge,
    Button,
    Box,
    Flex,
    Td,
    Text,
    Tr,
    Menu, Tooltip,
    MenuButton,
    MenuList,
    MenuItem,
    MenuIcon,
    IconButton,
    useColorModeValue,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    List, ListIcon, ListItem,

} from "@chakra-ui/react";
import { VscMenu, VscCircleLargeFilled, VscCircleLargeOutline } from 'react-icons/vsc'
import React from "react";

function TablesTableRow(props) {
    const data = props.data;
    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.400");

    return (
        <Tr>
            <Td>
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                        {data.questionId}
                    </Text>

                </Flex>
            </Td>

            <Td>
                <Flex direction="column" w="400px">
                    <Accordion allowToggle>
                        <AccordionItem borderRadius="10px" m="0.5">
                            <h2>
                                <AccordionButton _expanded={{ bg: 'gray.600', color: 'white' }} borderRadius="10px">
                                    <Box flex='1' textAlign='left' fontSize="sm" borderRadius={10}>
                                        {data.question}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel>
                                <Text mt={2} mb={2} fontWeight="bold" >Options</Text>
                                <List spacing={3}>
                                    {data.options.map(item => {
                                        return (
                                            <ListItem>
                                                {item.isCorrect === true ?
                                                    < ListIcon as={VscCircleLargeFilled} color='green.500' /> : <ListIcon as={VscCircleLargeOutline} color="gray.400" />}
                                                {item.option}
                                            </ListItem>);
                                    })}
                                </List>
                                <Tooltip label={data.explanation} aria-label='A tooltip' >
                                    <Text color='blue.300' mt={5}>Hover to see explanation</Text>
                                </Tooltip>

                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>

                </Flex>
            </Td>
            <Td>
                <Flex direction="column">
                    <Text fontSize="sm" color='gray.400' fontWeight="normal">
                        {data.lang.toUpperCase()}
                    </Text>

                </Flex>
            </Td>
            <Td>
                <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem" color="blue.500" noOfLines={2}>
                    {data.chapter}
                </Text>
            </Td>
            <Td>
                <Text fontSize="sm" color={textColor} fontWeight="normal" pb=".5rem">
                    {data.isMarkFixed ? <Badge colorScheme='green'>Yes</Badge> : <Badge colorScheme='red'>No</Badge>}
                </Text>
            </Td>
            <Td>
                <Text fontSize="sm" color={textColor} fontWeight="normal" pb=".5rem">
                    {data.isActive ? <Badge colorScheme='green'>active</Badge> : <Badge colorScheme='red'>inactive</Badge>}
                </Text>
            </Td>

            <Td>
                <Menu>
                    <MenuButton as={IconButton} icon={<VscMenu />}>

                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => props.onclick({ id: data.questionId, action: "delete" })}>Delete</MenuItem>
                        <MenuItem onClick={() => props.onclick({ id: data, action: "disable" })}>Disable</MenuItem>
                    </MenuList>
                </Menu>
            </Td>
        </Tr>
    );
}

export default TablesTableRow;
