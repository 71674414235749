import React, { useState, useEffect } from "react";
// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Grid,
  Image,
  Box,
  UnorderedList,
  ListItem,
  SimpleGrid,
  VStack,
  useColorModeValue,
  Link,
  Switch,
  useDisclosure,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesTableRow from "components/Tables/BranchesTableRow";
import {
  PhoneIcon,
  AddIcon,
  WarningIcon,
  ArrowBackIcon,
  EditIcon,
} from "@chakra-ui/icons";
import Api from "../../helpers/Fetch";
import Utils from "../../helpers/utils";
import Auth from "../../helpers/auth";

function ViewClient(props) {
  const textColor = useColorModeValue("gray.700", "white");
  const { onOpen, onClose } = useDisclosure();
  const [data, setData] = useState(null);
  const [orders, setOrders] = useState(null);
  const [isOpen, setOpen] = useState(false);
  let api = new Api();
  let util = new Utils();
  const inputRef = React.useRef();
  const inputRef2 = React.useRef();

  let userId = props.location.state.userId;
  useEffect(() => {
    let data = {
      userId: userId,
    };
    api
      .getClient(data)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((err) => {
        util.showServerError(err);
      });
  }, []);
  const updateClient = (update) => {
    update.userId = userId;
    api
      .updateClient(update)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((err) => {
        util.showServerError(err);
      });
  };
  const onclickHandler = (data) => {
    console.log("row clicked", data);
    if (data.action === "view_orders") {
      setOrders(data.data);
      setOpen(true);
    }
  };
  const updateLogo = (file) => {
    if (!!data) {
      api
        .updateClientLogo(file, data.userId, data.companyName)
        .then((res) => {
          console.log(res);
          util.showSuccess(res.data.message);
        })
        .catch((err) => {
          util.showServerError(err);
        });
    }
  };
  const updateBand = (file) => {
    if (!!data) {
      api
        .updateClientBand(file, data.userId, data.companyName)
        .then((res) => {
          console.log(res);
          util.showSuccess(res.data.message);
        })
        .catch((err) => {
          util.showServerError(err);
        });
    }
  };

  const OrdersModel = () => {
    return (
      <>
        <Modal
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          isCentered
          size="lg"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Orders</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction="column" py={3}>
                <Flex justify="space-between" w="100%" mb={3}>
                  <Text color="green.400">Order Id</Text>
                  <Text color="green.400">Quantity</Text>
                  <Text color="green.400">Order amount</Text>
                  <Text color="green.400">Status</Text>
                </Flex>
                {!!orders &&
                  orders.map((order) => {
                    return (
                      <>
                        <Flex
                          justify="space-between"
                          w="100%"
                          textAlign="start"
                        >
                          <Text>{order.orderId}</Text>
                          <Text>{order.licenceQuantity}</Text>
                          <Text>
                            Rs. {order.licenceQuantity * order.pricePerLicence}
                            /-
                          </Text>
                          <Text>{order.status}</Text>
                        </Flex>
                      </>
                    );
                  })}
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex
            justify="space-between"
            align="center"
            minHeight="30px"
            w="100%"
          >
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              {!!data && data.companyName}
            </Text>
            <Button
              fontSize="xs"
              variant="solid"
              leftIcon={<ArrowBackIcon />}
              onClick={() => props.history.goBack()}
            >
              Back
            </Button>
          </Flex>
        </CardHeader>
      </Card>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} mt={2}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex
            justify="space-between"
            align="center"
            minHeight="30px"
            w="100%"
          >
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Company Details
            </Text>
            <Button
              fontSize="xs"
              variant="solid"
              leftIcon={<EditIcon />}
              onClick={() => props.history.goBack()}
            >
              Edit
            </Button>
          </Flex>
        </CardHeader>
        <CardBody>
          {!!data ? (
            <Flex direction="column">
              <Box alignSelf="center" textAlign="center">
                <Link>
                  <Image
                    borderRadius="full"
                    boxSize="70px"
                    name="logo"
                    src={
                      !!data.company_logo.url
                        ? data.company_logo.url
                        : "https://icons-for-free.com/iconfiles/png/512/capture+device+photo+play+icon-1320166363978203980.png"
                    }
                    alt="Logo"
                    onClick={() => inputRef.current.click()}
                  />
                </Link>
                <Input
                  ref={inputRef}
                  accept="image/*"
                  hidden={true}
                  type="file"
                  onChange={(event) => {
                    updateLogo(event.currentTarget.files[0]);
                  }}
                />
                <Text color="gray.400" ml="-4" mb="4">
                  Click to update
                </Text>
              </Box>
              <Box alignSelf="center" textAlign="center">
                <Link>
                  <Image
                    borderRadius="full"
                    boxSize="70px"
                    name="logo"
                    src={
                      !!data.other_logo && !!data.other_logo.url
                        ? data.other_logo.url
                        : "https://icons-for-free.com/iconfiles/png/512/capture+device+photo+play+icon-1320166363978203980.png"
                    }
                    alt="Logo"
                    onClick={() => inputRef2.current.click()}
                  />
                </Link>
                <Input
                  ref={inputRef2}
                  accept="image/*"
                  hidden={true}
                  type="file"
                  onChange={(event) => {
                    updateBand(event.currentTarget.files[0]);
                  }}
                />
                <Text color="gray.400" ml="-4" mb="4">
                  Click to Update Band
                </Text>
              </Box>
              <SimpleGrid columns={3} spacingX={24} spacingY={8}>
                <Box textAlign="flex-start">
                  <Text align="start" color="gray.500" fontSize="14px">
                    ID
                  </Text>
                  <Text fontSize="16px">{data.userId}</Text>
                </Box>
                <Box textAlign="flex-start">
                  <Text align="start" color="gray.500" fontSize="14px">
                    Name
                  </Text>
                  <Text fontSize="16px">{data.companyName}</Text>
                </Box>
                <Box textAlign="flex-start">
                  <Text align="start" color="gray.500" fontSize="14px">
                    Display Name
                  </Text>
                  <Text fontSize="16px">{data.companyDisplayName}</Text>
                </Box>
                <Box textAlign="flex-start">
                  <Text align="start" color="gray.500" fontSize="14px">
                    Email
                  </Text>
                  <Text fontSize="16px">{data.email}</Text>
                </Box>
                <Box textAlign="flex-start">
                  <Text align="start" color="gray.500" fontSize="14px">
                    Secondary Email
                  </Text>
                  <Text fontSize="16px">{data.secondaryEmail || "-"}</Text>
                </Box>
                <Box textAlign="flex-start">
                  <Text align="start" color="gray.500" fontSize="14px">
                    Address
                  </Text>
                  <Text fontSize="16px">{data.address || "-"}</Text>
                </Box>
                <Box textAlign="flex-start">
                  <Text align="start" color="gray.500" fontSize="14px">
                    Country/State/City
                  </Text>
                  <Text fontSize="16px">
                    {data.country}/{data.state}/{data.city}
                  </Text>
                </Box>
                <Box textAlign="flex-start">
                  <Text align="start" color="gray.500" fontSize="14px">
                    Pincode
                  </Text>
                  <Text fontSize="16px">{data.pincode}</Text>
                </Box>
                <Box textAlign="flex-start">
                  <Text align="start" color="gray.500" fontSize="16px">
                    Contacts
                  </Text>
                  <UnorderedList>
                    {data.contacts.map((item) => {
                      return (
                        <ListItem>
                          {item.designation}, {item.name}, {item.contact}
                        </ListItem>
                      );
                    })}
                  </UnorderedList>
                </Box>
                <Box textAlign="flex-start">
                  <Text align="start" color="gray.500" fontSize="14px">
                    Account Status
                  </Text>
                  <Text color={!!data && data.isActive ? "green" : "red"}>
                    {!!data && data.isActive ? "Active" : "Inactive"}
                  </Text>
                  <Switch
                    isChecked={data.isActive}
                    onChange={() => updateClient({ isActive: !data.isActive })}
                  ></Switch>
                </Box>
              </SimpleGrid>
              <Flex
                justify="space-between"
                align="center"
                minHeight="30px"
                w="100%"
                mt={16}
              >
                <Text fontSize="lg" color={textColor} fontWeight="bold">
                  Branches
                </Text>
                <Button
                  fontSize="xs"
                  variant="ghost"
                  leftIcon={<AddIcon />}
                  onClick={() =>
                    props.history.push({
                      pathname: "/admin/step-branch",
                      state: {
                        userId: data.userId,
                        companyName: data.companyName,
                      },
                    })
                  }
                >
                  Add Branch
                </Button>
              </Flex>
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".3rem" pl="10px" color="gray.400">
                    <Th color="gray.400">Branch ID</Th>
                    <Th color="gray.400">Email</Th>
                    <Th color="gray.400">State/City</Th>
                    <Th color="gray.400">Orders</Th>
                    <Th color="gray.400">Licences</Th>
                    <Th color="gray.400">Actions</Th>

                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {!!data &&
                    !!data.branches &&
                    data.branches.map((row) => {
                      return (
                        <TablesTableRow data={row} onclick={onclickHandler} />
                      );
                    })}
                </Tbody>
              </Table>
            </Flex>
          ) : null}
          {orders !== null ? <OrdersModel /> : null}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default ViewClient;
