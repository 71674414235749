const Auth = {

    isLoggedIn: () => {
        console.log("isLoggedIn", localStorage.getItem("isLoggedIn"));
        return localStorage.getItem("isLoggedIn");
    },
    getLoginRole: () => {
        return localStorage.getItem("userType");
    },
    getToken: () => {
        return localStorage.getItem("ACCESS_TOKEN");
    },
    clearLocalStorage: (key) => {
        if (!!key) {
            localStorage.removeItem(key);
        }
        else (
            localStorage.clear()
        )
    },
    setDefaultLanguage(lang) {
        localStorage.setItem('default_lang', lang);
    },
    getDefaultLang() {
        let lang = localStorage.getItem('default_lang') || 'english';
        return lang;
    }
}
export default Auth;