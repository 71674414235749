import React, { useState, useEffect } from "react";
// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Grid,
  GridItem,
  useToast,
  useColorModeValue,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, Editable, EditableInput, EditablePreview
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesTableRow from "components/Tables/ModulesTableRow";
import { AddIcon } from '@chakra-ui/icons'
import Api from "../../helpers/Fetch";
import utils from "../../helpers/utils";
import Auth from '../../helpers/auth';

function Modules(props) {
  const textColor = useColorModeValue("gray.700", "white");
  const [data, setData] = useState(null);
  const [pageIndex, setPage] = useState(1);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  let api = new Api();
  let util = new utils();
  let editableRef = React.useRef(null);
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "red.800"
  );
  useEffect(() => {
    getModules();
  }, []);
  const getModules = (custom) => {

    let data = {
      pageSize: 10,
      currentPage: pageIndex,
      lang: Auth.getDefaultLang()
    }
    if (!!custom) {
      data = custom;
    }
    api.getModules(data).then((res) => {
      console.log(res);
      setData(res.data.data)

    }).catch((err) => {
      console.log(err);
      if (!!err && !!err.response) {
        util.showServerError(err);
      }

    });
  }
  const onclickHandler = (data) => {
    console.log("row clicked", data);
    if (!!data) {
      setSelectedData(data.id);
      if (data.action === 'open_modules') {
        props.history.push({ pathname: '/admin/chapters', state: { moduleId: data.id } });
      }
      else if (data.action === 'view') {
        setShowEdit(true)
      }
      else if (data.action === 'delete') {
        setShowDelete(true);
      }
      else {
        disableModule(data.id);
      }
    }
  }
  const saveChanges = (newValue) => {
    if (newValue === '') {
      alert('cannot be emply or void.');
    }
    api.updateModule({ moduleId: selectedData.moduleId, name: newValue }).then((res) => {
      console.log(res);
      setShowEdit(false)
      getModules();
    }).catch((err) => {
      console.log(err);
      if (!!err && !!err.response) {
        util.showServerError(err);
      }

    });
  }
  const deleteModule = () => {

    api.deleteModule({ moduleId: selectedData.moduleId }).then((res) => {
      console.log(res);
      setShowDelete(false)
      getModules();
    }).catch((err) => {
      console.log(err);
      if (!!err && !!err.response) {
        util.showServerError(err);
      }

    });
  }
  const disableModule = (data) => {

    api.disableModule({ moduleId: data.moduleId, disabled: data.visibility === 'visible' }).then((res) => {
      console.log(res);
      getModules();
    }).catch((err) => {
      console.log(err);
      if (!!err && !!err.response) {
        util.showServerError(err);
      }

    });
  }

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "scroll", md: 'scroll' }}>
        <CardHeader p="1px 0px 22px 0px">
          <Flex justify="space-between" align="center" minHeight="30px" w="100%">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Chapters
            </Text>
            <Button
              bg={bgButton}
              color="white"
              fontSize="xs"
              variant="solid"
              leftIcon={<AddIcon />}
              onClick={() => {
                props.history.push('/admin/create/mcc')
              }}

            >
              Add New
            </Button>
          </Flex>

        </CardHeader>
        <CardBody>
          <Table variant="simple" size="sm" color={textColor} overflowX='scroll'>
            <Thead>
              <Tr my=".3rem" pl="10px" color="gray.400">
                <Th color="gray.400">#</Th>
                <Th color="gray.400">ID</Th>
                <Th color="gray.400">Title</Th>
                <Th color="gray.400">Languages</Th>
                <Th color="gray.400">ICC</Th>
                <Th color="gray.400">Videos</Th>
                <Th color="gray.400">Chapters</Th>
                <Th color="gray.400">Status</Th>
                <Th color="gray.400">Sort</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!!data && data.map((row, index) => {
                return (
                  <TablesTableRow
                    data={row}
                    index={index}
                    onclick={onclickHandler}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
      <Modal isOpen={showEdit} onClose={() => setShowEdit(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit chapter</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Editable defaultValue={!!selectedData ? selectedData.title : ''} ref={editableRef} onSubmit={(v) => saveChanges(v)}>
              <EditablePreview />
              <EditableInput />
            </Editable>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='gray' mr={3} onClick={() => setShowEdit(false)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={showDelete} onClose={() => setShowDelete(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete chapter?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Deleting chapter will also delete topics and content. Are you sure, still wanna delete?</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='gray' mr={3} onClick={() => setShowDelete(false)}>
              Don't
            </Button>
            <Button colorScheme='red' mr={3} onClick={deleteModule}>
              Yes, Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default Modules;
