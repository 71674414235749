import React, { useState, useEffect } from "react";
// Chakra imports
import {
    Flex,
    Text,
    VStack, InputGroup, InputLeftElement, Input,
    IconButton, Box, Textarea, Grid, GridItem, Spacer, Select,
    Button, FormControl, Divider, SlideFade,
    CheckboxGroup, Alert, AlertDescription, AlertIcon, AlertTitle, CloseButton,
    useColorModeValue,
    Checkbox, Link, SimpleGrid

} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { AddIcon, ArrowBackIcon, CalendarIcon, MinusIcon, PhoneIcon } from '@chakra-ui/icons'
import { HiOutlineOfficeBuilding, HiOutlineMail, HiDotsHorizontal } from 'react-icons/hi'
import Api from "../../helpers/Fetch";
import { Formik, FieldArray } from 'formik';
import Utils from "../../helpers/utils";
import { BiRupee } from "react-icons/bi";
import { Separator } from "components/Separator/Separator";
import { FaKey } from "react-icons/fa";

function CreateOrder(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const [isLoading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [updateData, setUpdateData] = useState(null);

    const [clients, setClients] = useState(null);
    const [client, setClient] = useState(null);
    const [configs, setConfigs] = useState(null);
    const [addItems, setShowAddItems] = useState(false);

    let api = new Api();
    let util = new Utils();
    console.log(props);
    // const { userId, companyName } = props.location.state;
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    let min = yyyy + '-' + mm + '-' + dd;
    let max = (yyyy + 1) + '-' + mm + '-' + dd;
    console.log(today)
    console.log(props)
    useEffect(() => {
        api.getClients({ pageSize: 0 }).then(res => {
            setClients(res.data.data);
        }).catch(err => {
            if (!err && !!err.response) {
                util.showError(err.response.data.message, '');
            }
        });
        api.getConfigModules().then(res => {
            setConfigs(res.data.data);
        }).catch(err => {
            util.showServerError(err);
        });
    }, []);
    const getClient = (data) => {
        console.log(data.target.value);
        api.getClient({ userId: data.target.value }).then(res => {
            setClient(res.data.data.branches);
            if (res.data.data.branches.length == 0) {
                util.showWarning('Branch Needed', 'Please configure a branch to create order');
            }
        }).catch(err => {
            if (!err && !!err.response) {
                util.showError(err.response.data.message, '');
            }
        });
    }
    let data;
    if (!!props && !!props.location && !!props.location.state) {
        data = props.location.state;
        if (!!props.location.state.orderId) {
            useEffect(() => {
                api.getOrder({ orderId: props.location.state.orderId }).then((res) => {
                    console.log(res);
                    setUpdateData(res.data.data);
                }).catch((err) => {
                    console.log(err);
                    if (!!err && !!err.response) {
                        if (err.response.data.message === 'Validation Error' && !!err.response.data && !!err.response.data.data.length > 0)
                            util.showError(err.response.data.data[0], '');
                        else
                            util.showError(err.response.data.message, '');
                    }

                });
            }, []);
        }
    }
    const createPO = (data) => {
        setLoading(true)
        api.createPO(data).then((res) => {
            setLoading(false)
            util.showSuccess(res.data.message, '');
            setShowSuccess(true);
        }).catch((err) => {
            console.log(err.response);
            setLoading(false)
            if (!!err && !!err.response) {
                if (err.response.data.message === 'Validation Error' && !!err.response.data && !!err.response.data.data.length > 0)
                    util.showError(err.response.data.data[0], '');
                else
                    util.showError(err.response.data.message, '');
            }

        });
    }
    const updatePO = (data) => {
        setLoading(true)
        api.updatePO(data).then((res) => {
            setLoading(false)
            util.showSuccess(res.data.message, '');
            props.history.goBack();
        }).catch((err) => {
            console.log(err.response);
            setLoading(false)
            if (!!err && !!err.response) {
                if (err.response.data.message === 'Validation Error' && !!err.response.data && !!err.response.data.data.length > 0)
                    util.showError(err.response.data.data[0], '');
                else
                    util.showError(err.response.data.message, '');
            }

        });
    }


    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                <CardHeader p="6px 0px 22px 0px">
                    <Flex justify="space-between" align="center" w="100%">
                        <Text fontSize="lg" colorScheme="blackAlpha" fontWeight="bold" pl="24px">
                            {updateData == null ? 'Create' : 'Update'} Purchase Order
                        </Text>
                        <Button variant="solid" colorScheme='gray' leftIcon={<ArrowBackIcon />} onClick={() => props.history.goBack()}>Back</Button>
                    </Flex>

                </CardHeader>
                <Separator />
                <CardBody>
                    {!showSuccess ?
                        <Box direction="column" align="center" w="60%" mt="0px" zIndex={99}>
                            <Formik
                                initialValues={{
                                    "userId": "",
                                    "branchId": "",
                                    "licenceExpiryDate": "",
                                    "contentIncluded": true,
                                    "quizIncluded": true,
                                    "customEmailing": false,
                                    "customTemplating": false,
                                    "moduleId": "",
                                    "licenceQuantity": 0,
                                    "pricePerLicence": 0,
                                    "additonalItems": [
                                        {
                                            "description": '',
                                            "quantity": 0,
                                            "price": 0
                                        }
                                    ]

                                }}
                                onSubmit={(values, actions) => {
                                    console.log(values);
                                    if (updateData == null) {
                                        createPO(values);
                                    }
                                    else {
                                        updatePO(values);
                                    }
                                }}
                                validateOnChange

                            >
                                {({
                                    values,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <FormControl w="100%">
                                            <SimpleGrid columns={[1, null, 1]} spacing='40px' w="100%">
                                                <Box p='6' borderWidth='1px' borderRadius='lg' flex="1" mt={30} w="100%">
                                                    <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                                                        <VStack alignItems='flex-start'>
                                                            <Text color="gray.500">Select Client</Text>
                                                            <Select variant="outline" placeholder='Select Client' name="userId" onChange={(e) => { setFieldValue('userId', e.target.value); getClient(e) }}>
                                                                {clients != null && clients.map((client) => {
                                                                    return (<option value={client.userId}>{client.companyName}</option>);
                                                                })}
                                                            </Select>
                                                        </VStack>
                                                        <VStack alignItems='flex-start'>
                                                            <Text color="gray.500">Select Branch</Text>
                                                            <Select variant="outline" placeholder='Select Branch' name="branchId" value={values.branchId === "" ? data.branchId : values.branchId} onChange={(e) => { setFieldValue('branchId', e.target.value); }}>
                                                                {client != null && client.map((client) => {
                                                                    return (<option value={client.branchId}>{client.branchName}</option>);
                                                                })}
                                                            </Select>
                                                        </VStack>
                                                        <VStack alignItems='flex-start'>
                                                            <Text color="gray.500">Number of licences   <Text as='sup' color='red'>*</Text></Text>
                                                            <InputGroup size='md'>
                                                                <InputLeftElement
                                                                    pointerEvents="none"
                                                                    children={<FaKey color="gray.300" />}
                                                                />
                                                                <Input type="number" placeholder="licence" name="licenceQuantity" value={updateData !== null ? values.licenceQuantity === 0 ? updateData.licenceQuantity : values.licenceQuantity : values.licenceQuantity} onChange={handleChange} required />
                                                            </InputGroup>
                                                        </VStack>
                                                        <VStack alignItems='flex-start'>
                                                            <Text color="gray.500">Price per licence(<i>Optional</i>)</Text>
                                                            <InputGroup size='md'>
                                                                <InputLeftElement
                                                                    pointerEvents="none"
                                                                    children={<BiRupee color="gray.300" />}
                                                                />
                                                                <Input type="number" placeholder="Price Per licence" name="pricePerLicence" value={updateData !== null ? values.pricePerLicence === 0 ? updateData.pricePerLicence : values.pricePerLicence : values.pricePerLicence} onChange={handleChange} required />
                                                            </InputGroup>
                                                        </VStack>
                                                        <VStack alignItems='flex-start'>
                                                            <Text color="gray.500">Expiry Date (DD-MM-YYYY)</Text>
                                                            <InputGroup size='md'>
                                                                <InputLeftElement
                                                                    pointerEvents="none"
                                                                    children={<CalendarIcon color="gray.300" />}
                                                                />
                                                                <Input type="date" placeholder="01-01-2000" name="licenceExpiryDate" value={updateData !== null ? values.licenceExpiryDate === "" ? updateData.licenceExpiryDate : values.licenceExpiryDate : values.licenceExpiryDate} min={min} max={max} onChange={handleChange} required />
                                                            </InputGroup>
                                                        </VStack>

                                                        <VStack alignItems='flex-start'>
                                                            <Text color="gray.500">Select module</Text>
                                                            <Select variant="outline" placeholder='Select Module' name="moduleId" value={updateData !== null ? values.moduleId === "" ? updateData.moduleId : values.moduleId : values.moduleId} onChange={handleChange}>
                                                                {configs != null && configs.map((item) => {
                                                                    return (<option value={item.configId}>{item.name}</option>);
                                                                })}
                                                            </Select>
                                                        </VStack>
                                                        <Checkbox name="contentIncluded" isChecked={updateData !== null ? values.contentIncluded === false ? updateData.contentIncluded : values.contentIncluded : values.contentIncluded} onChange={handleChange}>
                                                            Include Read Content
                                                        </Checkbox>
                                                        <Checkbox name="quizIncluded" isChecked={updateData !== null ? values.quizIncluded === false ? updateData.quizIncluded : values.quizIncluded : values.quizIncluded} onChange={handleChange}>
                                                            Include Quiz
                                                        </Checkbox>
                                                        <Checkbox name="customEmailing" isChecked={updateData !== null ? values.customEmailing === false ? updateData.customEmailing : values.customEmailing : values.customEmailing} onChange={handleChange}>
                                                            Email Customization
                                                        </Checkbox>
                                                        <Checkbox name="customTemplating" isChecked={updateData !== null ? values.customTemplating === false ? updateData.customTemplating : values.customTemplating : values.customTemplating} onChange={handleChange}>
                                                            Custom Templates
                                                        </Checkbox>
                                                        <Flex>
                                                            <Button onClick={() => setShowAddItems(!addItems)}>{!addItems ? "Add Items" : "Remove Items"}</Button>
                                                        </Flex>
                                                        {addItems ?
                                                            <FieldArray name="additonalItems">
                                                                {({ insert, remove, push }) => (
                                                                    values.additonalItems.map((item, index) => (

                                                                        <GridItem colSpan={2}>
                                                                            <Flex>
                                                                                <InputGroup>
                                                                                    <InputLeftElement
                                                                                        pointerEvents="none"

                                                                                    />
                                                                                    <Input type="text" placeholder="Description" name={`additonalItems.${index}.description`} onChange={handleChange} />
                                                                                </InputGroup>
                                                                                <Spacer />
                                                                                <InputGroup ms="10px" me="10px">
                                                                                    <InputLeftElement
                                                                                        pointerEvents="none"
                                                                                    />
                                                                                    <Input type="text" placeholder="Quantity" name={`additonalItems.${index}.quantity`} onChange={handleChange} />
                                                                                </InputGroup>
                                                                                <Spacer />
                                                                                <InputGroup>
                                                                                    <InputLeftElement
                                                                                        pointerEvents="none"
                                                                                    />
                                                                                    <Input type="number" placeholder="Price" name={`additonalItems.${index}.price`} onChange={handleChange} />
                                                                                </InputGroup>
                                                                                <Spacer />
                                                                                {index === 0 ?

                                                                                    <IconButton aria-label='add contact' icon={<AddIcon />} ms={6} onClick={() => push({
                                                                                        "description": '',
                                                                                        "quantity": 0,
                                                                                        "price": 0
                                                                                    })} />
                                                                                    : <IconButton aria-label='remove contact' icon={<MinusIcon />} ms={6} onClick={() => remove(index)} />}

                                                                            </Flex>
                                                                        </GridItem>
                                                                    )
                                                                    ))}
                                                            </FieldArray>
                                                            : null}
                                                        {updateData == null || (updateData != null && !updateData.licencesReleased) ?

                                                            < GridItem colSpan={2}>
                                                                <Button
                                                                    fontSize="14px"
                                                                    type="submit"
                                                                    bg="red.400"
                                                                    w="100%"
                                                                    h="45"
                                                                    mb="20px"
                                                                    color="white"
                                                                    isLoading={isLoading}
                                                                    mt="20px"
                                                                    _hover={{
                                                                        bg: 'red.200',
                                                                    }}
                                                                    _active={{
                                                                        bg: 'red.400',
                                                                    }}
                                                                    type="submit"
                                                                >
                                                                    {updateData == null ? 'Preview' : 'Update'}
                                                                </Button>

                                                            </GridItem>
                                                            : null}
                                                    </Grid>


                                                </Box>
                                            </SimpleGrid>
                                        </FormControl>
                                    </form>
                                )}
                            </Formik>

                        </Box>
                        :
                        <Flex direction="column" align="center" w="100%" mt="0px" zIndex={99}>
                            <SlideFade in={true} offsetY='20px'>

                                <Alert
                                    status='success'
                                    variant='subtle'
                                    flexDirection='column'
                                    alignItems='center'
                                    justifyContent='center'
                                    textAlign='center'
                                    height='200px'
                                    w="100%"
                                >
                                    <AlertIcon boxSize='40px' mr={0} />
                                    <AlertTitle mt={4} mb={1} fontSize='lg'>
                                        Order Created.
                                    </AlertTitle>
                                    <AlertDescription maxWidth='sm'>
                                        Licence order is successfully generated.<Divider />
                                        <Link color='red.500' href="/admin/po" >Go To  Purchase Orders </Link>to release the licences.
                                    </AlertDescription>
                                </Alert>
                            </SlideFade>
                        </Flex>
                    }

                </CardBody>
            </Card>
        </Flex >
    );
}

export default CreateOrder;
