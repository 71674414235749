import {
    Avatar,
    Badge,
    Button,
    Flex,
    Td,
    Text,
    Tr,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuIcon,
    IconButton,
    useColorModeValue,
} from "@chakra-ui/react";
import { VscMenu } from 'react-icons/vsc'
import React from "react";

function TablesTableRow(props) {
    const data = props.data;
    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.400");

    return (
        <Tr>
            <Td >
                <Flex direction="column">
                    <Text fontSize="sm" color="gray.600" fontWeight="normal" noOfLines={2}>
                        {data.branchName}
                    </Text>
                    <Text fontSize="sm" color="gray.400" fontWeight="normal">
                        {data.branchId}
                    </Text>
                </Flex>
            </Td>

            <Td>
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="normal">
                        {data.branchEmail}
                    </Text>
                    {/* <Text fontSize="sm" color="gray.400" fontWeight="normal">
                        {subdomain}
                    </Text> */}
                </Flex>
            </Td>
            <Td>
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="normal">
                        {data.state}/{data.city}
                    </Text>
                    {/* <Text fontSize="sm" color="gray.400" fontWeight="normal">
                        {subdomain}
                    </Text> */}
                </Flex>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem" color="blue.500">
                    <a onClick={() => props.onclick({ data: data.purchaseOrders, action: "view_orders" })}><u>{data.purchaseOrders.length}</u></a>
                </Text>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
                    <a onClick={() => props.onclick({ id: data.branchId, action: "view_licences" })}><u>{data.licences || 0}</u></a>
                </Text>
            </Td>

            <Td>
                <Menu>
                    <MenuButton as={IconButton} icon={<VscMenu />}>

                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => props.onclick({ id: data.branchId, action: "view" })}>Edit</MenuItem>
                        <MenuItem onClick={() => props.onclick({ id: data.branchId, action: "delete" })}>Delete</MenuItem>
                    </MenuList>
                </Menu>
            </Td>
        </Tr>
    );
}

export default TablesTableRow;
