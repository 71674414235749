// Chakra imports
import {
    Box,
    Button, Wrap, WrapItem,
    Flex,
    Grid,
    Icon,
    Spacer,
    Text, ButtonGroup, SimpleGrid,
    useColorModeValue, VStack, Select,
    Popover, IconButton,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    LinkBox, LinkOverlay,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,

} from "@chakra-ui/react";
// Assets
import BackgroundCard1 from "assets/img/BackgroundCard1.png";
import BackgroundCard2 from "assets/img/solidcolor.png";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React, { useEffect, useState } from "react";
import { GiBookPile } from "react-icons/gi";
import { BsFilesAlt } from "react-icons/bs";
import { BiBookReader } from "react-icons/bi";
import Api from '../../helpers/Fetch';
import Util from '../../helpers/utils';
import Auth from '../../helpers/auth';
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { IoTimerOutline } from "react-icons/io5";

function Content(props) {

    let api = new Api();
    let util = new Util();
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [getId, setId] = useState(null);
    const [dropdownData, setDropdownData] = useState(null);
    const langRef = React.useRef();
    const open = () => setOpen(!isOpen)
    const close = () => setOpen(false)

    const [isDialogOpen, setIsOpen] = React.useState(false)
    const onClose = () => setIsOpen(false)
    const cancelRef = React.useRef()

    useEffect(() => {
        getModules();

    }, []);
    const getModules = () => {
        api.getQuizLangPacks().then(res => {
            setData(res.data.data);
        }).catch(err => {
            util.showServerError(err);
        })
    }
    const openChapters = (lang) => {
        Auth.setDefaultLanguage(lang);
        if (!!props && !!props.history) {
            props.history.push({
                pathname: '/admin/quiz',
                state: {
                    lang: lang
                }
            })
        }
    }
    const deleteModule = () => {
        api.deleteContentModule({ id: getId }).then(res => {
            getModules();
            setIsOpen(false)
            setLoading(false)
        }).catch(err => {
            util.showServerError(err);
            setLoading(false)
        })
    }


    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Grid templateRows="1fr" w="100%">
                <Box w="100%">
                    <SimpleGrid
                        w="100%"
                        columns={{ sm: 1, md: 4 }} spacingX='20px' spacingY='10px'
                    >
                        {!!data && data.map(item => {

                            return (<VStack>
                                <LinkBox p='5' rounded='sm'>
                                    <LinkOverlay href='#' onClick={() => openChapters(item.lang)}>
                                        <Card
                                            backgroundImage={BackgroundCard1}
                                            backgroundRepeat="no-repeat"
                                            background="cover"
                                            bgPosition="10%"
                                            p="8px"
                                            h={{ sm: "220px", xl: "100%" }}
                                            gridArea={{ md: "1 / 1 / 2 / 3", xl: "1 / 1 / 2 / 3" }}
                                        >
                                            <CardBody h="100%" w="200px">
                                                <Flex
                                                    direction="column"
                                                    color="white"
                                                    h="100%"
                                                    p="0px 10px 20px 10px"
                                                    w="100%"
                                                >
                                                    <Flex justify="space-between" align="center">
                                                        <Text fontSize="md" fontWeight="bold">
                                                            {item.lang.toUpperCase()}
                                                        </Text>
                                                        <Icon
                                                            as={IoTimerOutline}
                                                            w="48px"
                                                            h="auto"
                                                            color="gray.400"
                                                        />
                                                    </Flex>
                                                    <Spacer />
                                                    <Flex direction="column">
                                                        <Box>
                                                            <Wrap spacing='10px' align='center' justifyContent='stretch'>
                                                                <WrapItem>
                                                                    <Text fontSize="44px" fontWeight="bold">
                                                                        {item.chapters}
                                                                    </Text>
                                                                </WrapItem>
                                                                <WrapItem>
                                                                    <Text fontSize="md" pt={4} color='gray.400' fontWeight="bold">Questions</Text>
                                                                </WrapItem>
                                                            </Wrap>
                                                        </Box>

                                                    </Flex>
                                                </Flex>
                                            </CardBody>
                                        </Card>
                                    </LinkOverlay>
                                </LinkBox>
                                {/* <IconButton icon={<DeleteIcon />} id={item.packageId} colorScheme='red' w="20px" pos="relative" bottom="120px" left="105px" mt='14px' onClick={(e) => { setId(e.target.id); setIsOpen(true) }} /> */}
                            </VStack>);
                        })}

                    </SimpleGrid>
                </Box>
            </Grid >
            <AlertDialog
                isOpen={isDialogOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={deleteModule} isLoading={isLoading} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </Flex >
    );
}

export default Content;
