import React, { useState, useEffect } from "react";
// Chakra imports
import {
    Flex,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    Divider,
    useColorModeValue,
    Link,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TableRow from "components/Tables/ModuleConfigRow";
import { AddIcon } from '@chakra-ui/icons'
import Api from "../../helpers/Fetch";
import Auth from '../../helpers/auth';
import Utils from '../../helpers/utils';
import { Separator } from "components/Separator/Separator";


function Orders(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const [data, setData] = useState(null);
    const [pageIndex, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = React.useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false)
    const [orderId, setOrderId] = React.useState('')
    const onClose = () => { setIsOpen(false); setIsDeleteOpen(false) }
    const cancelRef = React.useRef()

    let api = new Api();
    let utils = new Utils();
    let toast = useToast();
    const bgButton = useColorModeValue(
        "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
        "red.800"
    );
    let querydata = {
        pageSize: 10,
        currentPage: pageIndex
    }
    useEffect(() => {
        getData(querydata)

    }, []);
    const getData = (data) => {
        api.getConfigModules(data).then((res) => {
            console.log(res);
            setData(res.data.data)

        }).catch((err) => {
            console.log(err);
            utils.showServerError(err);

        });
    }
    const onclickHandler = (data) => {
        console.log("row clicked", data);
        if (!!data) {
            setOrderId(data.id);
            if (data.action === 'delete') {
                setIsDeleteOpen(true);
            }
            else if (data.action === 'view') {
                props.history.push({
                    pathname: 'config/details',
                    state: {
                        moduleId: data.id
                    }
                })
            }
        }
    }

    const deleteOrder = () => {
        let data = {
            id: orderId
        }
        setIsLoading(true);
        api.deleteConfig(data).then((res) => {
            console.log(res);
            setIsDeleteOpen(false);
            setIsLoading(false);

            utils.showSuccess(res.data.message, '');
            getData(querydata);

        }).catch((err) => {
            console.log(err);
            utils.showServerError(err);

        });
    }

    return (<>
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                <CardHeader p="6px 0px 22px 0px">
                    <Flex justify="space-between" align="center" minHeight="60px" w="100%">
                        <Text fontSize="lg" color={textColor} fontWeight="bold">
                            Licence Configuration Modules
                        </Text>
                        <Button
                            bg={bgButton}
                            color="white"
                            fontSize="xs"
                            variant="solid"
                            leftIcon={<AddIcon />}
                            onClick={() => props.history.push('/admin/add-moduleConfig')}

                        >
                            Add New
                        </Button>
                    </Flex>

                </CardHeader>
                <Separator mb={2} />
                <CardBody>
                    <Table variant="striped" color={textColor} size="sm">
                        <Thead>
                            <Tr my="1.3rem" pl="10px" color="gray.400">
                                <Th color="gray.400">#</Th>
                                <Th color="gray.400">ID</Th>
                                <Th color="gray.400">Name</Th>
                                <Th color="gray.400">Quiz Type</Th>
                                <Th color="gray.400">Quiz Format</Th>
                                <Th color="gray.400">Learning Type</Th>
                                <Th color="gray.400">Languages</Th>
                                <Th color="gray.400">CreatedAt</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {!!data && data.map((row, index) => {
                                return (
                                    <>
                                        <TableRow
                                            index={index + 1}
                                            data={row}
                                            onclick={onclickHandler}
                                        />
                                    </>

                                );
                            })}
                        </Tbody>
                    </Table>
                </CardBody>
            </Card>
        </Flex>

        <AlertDialog
            isOpen={isDeleteOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Confirm Delete?
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Are you sure you want delete this configuration?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme='red' onClick={onClose} ml={3} isLoading={isLoading} onClick={deleteOrder}>
                            Delete
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    </>
    );
}

export default Orders;
