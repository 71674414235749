import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
// Chakra imports
import {
  Box,
  Flex,
  Button,
  Image,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Switch,
  Text,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react'
// Assets
import signInImage from 'assets/img/C4P-logo-1.png'
import signInBg from 'assets/img/login.jpeg'
import Api from '../../helpers/Fetch'


function SignIn(props) {
  // Chakra color mode
  const titleColor = useColorModeValue('red.500', 'red.200')
  const textColor = useColorModeValue('gray.400', 'white')
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  function doLogin(data) {
    setError(null)
    setLoading(true)
    console.log('login clicked')
    const api = new Api()

    api
      .login(data)
      .then((res) => {
        setLoading(false)
        console.log(res);
        localStorage.setItem("ACCESS_TOKEN", res.data.data.token);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("userType", 'A');
        if (res.data.data.role === 'admin') {
          localStorage.setItem("userType", 'A');
        }
        else {
          localStorage.setItem("userType", 'O');
        }
        toast({
          title: 'LoggedIn',
          description: res.data.message,
          status: 'success',
          duration: 1000,
          isClosable: true,
        })
        window.location.reload(false)
      })
      .catch((err) => {
        setLoading(false)
        if (!!err && !!err.response && !!err.response.data) {
          console.log(err.response.data)
          setError(err.response.data.message)
          toast({
            title: 'Denied',
            description: err.response.data.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          })

        }

      })
  }
  return (

    <Flex position="relative" mb="40px">
      <Flex
        h={{ sm: 'initial', md: '75vh', lg: '85vh' }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: '100px', md: '0px' }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: 'none' }}
          w={{ base: '100%', md: '50%', lg: '42%' }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="18px"
            mt={{ md: '150px', lg: '80px' }}
          >
            <Box
              bgImage={signInImage}
              w="110px"
              h="70px"
              mb="20px"
              bgSize="cover"
            ></Box>
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Welcome Back
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              Enter your email and password to sign in
            </Text>
            {error ?
              <Alert status="error" variant="left-accent" mb="10px">
                <AlertIcon />
                <AlertTitle>
                  {error}
                </AlertTitle>
              </Alert> :
              <></>}

            <Formik
              initialValues={{ userId: '', password: '' }}
              onSubmit={(values, actions) => {
                doLogin(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl>
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      User ID / Email
                    </FormLabel>
                    <Input
                      borderRadius="15px"
                      mb="24px"
                      name="userId"
                      fontSize="sm"
                      onChange={handleChange}
                      type="text"
                      placeholder="Your email adress"
                      size="lg"
                      required
                    />
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      Password
                    </FormLabel>
                    <Input
                      borderRadius="15px"
                      mb="16px"
                      fontSize="sm"
                      name="password"
                      type="password"
                      onChange={handleChange}
                      placeholder="Your password"
                      size="lg"
                      required
                    />
                    <Flex justify='flex-end' >
                      <Button variant='link' color='red.400'>Forget Password?</Button>
                    </Flex>
                    <Button
                      fontSize="10px"
                      type="submit"
                      bg="red.300"
                      w="100%"
                      h="45"
                      mb="20px"
                      color="white"
                      isLoading={isLoading}
                      mt="20px"
                      _hover={{
                        bg: 'red.200',
                      }}
                      _active={{
                        bg: 'red.400',
                      }}
                      type="submit"
                    >
                      SIGN IN
                    </Button>
                  </FormControl>
                </form>
              )}
            </Formik>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            ></Flex>
          </Flex>
        </Flex>
        <Box
          display={{ base: 'none', md: 'block' }}
          overflowX="hidden"
          h="100vh"
          w="100vh"
          bgColor='white'
          position="absolute"
          right="0px"
        >
          <Image
            src={signInBg}
            w="100%"
            h="100%"
            objectFit='contain'
            bgPosition="10%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Image>
        </Box>
      </Flex>
    </Flex >
  )
}

export default SignIn;
