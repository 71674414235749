import React, { useState, useEffect } from "react";
// Chakra imports
import {
    Flex, Tabs, TabList, TabPanels, Tab, TabPanel,
    Text, Box,
    Button, Wrap, WrapItem,
    Grid, GridItem, SimpleGrid,
    Input, InputGroup, InputLeftElement,
    useToast, IconButton, Spacer,
    useColorModeValue, Stack, Checkbox,
    Link, Select, FormControl, Textarea
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesTableRow from "components/Tables/ChaptersTableRow";
import { AddIcon, ArrowBackIcon, MinusIcon, PhoneIcon } from '@chakra-ui/icons'
import Api from "../../helpers/Fetch";
import Auth from '../../helpers/auth';
import Util from '../../helpers/utils';
import { MdTitle } from 'react-icons/md'
import { Formik, FieldArray } from 'formik'



function AddQuestion(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const [data, setData] = useState(null);
    const [chapters, setChapters] = useState(null);
    const [moduleId, setModuleId] = useState(null);
    const [pageIndex, setPage] = useState(1);
    const [isLoading, setLoading] = useState(false);
    let api = new Api();
    let util = new Util();
    const resetbtnRef = React.useRef();

    useEffect(() => {
        if (!!props && !!props.location && !!props.location.state) {
            console.log('props', props.location.state.moduleId);
            setModuleId(props.location.state.moduleId)
        }
        api.getDropdownData().then(res => {
            setData(res.data.data);
        }).catch(err => {
            util.showServerError(err);
        });
        api.getQuizChapters({ lang: Auth.getDefaultLang().toLowerCase() }).then(res => {
            setChapters(res.data.data);
        }).catch(err => {
            util.showServerError(err);
        });
    }, [])
    const AddQuestion = (values) => {
        setLoading(true);

        api.addQuestion(values).then(res => {
            setLoading(false);
            util.showSuccess(res.data.message, '');
        }).catch(err => {
            setLoading(false);
            util.showServerError(err);
        });
    }

    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card overflowX={{ sm: "scroll", xl: "scroll", md: 'scroll' }}>
                <CardHeader p="1px 0px 22px 0px">
                    <Flex justify="space-between" align="center" minHeight="30px" w="100%">
                        <Text fontSize="lg" color={textColor} fontWeight="bold">
                            Add
                        </Text>
                        <Button
                            fontSize="xs"
                            variant="solid"
                            leftIcon={<ArrowBackIcon />}
                            onClick={() => props.history.goBack()}

                        >
                            Back
                        </Button>
                    </Flex>

                </CardHeader>
                <CardBody>
                    <Flex w='100%'>
                        <Box w="100%" borderRadius="10px" borderWidth="1px" p="30px">
                            <Formik
                                initialValues={{
                                    "question": "",
                                    "chapterId": "",
                                    isMarkFixed: false,
                                    "lang": Auth.getDefaultLang().toLowerCase(),
                                    "options": [
                                        {
                                            "optionId": "",
                                            "option": "",
                                            "isCorrect": false,
                                            "sortIndex": 0
                                        },
                                        {
                                            "optionId": "",
                                            "option": "",
                                            "isCorrect": false,
                                            "sortIndex": 0
                                        }
                                    ],
                                    "explanation": ""
                                }}
                                onSubmit={(values, actions) => {
                                    console.log('values', values);
                                    if (values.chapterId === '') {
                                        values.chapterId = moduleId;
                                    }
                                    AddQuestion(values);

                                }}

                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    handleReset,
                                    isSubmitting,
                                    setFieldValue
                                    /* and other goodies */
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <FormControl>
                                            <Flex direction='column'>
                                                <Checkbox pos="absolute" right={1} mb='4' name="isMarkFixed" onChange={handleChange}>Mark as fixed</Checkbox>
                                                <Text mt="14px" color='gray.400'>Select Language</Text>
                                                <Select placeholder="Select language" name="lang" value={values.lang} onChange={handleChange}>
                                                    {
                                                        !!data && !!data.languages && data.languages.map(item => {
                                                            return (
                                                                <option value={item.toLowerCase()}>{item}</option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                                <Text mt="14px" color='gray.400'>Select Chapter</Text>
                                                <Select placeholder="Select Chapter" name="chapterId" value={values.chapterId === "" ? moduleId : values.chapterId} onChange={handleChange}>
                                                    {
                                                        !!chapters && chapters.map(item => {
                                                            return (
                                                                <option value={item.moduleId}>{item.name}</option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                                <Text mt="14px" color='gray.600' fontWeight='bold'>Question</Text>
                                                <InputGroup>
                                                    <InputLeftElement
                                                        pointerEvents="none"
                                                        children={<MdTitle color="gray.300" />}
                                                    />
                                                    <Input type="text" placeholder="your question here.." name="question" onChange={handleChange} />
                                                </InputGroup>
                                                <Text fontWeight="bold" size="md" color="gray.600" mt="24px">Options</Text>
                                                <FieldArray name="options">
                                                    {({ insert, remove, push }) => (
                                                        values.options.map((option, index) => (

                                                            <Wrap w="100%" spacing='20px' mt={3}>
                                                                <WrapItem>
                                                                    <Box as='h1' borderRadius='md' bg='gray.600' color='white' px={4} py={2} textAlign="center" name='optionId' value={index + 1} onChange={handleChange}>
                                                                        <Text onLoad={() => setFieldValue(`options.${index}.optionId`, index + 1)}>{index + 1}</Text>
                                                                    </Box>
                                                                </WrapItem>
                                                                <WrapItem w="40%">
                                                                    <InputGroup ms={5}>
                                                                        <Input type="text" placeholder={`Option ${index + 1}`} name={`options.${index}.option`} onChange={handleChange} />
                                                                    </InputGroup>
                                                                </WrapItem>
                                                                <WrapItem>
                                                                    <InputGroup ms={10}>
                                                                        <Input type="number" w={150} placeholder="Sort Index" name={`options.${index}.index`} onChange={handleChange} />
                                                                    </InputGroup>
                                                                </WrapItem>
                                                                <WrapItem py={2}>
                                                                    <Checkbox size='md' colorScheme='green' w={200} name={`options.${index}.isCorrect`} isChecked={values.options[index].isCorrect} onChange={(e) => {
                                                                        values.options.map(
                                                                            (value, i) => {
                                                                                if (i !== index) {
                                                                                    setFieldValue(`options.${i}.isCorrect`, false)
                                                                                }
                                                                            }
                                                                        )
                                                                        setFieldValue(`options.${index}.isCorrect`, true)
                                                                    }}>
                                                                        Is Correct
                                                                    </Checkbox>
                                                                </WrapItem>
                                                                <WrapItem>
                                                                    {index <= 1 ?
                                                                        index == 1 ? <IconButton aria-label='add option' icon={<AddIcon />} ms={6} onClick={() => push({
                                                                            "optionId": "",
                                                                            "option": "",
                                                                            "isCorrect": false,
                                                                            "sortIndex": 0
                                                                        })} /> : <IconButton aria-label='add option' disabled ms={6} hidden={false} />
                                                                        : <IconButton aria-label='remove option' icon={<MinusIcon />} ms={6} onClick={() => remove(index)} />}
                                                                </WrapItem>
                                                            </Wrap>
                                                        )
                                                        ))}
                                                </FieldArray>
                                                <Textarea placeholder="explanation here" name="explanation" onChange={handleChange} mt={10} />
                                                <Button
                                                    mt="24px"
                                                    type="submit"
                                                    isLoading={isLoading}
                                                    colorScheme='green'
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    ref={resetbtnRef}
                                                    mt="24px"
                                                    hidden={true}
                                                    colorScheme='red'
                                                    onClick={handleReset}
                                                >
                                                    Reset
                                                </Button>

                                            </Flex>
                                        </FormControl>
                                    </form>
                                )}
                            </Formik>
                        </Box>
                    </Flex>
                </CardBody>
            </Card>
        </Flex >
    );
}

export default AddQuestion;
