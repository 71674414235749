import React, { useState, useEffect } from "react";
// Chakra imports
import {
  Flex,
  Text,
  VStack,
  InputGroup,
  InputLeftElement,
  Input,
  IconButton,
  Box,
  Textarea,
  Grid,
  GridItem,
  Spacer,
  Select,
  Button,
  FormControl,
  Divider,
  CheckboxGroup,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  useColorModeValue,
  Checkbox,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { AddIcon, ArrowBackIcon, MinusIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  HiOutlineOfficeBuilding,
  HiOutlineMail,
  HiDotsHorizontal,
} from "react-icons/hi";
import Api from "../../helpers/Fetch";
import Auth from "../../helpers/auth";
import { PersonIcon } from "components/Icons/Icons";
import { Formik, FieldArray } from "formik";
import Utils from "../../helpers/utils";
import { RiGitBranchLine } from "react-icons/ri";

function Branch(props) {
  const textColor = useColorModeValue("gray.700", "white");
  const [isFormHidden, setIsFormHidden] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [countries, setCountries] = useState(null);
  const [states, setStates] = useState(null);
  const [cities, setCities] = useState(null);

  let api = new Api();
  let util = new Utils();
  console.log(props);
  const { userId, companyName } = props.location.state;
  useEffect(() => {
    api
      .getCountries()
      .then((res) => {
        console.log(res);
        setCountries(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (!!err && !!err.response) {
          util.showError(err.response.data.message, "");
        }
      });
  }, []);
  let onChecked = (e) => {
    console.log(e);
    setIsFormHidden(e.target.checked);
  };
  const AddBranch = (data) => {
    setLoading(true);
    if (!data) {
      data = {
        userId: userId,
        haveMultipleBranches: "false",
      };
    } else {
      data.haveMultipleBranches = "true";
    }
    api
      .addBranch(data)
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        util.showSuccess(res.data.message, "");
        let pushData = {
          userId: res.data.data.clientId,
          branchId: res.data.data.branchId,
          branchName: res.data.data.branchName,
          companyName: companyName,
        };
        console.log("pushdata", pushData);
        props.history.push({
          pathname: "/admin/step-po",
          state: pushData,
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (!!err && !!err.response)
          util.showError(err.response.data.message, "");
      });
  };
  const getStates = (country) => {
    api
      .getStates(country)
      .then((res) => {
        console.log(res);
        setStates(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (!!err && !!err.response) {
          util.showError(err.response.data.message, "");
        }
      });
  };
  const getCities = (country, state) => {
    api
      .getCities(country, state)
      .then((res) => {
        console.log(res);
        setCities(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (!!err && !!err.response) {
          util.showError(err.response.data.message, "");
        }
      });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex
            justify="space-between"
            align="center"
            minHeight="60px"
            w="100%"
          >
            <VStack w="100%">
              <Alert status="success">
                <AlertIcon />
                <AlertTitle mr={2}>Company profile registered now. </AlertTitle>
                <AlertDescription>
                  Client ID is <strong>{userId}</strong>, Now you can add/skip a
                  branch to proceed.
                </AlertDescription>
              </Alert>
              <Text fontSize="lg" color="gray.400" fontWeight="bold" pt="24px">
                Add Branch
              </Text>
              <Divider variant="dashed" colorScheme={textColor} />
            </VStack>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex
            direction="column"
            align="center"
            w="100%"
            mt="35px"
            zIndex={99}
          >
            <Checkbox colorScheme="red" size="lg" onChange={onChecked}>
              <strong>Primary</strong> branch details are not same as company
              details?
            </Checkbox>
            <Button
              fontSize="14px"
              bg="red.300"
              h="45"
              mb="20px"
              color="white"
              isLoading={isLoading}
              mt="26px"
              _hover={{
                bg: "red.200",
              }}
              _active={{
                bg: "red.400",
              }}
              hidden={isFormHidden}
              onClick={() => {
                AddBranch(null);
              }}
            >
              Skip to next
            </Button>
            {isFormHidden ? (
              <Formik
                initialValues={{
                  userId: userId || "",
                  companyName: companyName || "",
                  branchName: "",
                  branchEmail: "",
                  country: "",
                  state: "",
                  city: "",
                  pincode: "",
                  address: "",
                  contacts: [
                    {
                      name: "",
                      designation: "",
                      contact: "",
                    },
                  ],
                }}
                onSubmit={(values, actions) => {
                  console.log(values);
                  AddBranch(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormControl>
                      <Flex
                        direction="column"
                        align="center"
                        w="100%"
                        mt="35px"
                        zIndex={99}
                      >
                        <Box borderWidth="1px" borderRadius="lg" w="100%">
                          <Box p="6">
                            <Text color="gray.500" mb={1}>
                              Company Name
                            </Text>
                            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                              <InputGroup>
                                <InputLeftElement
                                  pointerEvents="none"
                                  children={
                                    <HiOutlineOfficeBuilding color="gray.300" />
                                  }
                                />
                                <Input
                                  type="text"
                                  variant="filled"
                                  placeholder="Company Name"
                                  name="companyName"
                                  onChange={handleChange}
                                  value={values.companyName}
                                  disabled={true}
                                />
                              </InputGroup>
                              <InputGroup>
                                <InputLeftElement
                                  pointerEvents="none"
                                  children={
                                    <RiGitBranchLine color="gray.300" />
                                  }
                                />
                                <Input
                                  type="text"
                                  placeholder="Branch Name"
                                  name="branchName"
                                  onChange={handleChange}
                                />
                              </InputGroup>
                              <GridItem colSpan={2}>
                                <InputGroup>
                                  <InputLeftElement
                                    pointerEvents="none"
                                    children={
                                      <HiOutlineMail color="gray.300" />
                                    }
                                  />
                                  <Input
                                    type="email"
                                    placeholder="Branch Email"
                                    name="branchEmail"
                                    onChange={handleChange}
                                  />
                                </InputGroup>
                              </GridItem>

                              <Text color="gray.300">Add Contacts</Text>
                              <FieldArray name="contacts">
                                {({ insert, remove, push }) =>
                                  values.contacts.map((contact, index) => (
                                    <GridItem colSpan={2}>
                                      <Flex>
                                        <Select
                                          variant="filled"
                                          placeholder="Designation"
                                          name={`contacts.${index}.designation`}
                                          onChange={handleChange}
                                        >
                                          <option value="HR Manager">
                                            HR Manager
                                          </option>
                                          <option value="Director">
                                            Director
                                          </option>
                                          <option value="ceo">CEO</option>
                                          <option value="tech_lead">
                                            Tech Lead
                                          </option>
                                        </Select>
                                        <Spacer />
                                        <InputGroup me={10} ms={10}>
                                          <InputLeftElement
                                            pointerEvents="none"
                                            children={
                                              <PersonIcon color="gray.300" />
                                            }
                                          />
                                          <Input
                                            type="text"
                                            placeholder="Person Name"
                                            value={contact.name || ""}
                                            name={`contacts.${index}.name`}
                                            onChange={handleChange}
                                          />
                                        </InputGroup>
                                        <Spacer />
                                        <InputGroup>
                                          <InputLeftElement
                                            pointerEvents="none"
                                            children={
                                              <PhoneIcon color="gray.300" />
                                            }
                                          />
                                          <Input
                                            type="number"
                                            placeholder="Contact"
                                            value={contact.contact || ""}
                                            name={`contacts.${index}.contact`}
                                            onChange={handleChange}
                                          />
                                        </InputGroup>
                                        <Spacer />
                                        {index === 0 ? (
                                          <IconButton
                                            aria-label="add contact"
                                            icon={<AddIcon />}
                                            ms={6}
                                            onClick={() =>
                                              push({
                                                name: "",
                                                designation: "",
                                                contact: "",
                                              })
                                            }
                                          />
                                        ) : (
                                          <IconButton
                                            aria-label="remove contact"
                                            icon={<MinusIcon />}
                                            ms={6}
                                            onClick={() => remove(index)}
                                          />
                                        )}
                                      </Flex>
                                    </GridItem>
                                  ))
                                }
                              </FieldArray>
                              <Text color="gray.300" mb={0}>
                                Address details
                              </Text>

                              <GridItem colSpan={2}>
                                <Flex>
                                  <Select
                                    variant="filled"
                                    placeholder="Select Country"
                                    name="country"
                                    onChange={(e) => {
                                      setFieldValue("country", e.target.value);
                                      getStates(e.target.value);
                                    }}
                                  >
                                    {!!countries &&
                                      countries.map((item) => {
                                        return (
                                          <option value={item.iso2}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                  </Select>
                                  <Spacer />
                                  <Select
                                    variant="filled"
                                    placeholder="Select State"
                                    me={12}
                                    ms={12}
                                    name="state"
                                    onChange={(e) => {
                                      setFieldValue("state", e.target.value);
                                      getCities(values.country, e.target.value);
                                    }}
                                  >
                                    {!!states &&
                                      states.map((item) => {
                                        return (
                                          <option value={item.iso2}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                  </Select>
                                  <Spacer />
                                  <Select
                                    variant="filled"
                                    placeholder="Select City"
                                    name="city"
                                    onChange={handleChange}
                                  >
                                    {!!cities &&
                                      cities.map((item) => {
                                        return (
                                          <option value={item.iso2}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                  </Select>
                                </Flex>
                              </GridItem>

                              <Textarea
                                placeholder="Enter complete address"
                                size="sm"
                                name="address"
                                onChange={handleChange}
                              />
                              <GridItem colSpan={1}>
                                <Flex>
                                  <InputGroup>
                                    <InputLeftElement
                                      pointerEvents="none"
                                      children={
                                        <HiDotsHorizontal color="gray.300" />
                                      }
                                    />
                                    <Input
                                      type="number"
                                      placeholder="Pin code"
                                      name="pincode"
                                      onChange={handleChange}
                                    />
                                  </InputGroup>
                                  <Spacer />
                                </Flex>
                              </GridItem>
                              <GridItem colSpan={2}>
                                <Button
                                  fontSize="14px"
                                  type="submit"
                                  bg="red.300"
                                  w="100%"
                                  h="45"
                                  mb="20px"
                                  color="white"
                                  isLoading={isLoading}
                                  mt="20px"
                                  _hover={{
                                    bg: "red.200",
                                  }}
                                  _active={{
                                    bg: "red.400",
                                  }}
                                >
                                  Add & Next
                                </Button>
                              </GridItem>
                            </Grid>
                          </Box>
                        </Box>
                      </Flex>
                    </FormControl>
                  </form>
                )}
              </Formik>
            ) : null}
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Branch;
