import React, { useState, useEffect } from "react";
// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Grid,
  Box, UnorderedList, ListItem,
  SimpleGrid, VStack,
  useColorModeValue,
  Link, Switch, useDisclosure,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesTableRow from "components/Tables/BranchesTableRow";
import { PhoneIcon, AddIcon, WarningIcon, ArrowBackIcon, EditIcon } from '@chakra-ui/icons'
import Api from "../../helpers/Fetch";
import Utils from "../../helpers/utils";
import Auth from '../../helpers/auth';


function ViewClient(props) {
  const textColor = useColorModeValue("gray.700", "white");
  const { onOpen, onClose } = useDisclosure()
  const [data, setData] = useState(null);
  const [orders, setOrders] = useState(null);
  const [isOpen, setOpen] = useState(false)
  let api = new Api();
  let util = new Utils();
  let module = props.location.state.moduleId;
  useEffect(() => {
    let data = {
      configId: module
    }
    api.getConfigDetails(data).then((res) => {
      console.log(res);
      setData(res.data.data)

    }).catch((err) => {
      util.showServerError(err);

    });
  }, []);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex justify="space-between" align="center" minHeight="30px" w="100%">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              {!!data && data.name}
            </Text>
            <Button
              fontSize="xs"
              variant="solid"
              leftIcon={<ArrowBackIcon />}
              onClick={() => props.history.goBack()}

            >
              Back
            </Button>
          </Flex>

        </CardHeader>
      </Card>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} mt={2}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex justify="space-between" align="center" minHeight="30px" w="100%">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Configuration Details
            </Text>


          </Flex>

        </CardHeader>
        <CardBody>
          {!!data ?
            <Flex direction='column'>
              <SimpleGrid columns={3} spacingX={24} spacingY={8}>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">Name</Text>
                  <Text fontSize="16px">{data.name}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">ID</Text>
                  <Text fontSize="16px">{data.configId}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">ICC</Text>
                  <Text fontSize="16px">{data.iccContent ? 'Yes' : 'No'}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">Videos</Text>
                  <Text fontSize="16px">{data.animatedVideos ? 'Yes' : 'No'}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">Learning Type</Text>
                  <Text fontSize="16px">{data.learningType}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">Quiz Type</Text>
                  <Text fontSize="16px">{data.quizType || '-'}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">Quiz Format</Text>
                  <Text fontSize="16px">{data.quizFormat || '-'}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">Quiz Duration</Text>
                  <Text fontSize="16px">{data.quizTimerDurationInSec}s</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">No. of questions</Text>
                  <Text fontSize="16px">{data.quizType === 'NORMAL' ? data.quizQuestionQuantity : data.ChapterWiseFormat.questionsPerChapter}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">Passing (%)</Text>
                  <Text fontSize="16px">{data.quizType === 'NORMAL' ? data.quizPassingPercentage : data.ChapterWiseFormat.passingPercentage}</Text>
                </Box>
                {data.quizType !== 'NORMAL' ?
                  <Box textAlign='flex-start'>
                    <Text align='start' color='gray.500' fontSize="14px">Passing Format</Text>
                    <Text fontSize="16px">{data.quizType === 'NORMAL' ? '-' : data.ChapterWiseFormat.passingFormat}</Text>
                  </Box>
                  : null
                }
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">Quiz Having Timer?</Text>
                  <Text fontSize="16px">{data.quizTimer ? 'Yes' : 'No'}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">Answer Explantion</Text>
                  <Text fontSize="16px">{data.quizExplanation ? 'Yes' : 'No'}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">Language included</Text>
                  <Text fontSize="16px">{data.languageSupport.map(el => {
                    return (<Badge me='2'>{el}</Badge>);
                  })}</Text>
                </Box>



              </SimpleGrid>
              {data.SectionWiseQuestionFormat.length > 1 ?
                <Box textAlign='flex-start' w='100%'>
                  <Text align='start' color='gray.500' fontSize="18px" mt='10' mb='2'><u>Section Wise</u></Text>
                  <Flex justify='space-between' w='100%' textAlign='center' mb='2'>
                    <Text color='gray.400'>Chapter ID</Text>
                    <Text color='gray.400' ms='20'>Language</Text>
                    <Text color='gray.400'>No. of Questions</Text>

                  </Flex>
                  <Flex direction='column' fontSize="16px" justify='space-between' w='100%'>
                    {data.SectionWiseQuestionFormat.map(el => {
                      return (<Flex justify='space-between' w='100%' textAlign='start'>
                        <Text>{el.moduleId}</Text>
                        <Text>{el.lang}</Text>
                        <Text>{el.noOfQuestions}</Text>

                      </Flex>);
                    })}</Flex>
                </Box>
                : null}

            </Flex>
            : null}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default ViewClient;
