import React, { useState, useEffect } from "react";
// Chakra imports
import {
    Flex,
    Text,
    VStack, InputGroup, InputLeftElement, Input,
    IconButton, Box, Table, Th, Tr, Thead, Tbody, GridItem, Spacer, Select,
    Button, FormControl, Divider, SlideFade, Grid,
    CheckboxGroup, Alert, AlertDescription, AlertIcon, AlertTitle, CloseButton,
    useColorModeValue, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalCloseButton, ModalBody,
    Checkbox, Link, SimpleGrid, List, ListItem, ListIcon

} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { AddIcon, ArrowBackIcon, CalendarIcon, MinusIcon, PhoneIcon } from '@chakra-ui/icons'
import { HiOutlineOfficeBuilding, HiOutlineMail, HiDotsHorizontal } from 'react-icons/hi'
import Api from "../../helpers/Fetch";
import { Formik, FieldArray } from 'formik';
import Utils from "../../helpers/utils";
import { BiRupee } from "react-icons/bi";
import { Separator } from "components/Separator/Separator";
import {
    AsyncCreatableSelect,
    AsyncSelect,
    CreatableSelect,
    Select as Multiselect,
} from "chakra-react-select";


function CreateOrder(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const [isLoading, setLoading] = useState(false);
    const [dropdownData, setDropDownData] = useState(null);
    const [chapters, setChapters] = useState(null);
    const [languages, setLanguages] = useState(null);

    let api = new Api();
    let util = new Utils();
    let content_language_options = []
    useEffect(() => {
        api.getDropdownData().then(res => {
            console.log('dropdown', res.data.data);
            setDropDownData(res.data.data);
            res.data.data.languages.map(item => {
                content_language_options.push({
                    label: item,
                    value: item.toLowerCase()
                })
            })
            setLanguages(content_language_options);
            console.log('h', languages);

        }).catch(err => {
            if (!!err) {
                util.showServerError(err);
            }
        });
    }, []);
    const getChapters = (langs) => {
        console.log('langs', langs)
        api.getQuizChapters({ langs: langs }).then(res => {
            setChapters(res.data.data);
        }).catch(err => {
            if (!err && !!err.response) {
                util.showError(err.response.data.message, '');
            }
        });
    }
    const addModule = (data) => {
        if (data.quizFormat === 'SECTIONAL' && data.total.filter(el => el !== data.quizQuestionQuantity).length > 0) {
            util.showError("Each language section total value should be " + data.quizQuestionQuantity);
            return;
        }
        setLoading(true);
        let secWise = [];
        data.languageWise.map(item => {
            item.SectionWiseQuestionFormat.map(i => {
                secWise.push(i);
            })
        })
        data.SectionWiseQuestionFormat = secWise;
        delete data.languageWise;
        console.log('finalData', data);
        api.addConfigModule(data).then(res => {
            setLoading(false)
            util.showSuccess(res.data.message);
            props.history.goBack();
        }).catch(err => {
            setLoading(false)
            util.showServerError(err);

        });
    }

    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                <CardHeader p="6px 0px 22px 0px">
                    <Flex justify="space-between" align="center" w="100%">
                        <Text fontSize="lg" colorScheme="blackAlpha" fontWeight="bold" pl="24px">
                            Create Module
                        </Text>
                        <Button variant="solid" colorScheme='gray' leftIcon={<ArrowBackIcon />} onClick={() => props.history.goBack()}>Back</Button>
                    </Flex>

                </CardHeader>
                <Separator />
                <CardBody>
                    <Box flex="1" align="center" w="100%" mt="30px" zIndex={99} borderWidth="1px" borderRadius="lg" p={6}>
                        <Formik
                            initialValues={{
                                "name": "",
                                "animatedVideos": false,
                                "iccContent": false,
                                "quizQuestionQuantity": false,
                                "quizExplanation": false,
                                "quizTimer": false,
                                "quizTimerDurationInSec": 0,
                                "quizPassingPercentage": 0,
                                "quizType": "",
                                "ChapterWiseFormat": {
                                    "passingFormat": "",
                                    "individuallyPassingPercentage": 0,
                                    "overallPassingPercentage": 0,
                                    "questionsPerChapter": 0
                                },
                                "quizFormat": "",
                                "languageWise": [
                                    {
                                        "SectionWiseQuestionFormat": [
                                            {
                                                "lang": "",
                                                "moduleId": "",
                                                "noOfQuestions": 0
                                            }
                                        ]
                                    }
                                ],
                                "learningType": "",
                                "languageSupport": [],
                                "total": []
                            }}
                            onSubmit={(values, actions) => {
                                console.log(values);

                                addModule(values);

                            }}


                        >
                            {({
                                values,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <FormControl w="100%">
                                        <SimpleGrid columns={[1, null, 1]} spacing='40px' w="100%">
                                            <Box pt='3' borderWidth='0px' borderRadius='lg' flex="1">
                                                <Grid templateColumns='repeat(3, 1fr)' gap={4}>
                                                    <GridItem colSpan={3}>
                                                        <VStack alignItems='flex-start'>
                                                            <Text color="gray.500">Name of configuration</Text>
                                                            <InputGroup size='md'>
                                                                <Input type="text" placeholder="name" name="name" onChange={handleChange} />
                                                            </InputGroup>
                                                        </VStack>
                                                    </GridItem>
                                                    <GridItem>
                                                        <VStack alignItems='flex-start'>
                                                            <Text color="gray.500">Include Animated Videos</Text>
                                                            <Checkbox name="animatedVideos" onChange={handleChange}></Checkbox>
                                                        </VStack>
                                                    </GridItem>
                                                    <GridItem>
                                                        <VStack alignItems='flex-start'>
                                                            <Text color="gray.500">Include ICC Content</Text>
                                                            <Checkbox name="iccContent" onChange={handleChange}></Checkbox>
                                                        </VStack>
                                                    </GridItem>
                                                    <GridItem colStart={1}>
                                                        <VStack alignItems='flex-start'>
                                                            <Text color="gray.500">Learning Type</Text>
                                                            <Select variant="outline" placeholder='Select' name="learningType" onChange={handleChange} size='sm'>
                                                                <option value='FORCE'>Force</option>
                                                                <option value='NOT_FORCE'>Flexible</option>
                                                            </Select>
                                                        </VStack>
                                                    </GridItem>
                                                    <GridItem colSpan={2}>
                                                        <VStack alignItems='flex-start'>
                                                            <Text color="gray.500">Languages support</Text>
                                                            <Box w="100%">
                                                                <Multiselect
                                                                    size="sm"
                                                                    isMulti
                                                                    colorScheme="green"
                                                                    closeMenuOnSelect={false}
                                                                    placeholder='Select Languages'
                                                                    name="languageSupport"
                                                                    onChange={(e) => {
                                                                        let items = e.map(item => item.value);
                                                                        console.log(items);
                                                                        setFieldValue('languageSupport', items);
                                                                        getChapters(JSON.stringify(items));
                                                                    }}
                                                                    selectedOptionStyle="check"
                                                                    options={!!languages ? languages : []}
                                                                    width={300}
                                                                >

                                                                </Multiselect>
                                                            </Box>
                                                        </VStack>
                                                    </GridItem>
                                                    <GridItem colStart={1} textAlign='start' mt={3}>
                                                        <Text color="gray.800" fontWeight='semibold'>Quiz Configurations</Text>
                                                        <Divider my="10px" />
                                                    </GridItem>
                                                    <GridItem colStart={1}>
                                                        <VStack alignItems='flex-start'>
                                                            <Text color="gray.500">Quiz Type</Text>
                                                            <Select variant="outline" placeholder='Select' name="quizType" onChange={handleChange} size='sm'>
                                                                <option value='NORMAL'>Standard</option>
                                                                <option value='CHAPTER_WISE'>Chapter Wise</option>
                                                            </Select>
                                                        </VStack>
                                                    </GridItem>
                                                    {
                                                        values.quizType === 'CHAPTER_WISE' ? <>
                                                            <GridItem colSpan={3} colStart={1} textAlign='center'>
                                                                <Box color="gray.600" fontWeight='normal' borderTopRadius="10px" borderWidth="1px" p="3px" w="200px" textAlign='center' bg="gray.300">Chapter-Wise options</Box>
                                                                <Box borderBottomRadius="10px" borderWidth="1px" p="13px" w="100%">
                                                                    <SimpleGrid columns={{ sm: 1, md: 3 }} spacing="16px">
                                                                        <VStack alignItems='flex-start'>
                                                                            <Text color="gray.500" size='sm'>Questioning Format</Text>
                                                                            <Select variant="outline" placeholder='Select' name="quizFormat" onChange={handleChange} size='md'>
                                                                                <option value='FIXED'>Fixed</option>
                                                                                <option value='RANDOM'>Random</option>
                                                                            </Select>
                                                                        </VStack>
                                                                        <VStack alignItems='flex-start'>
                                                                            <Text color="gray.500" size='sm'>Passing Format</Text>
                                                                            <Select variant="outline" placeholder='Select' name="ChapterWiseFormat.passingFormat" onChange={handleChange} size='md'>
                                                                                <option value='INDIVIDUAL'>Individually</option>
                                                                                <option value='OVERALL'>Overall</option>
                                                                            </Select>
                                                                        </VStack>
                                                                        <VStack alignItems='flex-start'>
                                                                            <Text color="gray.500" size='sm'>Passing Percentage [0-100]</Text>
                                                                            <Input type="number" name="ChapterWiseFormat.passingPercentage" placeholder="%" onChange={handleChange}></Input>
                                                                        </VStack>
                                                                        <VStack alignItems='flex-start'>
                                                                            <Text color="gray.500" size='sm'>Question per chapter</Text>
                                                                            <Input type="number" name="ChapterWiseFormat.questionsPerChapter" placeholder="count" onChange={handleChange}></Input>
                                                                        </VStack>
                                                                    </SimpleGrid>
                                                                </Box>
                                                            </GridItem>
                                                            <GridItem colSpan={3}>

                                                            </GridItem>
                                                        </>
                                                            : values.quizType === 'NORMAL' ? <>
                                                                <GridItem colSpan={3} colStart={1} textAlign='center'>
                                                                    <Box color="gray.600" fontWeight='normal' borderTopRadius="10px" borderWidth="1px" p="3px" w="200px" textAlign='center' bg="gray.300">Standard options</Box>
                                                                    <Box borderBottomRadius="10px" borderWidth="1px" p="13px" w="100%">
                                                                        <Grid columns={{ sm: 1, md: 3 }} gap={4}>
                                                                            <GridItem>
                                                                                <VStack alignItems='flex-start'>
                                                                                    <Text color="gray.500" size='sm'>Questioning Format</Text>
                                                                                    <Select variant="outline" placeholder='Select' name="quizFormat" onChange={handleChange} size='md'>
                                                                                        <option value='FIXED'>Fixed</option>
                                                                                        <option value='RANDOM'>Random</option>
                                                                                        <option value='SECTIONAL'>Sectional</option>
                                                                                    </Select>
                                                                                </VStack>
                                                                            </GridItem>
                                                                            <GridItem>
                                                                                <VStack alignItems='flex-start'>
                                                                                    <Text color="gray.500" size='sm'>Passing Percentage [0-100]</Text>
                                                                                    <Input type="number" name="quizPassingPercentage" placeholder="%" onChange={handleChange}></Input>
                                                                                </VStack>
                                                                            </GridItem>
                                                                            <GridItem>
                                                                                <VStack alignItems='flex-start'>
                                                                                    <Text color="gray.500" size='sm'>No. of Questions</Text>
                                                                                    <Input type="number" name="quizQuestionQuantity" placeholder="count" onChange={handleChange}></Input>
                                                                                </VStack>
                                                                            </GridItem>
                                                                            {
                                                                                values.quizFormat === 'SECTIONAL' ? <>
                                                                                    <GridItem colSpan={3} justifyContent='center' justifyItems='center'>
                                                                                        <Flex direction='column' w='100%'>
                                                                                            <Text color="lightblue" size='sm'>Select number of questions from each chapter under each language. For each language, total of all should be exactly {values.quizQuestionQuantity}</Text>
                                                                                            {!!chapters && chapters.map((chapter, i) => {
                                                                                                return (<Flex w='100%' justify='space-between'>
                                                                                                    <VStack alignItems='flex-start'>
                                                                                                        <Text fontWeight='bold' size='md' mt='4'>{chapter.lang.toUpperCase()}</Text>
                                                                                                        <List spacing={3}>
                                                                                                            {
                                                                                                                !!chapter && chapter.items.map((item, j) => {
                                                                                                                    return (<ListItem>
                                                                                                                        <Flex>
                                                                                                                            <Input value={item.name} name={`languageWise.${i}.SectionWiseQuestionFormat.${j}.moduleId`} disabled onChange={handleChange} />
                                                                                                                            <Input w="100px" type="number" placeholder="0" name={`languageWise.${i}.SectionWiseQuestionFormat.${j}.noOfQuestions`} ml="16px" onChange={handleChange} onBlur={(e) => {
                                                                                                                                console.log('onblur');
                                                                                                                                let total = 0;
                                                                                                                                values.languageWise[i].SectionWiseQuestionFormat.map(s => {
                                                                                                                                    total += parseInt(s.noOfQuestions);
                                                                                                                                })

                                                                                                                                if (total > values.quizQuestionQuantity) {
                                                                                                                                    alert('Total value exceeded to total no. of questions');
                                                                                                                                    setFieldValue(`total.${i}`, total - parseInt(e.target.value))
                                                                                                                                    e.target.value = 0
                                                                                                                                    return;
                                                                                                                                }
                                                                                                                                setFieldValue(`languageWise.${i}.SectionWiseQuestionFormat.${j}.moduleId`, item.moduleId)
                                                                                                                                setFieldValue(`languageWise.${i}.SectionWiseQuestionFormat.${j}.lang`, chapter.lang.toLowerCase());
                                                                                                                                setFieldValue(`total.${i}`, total)


                                                                                                                            }} />
                                                                                                                        </Flex>
                                                                                                                    </ListItem>)
                                                                                                                })
                                                                                                            }
                                                                                                        </List>

                                                                                                    </VStack>
                                                                                                    <VStack justifyContent='center' textAlign='center' me={20}>
                                                                                                        <Text color={values.total[i] !== values.quizQuestionQuantity ? 'red.500' : 'green.700'} fontSize="20px"> Total: {values.total[i] || 0} Questions</Text>
                                                                                                    </VStack>
                                                                                                </Flex>)
                                                                                            })
                                                                                            }
                                                                                        </Flex>
                                                                                    </GridItem>
                                                                                </> : null
                                                                            }
                                                                        </Grid>
                                                                        <GridItem>

                                                                        </GridItem>
                                                                    </Box>
                                                                </GridItem>

                                                            </> : null
                                                    }
                                                    <GridItem>
                                                        <VStack alignItems='flex-start'>
                                                            <Text color="gray.500">Show Question Explanation</Text>
                                                            <Checkbox name="quizExplanation" onChange={handleChange}></Checkbox>
                                                        </VStack>
                                                    </GridItem>
                                                    <GridItem>
                                                        <VStack alignItems='flex-start'>
                                                            <Text color="gray.500">Have Timer</Text>
                                                            <Checkbox name="quizTimer" onChange={handleChange}></Checkbox>
                                                        </VStack>
                                                    </GridItem>
                                                    <GridItem hidden={!values.quizTimer} colStart={1}>
                                                        <VStack alignItems='flex-start'>
                                                            <Text color="gray.500">Timer time(seconds)</Text>
                                                            <Input name="quizTimerDurationInSec" placeholder="time" onChange={handleChange} />
                                                        </VStack>
                                                    </GridItem>


                                                </Grid>
                                                <Flex spacing="16px" my="16px">
                                                    <Button colorScheme='gray' onClick={() => window.location.reload(false)}>Reset</Button>
                                                    <Spacer />
                                                    <Button colorScheme='green' type='submit' isLoading={isLoading} w={200}>Save</Button>
                                                </Flex>


                                            </Box>
                                        </SimpleGrid>
                                    </FormControl>
                                </form>
                            )}
                        </Formik>
                    </Box>


                </CardBody>
            </Card>
        </Flex >
    );
}

export default CreateOrder;
