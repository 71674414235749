import React, { useState, useEffect } from "react";
// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Grid,
  Box, UnorderedList, ListItem,
  SimpleGrid, VStack,
  useColorModeValue,
  Link, Switch, useDisclosure,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesTableRow from "components/Tables/BranchesTableRow";
import { PhoneIcon, AddIcon, WarningIcon, ArrowBackIcon, EditIcon } from '@chakra-ui/icons'
import Api from "../../helpers/Fetch";
import Utils from "../../helpers/utils";
import Auth from '../../helpers/auth';


function ViewClient(props) {
  const textColor = useColorModeValue("gray.700", "white");
  const { onOpen, onClose } = useDisclosure()
  const [data, setData] = useState(null);
  const [orders, setOrders] = useState(null);
  const [isOpen, setOpen] = useState(false)
  let api = new Api();
  let util = new Utils();
  let module = props.location.state.orderId;
  useEffect(() => {
    let data = {
      orderId: module
    }
    api.getOrder(data).then((res) => {
      console.log(res);
      setData(res.data.data)

    }).catch((err) => {
      util.showServerError(err);

    });
  }, []);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex justify="space-between" align="center" minHeight="30px" w="100%">
            <Text fontSize="x-large" color={textColor} fontWeight="bold">
              {!!data && data.orderId}
            </Text>
            <Button
              fontSize="xs"
              variant="solid"
              leftIcon={<ArrowBackIcon />}
              onClick={() => props.history.goBack()}

            >
              Back
            </Button>
          </Flex>

        </CardHeader>
      </Card>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} mt={2}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex justify="space-between" align="center" minHeight="30px" w="100%">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Order Details
            </Text>


          </Flex>

        </CardHeader>
        <CardBody>
          {!!data ?
            <Flex direction='column'>
              <SimpleGrid columns={5} spacingX={24} spacingY={8}>

                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">ID</Text>
                  <Text fontSize="16px">{data.orderId}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">Company Name</Text>
                  <Text fontSize="16px">{data.companyName}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">Branch Name</Text>
                  <Text fontSize="16px">{data.branchName ? 'Yes' : 'No'}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">Content</Text>
                  <Text fontSize="16px">{data.contentIncluded ? 'YES' : 'No'}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">Quiz</Text>
                  <Text fontSize="16px">{data.quizIncluded ? 'YES' : 'No'}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">Custom Email</Text>
                  <Text fontSize="16px">{data.customEmailing ? 'Yes' : 'No'}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">Custom Templating</Text>
                  <Text fontSize="16px">{data.customTemplating ? 'Yes' : 'No'}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">License Issued</Text>
                  <Text fontSize="16px">{data.licenceQuantity}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">License Expiry</Text>
                  <Text fontSize="16px">{data.licenceExpiryDate}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">Price Per License</Text>
                  <Text fontSize="16px">{data.pricePerLicence}</Text>
                </Box>
                <Box textAlign='flex-start'>
                  <Text align='start' color='gray.500' fontSize="14px">Configuration Id</Text>
                  <Text fontSize="16px">{data.moduleId}</Text>
                </Box>
              </SimpleGrid>
              <Text mt='4' mb='10' bg='gray.100' pl='2'>Additional items</Text>
              {data.additionalItems.length > 0 ?
                <Box textAlign='flex-start' w='100%'>
                  <Flex justify='space-between' w='100%' textAlign='center' mb='2'>
                    <Text color='gray.400'>#</Text>
                    <Text color='gray.400'>Item</Text>
                    <Text color='gray.400'>Quantity</Text>
                    <Text color='gray.400'>Price</Text>

                  </Flex>
                  <Flex direction='column' fontSize="16px" justify='space-between' w='100%'>
                    {data.additionalItems.map((el, i) => {
                      return (<Flex justify='space-between' w='100%' textAlign='center'>
                        <Text >{i + 1}</Text>
                        <Text>{el.description || 'NA'}</Text>
                        <Text>{el.quantity}</Text>
                        <Text>{el.price}</Text>

                      </Flex>);
                    })}</Flex>
                </Box>
                : null}

            </Flex>
            : null}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default ViewClient;
