import {
    useToast,
    useColorModeValue,
} from '@chakra-ui/react';
import Auth from '../helpers/auth';

export default class Utils {
    constructor() {
        this.toast = useToast();

    }
    showError = (title, msg) => {
        this.toast({
            title: title || '',
            description: msg || '',
            status: 'error',
            duration: 3000,
            isClosable: true,
        })
    }
    showSuccess = (title, msg) => {
        this.toast({
            title: title || '',
            description: msg || '',
            status: 'success',
            duration: 3000,
            isClosable: true,
        })
    }
    showWarning = (title, msg) => {
        this.toast({
            title: title || '',
            description: msg || '',
            status: "warning",
            duration: 3000,
            isClosable: true,
        })
    }
    showInfo = (title, msg) => {
        this.toast({
            title: title || '',
            description: msg || '',
            status: "info",
            duration: 3000,
            isClosable: true,
        })
    }

    showServerError(err) {
        console.log(err);
        if (!!err && !!err.response) {
            if (err.response.data.message === "Session Expired") {
                this.toast({
                    title: err.response.data.message,
                    description: "Please login again.",
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                });
                Auth.clearLocalStorage();
                window.location.reload(false);
            }
            else {
                this.toast({
                    title: err.response.data.message,
                    description: "",
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                });
            }
        }
    }

}