import {
    Avatar,
    Badge,
    Button,
    Flex,
    Td,
    Text,
    Tr,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuIcon, Tooltip,
    HStack, Tag,
    IconButton,
    useColorModeValue,
} from "@chakra-ui/react";
import { VscMenu } from 'react-icons/vsc'
import { GoPrimitiveDot } from 'react-icons/go'
import React from "react";

function TablesTableRow(props) {
    const data = props.data;
    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.400");

    return (
        <Tr>
            <Td>
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                        {props.index + 1}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                        {data.moduleId}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <Flex direction="column" minWidth={{ sm: "120px" }} pl="0px">
                    <Tooltip label={data.title} aria-label='A tooltip'>
                        <Text onClick={() => props.onclick({ id: data.moduleId, action: "open_modules" })} noOfLines={1} color='blue.300'>{data.title}</Text>
                    </Tooltip>
                </Flex>
            </Td>
            <Td>
                <Text fontSize="sm" color={textColor} fontWeight="normal" pb=".5rem">
                    {data.lang.toUpperCase()}
                </Text>
            </Td>

            <Td>
                {data.isIccContent === true ? <Tag size='sm' variant='solid' colorScheme='green'>
                    Yes
                </Tag>
                    :
                    <Tag size='sm' variant='solid' colorScheme='red'>
                        No
                    </Tag>}
            </Td>
            <Td>
                {data.isContainVideos === true ? <Tag size='sm' variant='solid' colorScheme='green'>
                    Yes
                </Tag>
                    :
                    <Tag size='sm' variant='solid' colorScheme='red'>
                        No
                    </Tag>}
            </Td>
            <Td>
                <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
                    {data.topics}
                </Text>
            </Td>
            <Td>
                {data.visibility === "visible" ?
                    <Tooltip label={data.visibility} aria-label='A tooltip'>
                        <IconButton
                            variant='link'
                            size="lg"
                            colorScheme='green'
                            aria-label='status'
                            icon={<GoPrimitiveDot />}
                        />
                    </Tooltip>
                    :
                    <Tooltip label={data.visibility} aria-label='A tooltip'>
                        <IconButton
                            variant='link'
                            size="lg"
                            colorScheme='red'
                            aria-label='status'
                            icon={<GoPrimitiveDot />}
                        />
                    </Tooltip>
                }
            </Td>
            <Td><Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
                {data.sortIndex}
            </Text></Td>

            <Td>
                <Menu>
                    <MenuButton as={IconButton} icon={<VscMenu />}>

                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => props.onclick({ id: data, action: "view" })}>View/Edit</MenuItem>
                        <MenuItem onClick={() => props.onclick({ id: data, action: "delete" })}>Delete</MenuItem>
                        <MenuItem onClick={() => props.onclick({ id: data, action: "disable" })}>{data.visibility === 'visible' ? 'Make invisible' : 'Make visible'}</MenuItem>
                    </MenuList>
                </Menu>
            </Td>
        </Tr>
    );
}

export default TablesTableRow;
