import React, { useState, useEffect } from "react";
// Chakra imports
import {
    Flex,
    Table,
    Tbody,
    Text, Box,
    Th,
    Thead,
    Tr,
    Button,
    Grid, SlideFade, AlertIcon, AlertTitle, Alert, AlertDescription,
    GridItem,
    useToast, Divider,
    useColorModeValue, Center,
    Link, Select, Textarea,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Api from "../../helpers/Fetch";
import Util from "../../helpers/utils";
import { Separator } from "components/Separator/Separator";


function Clients(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const [data, setData] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    let api = new Api();
    let util = new Util();
    let dropRef = React.useRef();
    let textRef = React.useRef();

    const submit = () => {
        let data = {
            related: dropRef.current.value,
            query: textRef.current.value,
        }
        setLoading(true)
        api.submitQuery(data).then((res) => {
            setLoading(false)
            setData(res.data);
            util.showSuccess(res.data.message, '');
            setSubmitted(true);
        }).catch((err) => {
            console.log(err);
            setLoading(false)
            util.showServerError(err);
        });
    }

    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                <CardHeader p="6px 0px 2px 0px">
                    <Flex justify="center" align="center" minHeight="60px" w="100%">
                        <Text fontSize="lg" color={textColor} fontWeight="bold">
                            Submit your query here
                        </Text>

                    </Flex>

                </CardHeader>
                <Divider mb='4' />
                <CardBody>
                    {!submitted ?
                        <Center w='100%'>
                            <Box w="50%">
                                <Flex direction='column'>
                                    <Select placeholder='Your issue related to?' ref={dropRef}>
                                        <option>Licence related Issue</option>
                                        <option>Certificate related Issue</option>
                                        <option>Account related issue</option>
                                        <option>General</option>
                                    </Select>
                                    <Textarea mt='4' placeholder='write your query' height='200px' ref={textRef} />
                                    <Button mt='4' size='sm' maxW='100px' isLoading={loading} onClick={submit}>Submit</Button>
                                </Flex>
                            </Box>
                        </Center>
                        : <Flex direction="column" align="center" w="100%" mt="0px" zIndex={99}>
                            <SlideFade in={true} offsetY='20px'>

                                <Alert
                                    status='success'
                                    variant='subtle'
                                    flexDirection='column'
                                    alignItems='center'
                                    justifyContent='center'
                                    textAlign='center'
                                    height='200px'
                                    w="100%"
                                >
                                    <AlertIcon boxSize='40px' mr={0} />
                                    <AlertTitle mt={4} mb={1} fontSize='lg'>
                                        Submitted
                                    </AlertTitle>
                                    <AlertDescription maxWidth='sm'>
                                        {data.message}
                                        <Text>
                                            Ref No. #{data.data.queryId}
                                        </Text>
                                    </AlertDescription>
                                </Alert>
                            </SlideFade>
                        </Flex>}
                </CardBody>
            </Card>
        </Flex>
    );
}

export default Clients;
